import { DeviceEventEmitter } from 'react-native';
import { Socket, io } from 'socket.io-client';
import { RootState, store } from '../store';
import { setSubscribed } from '../store/agenda/agenda.actions';
import { AuthService } from './auth-service';
import { configService } from './config.service';
import { setIsWALoggedIn } from '../store/auth/auth.actions';
import { setSocketIOConnected } from '../store/ui/ui.actions';

class SocketService {
  private socket: Socket = io(configService.get('socketioUrl'));
  isConnected = false
  isLoggedIn = false

  socketIOPreviousConnected = null

  constructor() {
    var onevent = (<any>this.socket).onevent;
    (<any>this.socket).onevent = function (packet) {
      var args = packet.data || [];
      onevent.call(this, packet);    // original call
      packet.data = ["*"].concat(args);
      onevent.call(this, packet);      // additional call to catch-all
    };

    this.socket.on("*", (event: string, data: any) => {
      console.log("SOCKET RECEIVE *******")
      this.socketEventHandler(event, data)
    });

    this.socket.on('connect', () => {
      console.log("conected!!")
      store.dispatch(setSocketIOConnected(true))

      if( this.socketIOPreviousConnected != null ){
        if( AuthService.isLoggedIn ){
          console.log("SOCKET RECONNECTED!!")
          AuthService.getSessionInfo()
        }
      }

      this.socketIOPreviousConnected = true 
      this.isConnected = true;
      this.isLoggedIn = true;
      if (AuthService.isLoggedIn()) this.authenticate();
    });

    this.socket.on('disconnect', () => {
      this.socketIOPreviousConnected = false 
      this.isConnected = false;
      store.dispatch(setSocketIOConnected(false))
      this.isLoggedIn = false;
    });
  }

  connect() {
    this.socket.connect()
  }

  private socketEventHandler(event: string, data: any) {
    console.log("RECIBIDO: ", event, data)
    DeviceEventEmitter.emit(event, data)
    DeviceEventEmitter.emit("*", { event, data })
    //this.emit(JSON.parse(evt.data))

    switch (event) {
      case 'update-contact-list':
        /*setTimeout(()=>{
          window.location.reload();
        },500);*/
        break;
      case 'stopQR':
        AuthService.getSessionInfo()
        break;
      case 'scanQR':
        break;
      case 'connecting':
        break;
      case 'connected':
        break;
      case 'goToMain':
        break;
      case 'error':
        break;
      case 'startLogin':
        break;
      case 'numberOnline':
      case 'numberStatus':
        const newContacts = [...(store.getState() as RootState).agenda.subscribed]
        let newStatus: string = undefined
        let newIsOnline: boolean = undefined
        let index = newContacts.findIndex(c => c.phone == data.number)
        //console.log("newContacts[index]", newContacts[index])
        if (index == -1) return;
        if (event == 'numberOnline') {
          if (newContacts[index].status != "NUMBER_OK") newStatus = "NUMBER_OK";
          if (newContacts[index].isOnline != data.isOnline) newIsOnline = data.isOnline;
        } else if (event == 'numberStatus') {
          if (newContacts[index].status != data.numberStatus) newStatus = data.numberStatus;
        }
        if (newStatus) {
          let newContact = { ...newContacts[index] }
          newContact.status = newStatus
          newContacts[index] = newContact
        }
        if (newIsOnline !== undefined) {
          let newContact = { ...newContacts[index] }
          newContact.isOnline = newIsOnline
          if( newIsOnline === false ){
            newContact.lastSeen = Date.now()
          }
          newContacts[index] = newContact
        }
        if (newStatus || newIsOnline !== undefined) {
          console.log("updating...")
          store.dispatch(setSubscribed(newContacts));
        }
        break;

      default:
    };
  };

  async waitSocketReady() {
    while (!this.isConnected || !this.isLoggedIn) {
      await new Promise(res => setTimeout(res, 20));
    }
  }

  async send(event: string, data: object = {}) {
    return new Promise(resolve => {
      const dataToSend = { ...data, ...{ token: AuthService.authToken(), tempLoginCode: AuthService.tempLoginCode() } }
      console.log("Manda:", event, dataToSend);
      this.socket.emit(event, dataToSend, (response) => {
        console.log("response", response)
        if ('action' in response && 'data' in response) this.socketEventHandler(response.action, response.data);
        resolve(response)
      });
    })
  }

  authenticate() {
    this.send('auth')
  }
}

export const socketService = new SocketService()
