import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome5";
import { HeaderComponent } from '../components/header';
import { ImageAspectFit } from '../components/image';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { Plan } from '../shared/interfaces';
import { globalStyles } from '../shared/styles';
import { loadStripe, PaymentIntent, PaymentMethod, Stripe, StripeElements, StripeError, StripeCardElement } from '@stripe/stripe-js';
import { configService } from '../services/config.service';
import { setIsLoading } from '../store/ui/ui.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthService } from '../services/auth-service';
import { useNavigation, useFocusEffect, useRoute } from "@react-navigation/native";
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';
import { isApp } from '../shared';
import { APP } from '../shared/constants';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

declare var window: any
let stripe: Stripe, elements: StripeElements, cardElement: StripeCardElement

export const BuySubscriptionScreen = () => {

  const navigation = useNavigation()
  const { t } = useTranslation()
  const route = useRoute()
  const router = useRouter()
  const dispatch = useDispatch()
  const authService = useAuthService()
  const isLoading = useSelector( (state: RootState) => state.ui.isLoading )
  const [plans, setPlans] = useState<Plan[]>([])
  const [selectedPlan, setSelectedPlan] = useState<Plan>(null)
  const [payWithStripe, setPayWithStripe] = useState<boolean>(false)
  const [stripeCardOk, setStripeCardOk] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<string>("")
  const [fullName, setFullName] = useState<string>("")

  // SI APLICA LA CAMPAÑA DE MES GRATIS PARA ANTIGUOS SUSCRIPTORES
  const appliesReengageCampaign = useMemo(()=>
    false && // <- ESTOY ANULANDO ESTO TEMPORALMENTE
    !authService.isValidSubscription &&
    moment().toDate().getTime() - moment( authService.subscription.end ).toDate().getTime() > (7*24*60*60*1000)
  ,[authService])

  useFocusEffect(useCallback(() => {
    router.functionalGuard({ from: "BuySubscription" })
  }, [authService.isLoggedIn])
  )

  useFocusEffect( useCallback(() => {
    if ((route.params as any)?.withoutAnimation) navigation.setOptions({
      animationEnabled: false,
      cardStyleInterpolator: undefined, 
    });
}, [])
);

  const getFrequency = (desc: string) => {
    if (desc.toLocaleLowerCase().indexOf("year") != -1) return { name: t('buySubscription.yearly'), short: "YR", period: t('buySubscription.year') };
    if (desc.toLocaleLowerCase().indexOf("month") != -1) return { name: t('buySubscription.monthly'), short: "MO", period: t('buySubscription.month') };
    return { name: t('buySubscription.weekly'), short: "WK", period: t('buySubscription.week') };
  }


  useFocusEffect( useCallback(() => {
    router.loggedInGuard()
      .then(() => {
        http.get("subscription/subscriptionsListV2")
          .then(response => {
            let _plans = response.data.subscriptions.map(s => ({
              ...s,
              frequency: getFrequency(s.description)
            }))
            setPlans(_plans)
          })
          .catch()
      })
      .catch(e => console.log(e))
  }, []) )

  useEffect(() => {
    if (payWithStripe) {
      initializeStripe()
    }
  }, [payWithStripe])

  const initializeStripe = async () => {
    stripe = await loadStripe(configService.get('stripePublishableKey'))
    if (stripe) elements = stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap'
        }
      ]
    });
    if (!elements) return;

    cardElement = elements.create("card", {
      style: {
        base: {
          iconColor: '#41d0ff',
          color: 'black',
          fontWeight: '400',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: 'rgba(0,0,0,0.6)',
          },
        },
        invalid: {
          iconColor: '#ff87a3',
          color: '#ff87a3',
        },
      }
    });
    cardElement.mount("#card-element");
    let cardFocused
    cardElement.on('change', (event) => { setStripeCardOk( event.complete ) });
    cardElement.on('focus', (event) => { cardFocused = false; });
    cardElement.on('blur', (event: any) => {
      setStripeError(event.error?.message || null)
    });
  };

  const payWithPaypal = useCallback(() => {
    try{
      mixpanel.track('Pay with PayPal clicked')
    }catch(e){}
    window.location.href = selectedPlan.purchaseUri;
  }, [selectedPlan])

  const purchaseStripeSubscription = useCallback(async () => {
    console.log("purchaseStripeSubscription")
    let paymentMethod: any
    try {
      dispatch(setIsLoading(true))
      //this.loadingBar(10, "Setting up payment.");
      paymentMethod = await createPaymentMethod();
      console.log("PM OK!", paymentMethod);
      //this.loadingBar(30, "Creating subscription.");
      await createSubscription(paymentMethod.id);
    } catch (e: any) {
      console.error(e)
      dispatch(setIsLoading(false))
      setStripeError(e?.message)
    }
  }, [fullName, setStripeError])

  const createPaymentMethod = useCallback(async (): Promise<PaymentMethod | null> => {
    console.log("createPaymentMethod")

    return new Promise((res, rej) => {
      if (!fullName || fullName.length < 5) {
        rej(new Error(t('buySubscription.errorFullName')))
        return;
      }
      console.log("stripe", stripe)
      if (!stripe) {
        rej(new Error(t('buySubscription.errorCouldNotCreate')));
        return;
      };

      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: fullName,
          },
        })
        .then(function (result) {
          console.log("result", result)
          // Handle result.error or result.paymentMethod
          if (result.error) rej(result.error)
          else res(result.paymentMethod)
        });
    })

  }, [fullName])


  const createSubscription = useCallback(async (paymentMethodId: string) => {

    console.log("createSubscription")
    console.log(selectedPlan)

    console.log({
      paymentMethodId: paymentMethodId,
      subscriptionId: selectedPlan.key
    })

    //this.loadingBar(40, "Creating subscription.");

    /*const timeoutAvance = setTimeout(() => {
      this.loadingBar(60, "Creating subscription.");
    }, 3000);*/

    let result: any
    try {
      result = await http.post('subscription/createStripeSubscriptionV2', {
        paymentMethodId,
        subscriptionId: selectedPlan.key
      })
    } catch (error) {
      console.log("PURCHASE ERROR", error);
      setStripeError(error)
    }

    console.log("PURCHASE RESULT", result);

    if (result.code == 0) {
      //this.loadingBar(100, "complete!");
      //this.auth.clearSubscriptionData();
      await authService.getSessionInfo()
      try{
        mixpanel.track('New Stripe subscription')
      }catch(e){}
      navigation.navigate("ThankYou" as never)
      return;
    };


    if (result.code == 500 || result.code == 501 || result.code == 502 || result.code == 505 || result.code == 506 || result.code == 101) {
      // 500 error al attach payment method
      // 501 no se pudo completar pago porque tiene que verificar la tarjeta
      if (result.code == 501) { confirmPayment(result.content.latest_invoice.payment_intent, paymentMethodId); return }
      // 506 payment intent pending (para activar la tarjeta 3ds de una vez y no esperar a que truene la sub)
      if (result.code == 506) { confirmCardSetup(result.content.pending_setup_intent, paymentMethodId); return }
      // 502 ya tiene suscripción
      if (result.code == 502) {
        navigation.navigate("ThankYou" as never)
        return;
      }
      // 101 No price for currency and payment id was found.
      // The card had an error when trying to attach it to a customer.
      setStripeError(result.message)
    }

  }, [selectedPlan, setStripeError])

  const confirmCardSetup = useCallback(async (pendingPaymentIntent: any, paymentMethodId: string) => {
    if (elements && stripe) {
      stripe
        .confirmCardSetup(pendingPaymentIntent.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: fullName,
            },
          },
        })
        .then((result) => {
          //this.loadingBar(100, "complete!");
          if (result.error) {
            setStripeError(result.error.message)
            dispatch(setIsLoading(false))
            return;
          } else {
            navigation.navigate("ThankYou" as never)
          }
        });
    }
  }, [fullName, setStripeError])

  const confirmPayment = useCallback(async (paymentIntent: any, paymentMethodId: string) => {
    //this.loadingBar(70, "Payment method confirmation");
    if (elements && stripe) {
      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        { payment_method: paymentMethodId });
      //this.loadingBar(100, "complete!");
      if (result.error) {
        setStripeError(result.error.message)
        dispatch(setIsLoading(false))
        return;
      }

      if (result.paymentIntent.status === 'succeeded') {
        //console.log("SUCCESS", result)
        await authService.getSessionInfo()
        navigation.navigate("ThankYou" as never)
      }
    };
  }, [setStripeError])

  return (
    <View style={globalStyles.screenBase} >
      <HeaderComponent />
      <View style={styles.main} >
        <View style={[styles.headerCard, { backgroundColor: "transparent", paddingVertical: 12, justifyContent: "center", alignItems: "center" }]}>
          <ImageAspectFit asset={require('../../assets/images/subs-calendar.png')} style={{ width: 50, height: 50, marginRight: 12 }} imageStyle={{ tintColor: '#e99b2d' }} />
          <Text style={[globalStyles.title, { textAlign: "center", textTransform: 'uppercase' }]} >{t('buySubscription.subscription')}</Text>
        </View>
        {!selectedPlan &&
          <>
            { !appliesReengageCampaign && <Text style={[globalStyles.fontFamily, { lineHeight: 18, marginTop: 4, marginBottom: 10, color: globalStyles.title.color, textAlign: "center", fontSize: 15 }]} >{t('buySubscription.startYourTrial')}:</Text>}
            { appliesReengageCampaign && 
              <View style={[ globalStyles.contactCard, { backgroundColor: 'rgba(255,255,255,0)', padding: 16, flexDirection: 'row' } ]}>
                <Icon size={26} color={'gold'} name={"star"} solid />
                  <Text style={[ globalStyles.fontFamily, { color: globalStyles.title.color, fontSize: 15, marginLeft: 10, textAlign: 'center', flexGrow: 1 } ]}>¡Te ofrecemos <Text style={ globalStyles.fontFamilyBold }>un mes gratis</Text> para probar{'\n'}la nueva versión de Chatwatch!</Text>
                <Icon size={26} color={'gold'} name={"star"} solid />
              </View>
              }
            <ScrollView contentContainerStyle={{ marginTop: 12, alignItems: "stretch" }}>
              {plans.map(plan => (
                <View style={[globalStyles.contactCard, { backgroundColor: styles.contactCard.backgroundColor }]} key={plan.key}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", paddingVertical: 10, paddingHorizontal: 12, backgroundColor: styles.headerCard.backgroundColor }}>
                    <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { flexGrow: 1, color: "white" }]}>{plan.frequency.name}</Text>
                    <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { color: "white" } ]}>{plan.value} USD<Text style={{}}>/{plan.frequency.period}</Text></Text>
                  </View>
                  <View style={{ alignItems: 'flex-start', width: "100%", paddingVertical: 10, paddingHorizontal: 12 }}>

                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature1',{count:plan.maxNumbers})}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature2')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature3')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature4')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature5')}</Text>

                    <TouchableOpacity style={[globalStyles.primaryButton, { marginTop: 10, backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.primaryButton.backgroundColor : 'rgb(32 193 154)' }]} onPress={() => setSelectedPlan(plan)}>
                      <Text style={globalStyles.primaryButtonText}>{t('buySubscription.selectPlan')}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </ScrollView>
          </>
        }
        {selectedPlan &&
          <View style={{ width: "100%", flexGrow: 1 }}>
            <View style={{ width: "100%", flexGrow: 1 }}>
              <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { textAlign: "center", textTransform: 'uppercase' }]}>{t('buySubscription.selectedPlan')}:</Text>
              <View style={styles.contactCard}>
                <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", paddingVertical: 10, paddingHorizontal: 12, backgroundColor: styles.headerCard.backgroundColor }}>
                  <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { flexGrow: 1,color: "white" }]}>{selectedPlan.frequency.name}</Text>
                  <Text style={[globalStyles.baseText, globalStyles.fontFamilyBold, { color: "white" }]}>{selectedPlan.value} USD<Text style={{}}>/{selectedPlan.frequency.period}</Text></Text>
                </View>
                <View style={{ alignItems: 'flex-start', width: "100%", paddingVertical: 10, paddingHorizontal: 12, display: selectedPlan ? 'none' : 'flex' }}>

                <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature1',{count:selectedPlan.maxNumbers})}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature2')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature3')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature4')}</Text>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16 }}>• {t('subscriptionDetails.feature5')}</Text>
                </View>
              </View>

              {!payWithStripe &&
                <>
                  <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { textAlign: "center", marginVertical: 16, textTransform: "uppercase" }]}>{t('buySubscription.payWith')}:</Text>

                  <TouchableOpacity style={[globalStyles.primaryButton, { marginTop: 10, backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.primaryButton.backgroundColor : 'rgb(32 193 154)' }]} onPress={() => setPayWithStripe(true)}>
                    <Icon size={17} color={'white'} name={"credit-card"} solid />
                    <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', fontSize: 17, textTransform: "uppercase", marginLeft: 12 }]}>{t('buySubscription.card')}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={[globalStyles.primaryButton, { marginTop: 10, backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.primaryButton.backgroundColor : 'rgb(32 193 154)' }]} onPress={() => payWithPaypal()}>
                    <Icon size={17} color={'white'} name={"paypal"} solid />
                    <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', fontSize: 17, textTransform: "uppercase", marginLeft: 12 }]}>PAYPAL</Text>
                  </TouchableOpacity>
                </>}

              {payWithStripe &&
                <View style={[styles.contactCard]}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', width: "100%", paddingVertical: 10, paddingHorizontal: 12, backgroundColor: styles.headerCard.backgroundColor }}>
                    <Text style={{ ...globalStyles.fontFamily, fontSize: 16, fontWeight: "700", color: "white", flexGrow: 1 }}>{ t('buySubscription.payWithCard') }</Text>
                  </View>
                  <View style={{ alignItems: 'flex-start', width: "100%", paddingVertical: 10, paddingHorizontal: 12 }}>

                    <TextInput
                      style={{ ...styles.input, marginTop: 10 }}
                      inputMode={'text'}
                      autoCapitalize={'none'}
                      autoComplete={'name'}
                      autoCorrect={false}
                      placeholder={t('buySubscription.fullName')}
                      placeholderTextColor="rgba(0,0,0,0.6)"
                      onChangeText={(text) => setFullName(text)}
                      value={fullName}
                    />

                    <View style={[styles.input, { justifyContent: "center" }]}>
                      <div id="card-element"></div>
                    </View>

                    {!!stripeError && <View style={{ flexDirection: "row", width: "100%", marginTop: 16, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
                      <Icon size={17} color={'rgba(165,0,0,0.8)'} name={"exclamation-triangle"} solid />
                      <Text style={{ ...globalStyles.fontFamily, color: 'rgba(165,0,0,0.8)', fontSize: 17, textAlign: "center", marginLeft: 6, fontWeight: "600" }}>{stripeError}</Text>
                    </View>}

                    <TouchableOpacity style={[globalStyles.primaryButton, { marginTop: 10, opacity: !!fullName && stripeCardOk && !isLoading ? 1 : 0.5, backgroundColor: isApp(APP.CHATWATCH) ? globalStyles.primaryButton.backgroundColor : 'rgb(32 193 154)' }]} onPress={() => purchaseStripeSubscription()} disabled={!fullName || !stripeCardOk || isLoading}>
                      <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', fontSize: 17, textTransform: "uppercase", marginLeft: 12, marginRight: 12 }]}>{t('buySubscription.subscribe')}</Text>
                      { isLoading && <ActivityIndicator color="white" size={20} /> }
                    </TouchableOpacity>

                    <Image source={require('../../assets/images/stripe-badge-transparent.png')} resizeMode='contain' style={{ width: "100%", aspectRatio: 3.045 }} />
                  </View>
                </View>
              }


            </View>
            <View style={{ width: "100%" }}>
              <TouchableOpacity style={[globalStyles.secondaryButton, { marginTop: 10 }]} onPress={() => payWithStripe ? setPayWithStripe(false) : setSelectedPlan(null)}>
                <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', fontSize: 17, textTransform: "uppercase", marginLeft: 12 }]}>{t('goBack')}</Text>
              </TouchableOpacity>
            </View>
          </View>}

      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  containerCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    width: "100%",
    flexGrow: 1,
    alignItems: 'stretch',
    padding: 24,
    paddingTop: 0,
    maxWidth: 600,
    alignSelf: 'center'
  },
  headerCard: {
    flexGrow: 0,
    width: "100%",
    alignItems: 'center',
    padding: 10,
    flexDirection: 'row',
    backgroundColor: globalStyles.mainCardBackground.backgroundColor,
    borderRadius: 13
  },
  contactCard: {
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: 13,
    alignItems: 'center',
    marginVertical: 12,
    borderWidth: 0,
    overflow: "hidden"
  },
  input: {
    ...globalStyles.fontFamily,
    width: "100%",
    height: 50,
    fontSize: 17,
    paddingHorizontal: 16,
    borderRadius: 7,
    backgroundColor: 'rgba(255,255,255,0.1)',
    backdropFilter: "blur(5px)",
    color: 'black',
    fontWeight: "400"
  },
});
