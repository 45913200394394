import React, { Component } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { ImageAspectFit } from "./image";
import { globalStyles } from "../shared/styles";
import { isApp } from "../shared";
import { APP } from "../shared/constants";

export interface SearchBarProps{
  setSearchPhrase: any,
  placeholder: string
}

export class SearchBar extends Component<SearchBarProps>{
  render(): React.ReactNode {
    return (
      <View style={styles.container}>
        <View
          style={ globalStyles.searchBar }
        >
          {/* search Icon */}
          <ImageAspectFit asset={ require("../../assets/images/icon_search_1_.png")} style={{ width:20, height: 20 }} imageStyle={{ tintColor: isApp(APP.CHATWATCH) ? undefined : 'black' }} />
          {/* Input field */}
          <TextInput
            style={globalStyles.searchBarInput}
            autoCapitalize="none"
            placeholder={ this.props.placeholder }
            placeholderTextColor={globalStyles.placeholderText.color}
            onChangeText={this.props.setSearchPhrase}
          />
        </View>
      </View>
    );
  }
};
export default SearchBar;

// styles
const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",

  },
});