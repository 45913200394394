import {createReducer} from '@reduxjs/toolkit';
import { UiState } from './UiState';
import { setAppState, setIsFirstLoaded, setIsFirstScreen, setIsLoading, setShowDrawer, setSocketIOConnected, setTelegramBotLink, setTogglingOnlineStatus } from './ui.actions';
import { AppState } from 'react-native';

const initialState: UiState = {
  isLoading: false,
  showDrawer: false,
  isFirstLoaded: true,
  telegramBotLink: null,
  togglingOnlineStatus: false,
  appState: AppState.currentState,
  socketIOConnected: false,
  isFirstScreen: true
};

export const uiReducer = createReducer(initialState, builder => {
  builder.addCase(setIsLoading, (state, action) => {
    return { ...state, isLoading: action.payload};
  });
  builder.addCase(setShowDrawer, (state, action) => {
    return { ...state, showDrawer: action.payload};
  });
  builder.addCase(setIsFirstLoaded, (state, action) => {
    return { ...state, isFirstLoaded: action.payload};
  });
  builder.addCase(setTelegramBotLink, (state, action) => {
    return { ...state, telegramBotLink: action.payload};
  });
  builder.addCase(setTogglingOnlineStatus, (state, action) => {
    return { ...state, togglingOnlineStatus: action.payload};
  });
  builder.addCase(setAppState, (state, action) => {
    return { ...state, appState: action.payload};
  });
  builder.addCase(setSocketIOConnected, (state, action) => {
    return { ...state, socketIOConnected: action.payload};
  });
  builder.addCase(setIsFirstScreen, (state, action) => {
    return { ...state, isFirstScreen: action.payload};
  });
});
