import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import { en } from '../../assets/i18n/en.js';
import { es } from '../../assets/i18n/es.js';
import { de } from '../../assets/i18n/de.js';
import { it } from '../../assets/i18n/it.js';
import { fr } from '../../assets/i18n/fr.js';
import { nl } from '../../assets/i18n/nl.js';
import { pt } from '../../assets/i18n/pt.js';
import { ru } from '../../assets/i18n/ru.js';
import { tr } from '../../assets/i18n/tr.js';
import { hi } from '../../assets/i18n/hi.js';
import { getDeviceLanguage } from '../shared/helpers';
  
i18next.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: getDeviceLanguage(),
  fallbackLng: 'en',
  resources: {
    en: { translation: en },
    es: { translation: es },
    de: { translation: de },
    it: { translation: it },
    fr: { translation: fr },
    nl: { translation: nl },
    pt: { translation: pt },
    ru: { translation: ru },
    tr: { translation: tr },
    hi: { translation: hi },
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

//export { i18nKeys }