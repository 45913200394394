import { useCallback, useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native";
import { globalStyles } from "../../shared/styles";
import { useTranslation } from "react-i18next";
import { isApp } from "../../shared";
import { APP } from "../../shared/constants";

export const LastSeen = ({ lastSeen, isOnline }: { lastSeen?: number, isOnline: boolean }) => {

    const { t } = useTranslation()
    const [interval,_setInterval] = useState<NodeJS.Timeout>(null)
    const [seconds,setSeconds] = useState<number>(0)

    useEffect(()=>{
        _setInterval( setInterval(()=>{
            setSeconds( seconds => seconds+1)
        }, 1000) )

        return () => {
            clearInterval( interval )
        }
    },[])

    const formatLastSeen = useCallback(()=>{
        if( isOnline ) return t('main.contactCard.lastSeen.online');
        if( !lastSeen ) return "";
        const diff = Math.floor( (Date.now() - lastSeen) / 1000 );
        if( diff < 1 ) return t('main.contactCard.lastSeen.online');
        else if( diff < 60 ) return t('main.contactCard.lastSeen.secsAgo', {count: diff});
        else if( diff < 3600 ) return t('main.contactCard.lastSeen.minsAgo', {count: Math.floor( diff / 60 )});
        else if( diff < 86400 ) return t('main.contactCard.lastSeen.hoursAgo', {count: Math.floor( diff / 3600 )});
        else if( diff < 2592000 ) return t('main.contactCard.lastSeen.daysAgo', {count: Math.floor( diff / 84600 )});
        else return "";
    },[lastSeen,seconds,isOnline])

    const showLastSeen = useCallback(()=>{
        // SI LA FECHA DE CONEXIÓN ES MENOR A UN MES
        return (!!lastSeen && Date.now() - lastSeen < 2592000000 ) || isOnline;
    },[lastSeen,isOnline])

    return !showLastSeen() ? null : (
        <Text style={ styles.lastSeen }>{ formatLastSeen() }</Text>
    )
}


const styles = StyleSheet.create({
    lastSeen: {
        ...globalStyles.fontFamily,
        position: "absolute",
        top: 0,
        right: 0,
        paddingVertical: 3,
        width: 100,
        backgroundColor: isApp(APP.CHATWATCH) ? "rgba(32, 3, 75, 0.05)" : 'rgb(255 255 255 / 54%)',
        borderRadius: 19,
        height: 21,
        fontSize: 11,
        fontWeight: "700",
        textAlign: "center"
    }
});