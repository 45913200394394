import { useCallback, useEffect } from "react";
import { RootState, store } from "../store";
import { setIsLoading } from "../store/ui/ui.actions";
import { useAuthService } from "./auth-service";
import { useNavigation, StackActions } from "@react-navigation/native";
import { resetAgenda } from "../store/agenda/agenda.actions";
import { clearAllAuth } from "../store/auth/auth.actions";
import { useSelector } from "react-redux";
import { RootStackParamList } from "../navigation/root-stack-param-list";
import { APP, CURRENT_APP } from "../shared/constants";


export const useRouter = () => {

    const navigation = useNavigation()
    const authService = useAuthService()

    const enroute = async () => {
        const isFirstScreen = store.getState().ui.isFirstScreen
        console.log("enroute isFirstScreen",isFirstScreen)
        const params = isFirstScreen ? { withoutAnimation: true} : {}
    
        store.dispatch(setIsLoading( true ))
        let isLoggedIn = authService.isLoggedIn; 
        if (!isLoggedIn) {
            store.dispatch(resetAgenda())
            store.dispatch(clearAllAuth())
            navigation.dispatch(StackActions.push('Login',params));
          return;
        }
    
        let { success, isWALoggedIn } = await authService.getSessionInfo();
        if(!success) return;
    
        let validSubscription = store.getState().auth.subscription.valid
        if( !validSubscription ){
          store.dispatch(setIsLoading( false ))
          navigation.dispatch(StackActions.push('BuySubscription',params));
          return;
          // ENVIAR A COMPRAR PRODUCTO
        }
    
        if( !isWALoggedIn ){
          store.dispatch(setIsLoading( false ))
          store.dispatch(resetAgenda())
          console.log("router go to WALogin...")
            navigation.dispatch(StackActions.push('WALogin',params));
          return
        }
    
        //store.dispatch(hideLoading());
        navigation.dispatch(StackActions.push('Main',params));
    }

    const loggedInGuard = useCallback( async () =>{
        const isFirstScreen = store.getState().ui.isFirstScreen
        const params = isFirstScreen ? { withoutAnimation: true} : {}
        if( !authService.isLoggedIn ){
            navigation.dispatch(StackActions.push('Login',params));
            throw new Error("Not logged in")
        }
    },[authService])

    const functionalGuard = useCallback( async ({ from }: {from?: keyof RootStackParamList } = {}) =>{
        console.log("functionalGuard from",from)
        try{
            await authService.getSessionInfo()
        }catch(e){
            throw new Error("Missing internet connection")
        }
        const isFirstScreen = store.getState().ui.isFirstScreen
        const params = isFirstScreen ? { withoutAnimation: true} : {}

        if( !authService.isLoggedIn ){
            store.dispatch(resetAgenda())
            store.dispatch(clearAllAuth())
            console.log("functionalGuard ["+from+"] redirecting to Login...")
            navigation.dispatch(StackActions.push('Login',params));
            return false
            //throw new Error("Not logged in")
        }
        if( !!from && from == 'BuySubscription' ){
            if( authService.isValidSubscription ){
                console.log("functionalGuard ["+from+"] ! redirecting to Main...")
                navigation.dispatch(StackActions.push('Main',params));
                return false
            }
            return true;
        }else if( !authService.isValidSubscription && !!from && from != 'BuySubscription' ){
            console.log("functionalGuard ["+from+"] redirecting to BuySubscription...")
            navigation.dispatch(StackActions.push('BuySubscription',params));
            return false
            //throw new Error("Missing subscription")
        }
        if( !!from && from == 'ThankYou' ) return true;
        if( !authService.isWALoggedIn && !!from && from != 'WALogin' && from != 'QRScan' && from != 'SubscriptionDetails' ){
            store.dispatch(resetAgenda())
            console.log("functionalGuard ["+from+"] redirecting to WALogin...")
            navigation.dispatch(StackActions.push('WALogin',params));
            return false
            //throw new Error("Is not WA logged in")
        }
        console.log("functionalGuard ["+from+"] no redirecting")
        return true
    },[authService])

    const notFunctionalGuard = useCallback( async ({ from }: {from?: keyof RootStackParamList } = {}) =>{
        if( CURRENT_APP == APP.WAPATROL && from != "QRScan" ){
            return navigation.dispatch(StackActions.push('QRScan'));
        }
        console.log("notFunctionalGuard from",from)
        if( authService.isLoggedIn ){
            console.log("functionalGuard ["+from+"] redirecting to (enroute())...")
            enroute()
            return false
            //throw new Error("Not logged in")
        }
        console.log("notFunctionalGuard ["+from+"] no redirecting")
        return true
    },[authService])

    return {
        enroute,
        loggedInGuard,
        functionalGuard,
        notFunctionalGuard
    }
}