import {createReducer} from '@reduxjs/toolkit';
import { AgendaState } from './AgendaState';
import { collapseAll, resetAgenda, setContacts, setSubscribed } from './agenda.actions';

declare var document: any

const initialState: AgendaState = {
  contacts: [],
  subscribed: [],
};

export const agendaReducer = createReducer(initialState, builder => {
    builder.addCase(setContacts, (state, action) => {
      /*const stateContactsString = JSON.stringify( state.contacts.sort((c1,c2)=>c1.id > c2.id ? 1 : -1) )
      const newContactsString = JSON.stringify( [...document.payload].sort((c1,c2)=>c1.id > c2.id ? 1 : -1) )
      console.log("stateContactsString == newContactsString",stateContactsString == newContactsString)
      if( stateContactsString == newContactsString ) return;*/
      return { ...state, contacts: action.payload};
    });
    builder.addCase(setSubscribed, (state, action) => {
      return { ...state, subscribed: action.payload};
    });
    builder.addCase(resetAgenda, (state, action) => {
      return { ...state, contacts: [], subscribed: []};
    });
    builder.addCase(collapseAll, (state, action) => {
      let newSubscribed = state.subscribed.map(c=>({...c, isCollapsed: true}))
      return { ...state, contacts: [], subscribed: newSubscribed};
    });
});
