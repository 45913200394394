export const it = {
    "app.name": "ChatWatch",
    "language": "it",
    "slogan": "L'originale Whatsapp\nInseguitore online",
    "goBack": "Torna indietro",
    "menu": {
      "home": "Casa",
      "subscription": "Abbonamento",
      "logOut": "Esci"
    },
    "login": {
      "login": "Accesso",
      "logIn": "Accedi",
      "email": "Email",
      "password": "Password",
      "forgotPassword": "Hai dimenticato la password?",
      "dontHaveAccount": "Non hai un account?",
      "register": "Registrazione",
      "errorValidEmail": "Si prega di impostare un'e-mail valida",
      "errorPasswordEmpty": "Imposta una password"
    },
    "register": {
      "register": "Registrazione",
      "email": "Email",
      "password": "Password di accesso",
      "repeatPassword": "Ripeti la password",
      "alreadyHaveAccount": "Hai già un account?",
      "logIn": "Accedi",
      "errorValidEmail": "Si prega di impostare un'e-mail valida",
      "errorPasswordEmpty": "Imposta una password",
      "errorPasswordShort": "La password deve essere di almeno 8 caratteri",
      "errorPasswordNotMatch": "Le password non sono uguali"
    },
    "recoverPassword": {
      "recoverPassword": "Recupera la password",
      "errorValidEmail": "Impostare un'e-mail valida",
      "email": "Email",
      "emailSent": "Email inviata"
    },
    "buySubscription": {
      "subscription": "Abbonamento",
      "week": "SETTIMANA",
      "month": "MESE",
      "year": "ANNO",
      "weekly": "SETTIMANALE",
      "monthly": "MENSILE",
      "yearly": "ANNUALE",
      "startYourTrial": "INIZIA LA PROVA GRATUITA DI 3 GIORNI\nSELEZIONA ABBONAMENTO",
      "selectPlan": "PIANO SELEZIONATO",
      "selectedPlan": "PIANO SELEZIONATO",
      "payWith": "PAGA CON",
      payWithCard: 'PAGA CON CARTA',
      "card": "Carta",
      "fullName": "Nome e cognome",
      "subscribe": "ABBONAMENTO",
      "errorFullName": "Si prega di fornire il nome completo.",
      "errorCouldNotCreate": "Impossibile creare il pagamento. Riprovare. CODICE:1"
    },
    "chatOddsIntro": {
      "chatOdds": "Probabilità di chat",
      "wantToTrackAnother": "Vuoi rintracciare un altro contatto a\nper vedere se stanno chattando insieme a {n}?",
      "later": "Più tardi",
      "yes": "Sì"
    },
    "noContacts": {
      "noContacts": "Nessun contatto",
      "itSeems": "Sembra che non ci siano ancora\ncontatti tracciati...",
      "willBeRecorded": "Le informazioni online verranno registrate a partire da adesso\nper i contatti selezionati.\nSelezionare il primo contatto da rintracciare.",
      "addContact": "Aggiungi un contatto da rintracciare"
    },
    "qrScan": {
      "enterCode": "Inserire il codice di accesso",
      "followInstructions": "Seguire le istruzioni per scansionare questo\nsul vostro dispositivo principale.",
      "enjoy": "Buon divertimento con ChatWatch!",
      "canClose": "Puoi chiudere questa finestra",
      "openUrl": "<0>Devi aprire questo url\nda <3>UN ALTRO</3> dispositivo</0>",
      "updatingServices": "Stiamo aggiornando i nostri servizi,\nper favore ricarica la pagina e riprova.",
      "msgConnecting1": "Connessione a WA... Attendere...",
      "msgConnecting2": "Sincronizzazione dell'account. Attendere prego...",
      "msgConnecting3": "Recupero informazioni... Attendere prego...",
      "msgConnecting4": "Sessione inizializzata."
    },
    "resetPassword": {
      "resetPassword": "Reimpostare la password",
      "errorPasswordEmpty": "Impostare una password",
      "errorPasswordShort": "La password deve essere di almeno 8 caratteri",
      "errorPasswordNotMatch": "Le password non sono uguali",
      "errorLinkNotValid": "Link non valido",
      "newPassword": "Nuova password",
      "repeatNewPassword": "Ripetere la nuova password",
      "passwordReseted": "Password ripristinata"
    },
    "thankYou": {
      "thankYou": "Grazie\nper la tua iscrizione!",
      "continue": "CONTINUA"
    },
    "waLogin": {
      "privacyNotice": "Avviso sulla privacy",
      "weNotStore": "Non memorizziamo, leggiamo o\naccediamo ai dati dell'account WA.",
      "next": "Avanti",
      "howToLogin": "Come accedere?",
      "howToLogin1": "Trova un computer, un tablet, un telefono o una Smart TV\nVisita",
      "howToLogin2": "Inserisci il seguente codice",
      "link": "Collegare l'account WA",
      "link1": "Aprire le impostazioni di WA toccando questa icona.",
      "link2": "Selezionare i dispositivi collegati.",
      "link3": "Quando la fotocamera è attivata, puntare il telefono\nper scansionare il codice QR.",
      "connecting": "COLLEGAMENTO",
      "somethingWrong": "Qualcosa è andato storto.\nRicaricare la pagina e riprovare.",
      "msgConnecting1": "Connessione a WA... Attendere...",
      "msgConnecting2": "Sincronizzazione dell'account. Attendere...",
      "msgConnecting3": "Recupero informazioni... Attendere...",
      "msgConnecting4": "Session initialized."
    },
    "main": {
      "connectionManager": "Connection Manager",
      "trackedContacts": "Tracked contacts",
      "contactTrackingActive": "<0>Contact tracking is <1>active</1>.<br/>You can deactivate tracking to appear offline. Later, you can activate it with one click. There is no need scan the QR code again.</0>",
      "contactTrackingInactive": "<0>Contact tracking is <1>inactive</1>.</0>",
      "deactivateTracking": "Deactivate tracking",
      "addContact": "Add contact to track",
      "activateTracking": "Attivare il tracciamento!",
      "contactCard": {
        "notTracking": "Non tracciare",
        "howToTrack": "Come rintracciare un contatto?",
        "onlineNotification": "Notifica online",
        "onlineHistory": "Cronologia online",
        "chatODDS": "Chat OODS",
        "nightWatch": "Controllo notturno",
        "delete": "Cancellare",
        "lastSeen": {
          "online": "In linea",
          "secsAgo": "{{count}} Sec fa",
          "secsAgo_plural": "{{count}} Secs Ago",
          "minsAgo": "{{count}} Min Ago",
          "minsAgo_many": "{{count}} Minuti fa",
          "hoursAgo": "{{count}} Ora Precedente",
          "hoursAgo_plural": "{{count}} Ore fa",
          "daysAgo": "{Giorno Giorno fa",
          "daysAgo_plural": "{{count}} Giorni fa"
        }
      }
    },
    "subscriptionDetails": {
      "subscriptionDetails": "Dettagli dell'abbonamento",
      "feature1": "Traccia fino a {{count}} contatti!",
      "feature2": "Cronologia online",
      "feature3": "Approfondimenti sui contatti",
      "feature4": "Probabilità di chat addestrata dall'intelligenza artificiale",
      "feature5": "Support team (email)",
      "hasBeenCancelled": "<0>This subscription has been <1>cancelled</1> and your card will not be charged again.</0>",
      "accessWillEndOn": "Your access will end on",
      "nextPaymentDate": "Next payment date",
      "cancelPlan": "Cancel plan",
      "howToCancel": "How to cancel your plan?",
      "howToCancelText": "Accedere al proprio conto PayPal.\nFare clic su Strumenti, quindi selezionare Tutti gli strumenti nella parte superiore della pagina.\nQuindi selezionare Pagamenti ricorrenti dalla pagina Strumenti.\nDa qui è possibile annullare l'abbonamento.",
      "cancelSubscription": "Annullamento dell'abbonamento",
      "weAreSorryToEar": "Siamo spiacenti di sapere che desidera annullare l'abbonamento. Ci sono problemi di servizio o di qualità?\nDesidera inviarci un'e-mail per eventuali risoluzioni?\nIn caso contrario, fare clic sul pulsante Annulla abbonamento.",
      "keepSubscription": "Mantenere l'abbonamento"
    },
    "addNumber": {
      "selectContactToCompare": "Selezionare un contatto\nda confrontare",
      "selectContactToTrack": "Selezionare un contatto\nda monitorare",
      "searchBy": "Ricerca per telefono o nome",
      "noResultsFor": "Nessun risultato per",
      "trySearchingByPhone": "Prova a cercare per numero di telefono"
    },
    "onlineHistory": {
      "onlineHistory": "Cronologia online",
      "last24Hours": "Ultime 24 ore",
      "selectADate": "Selezionare una data",
      "time": "Tempo",
      "offlineFor": "Non in linea per",
      "hour": "ora",
      "hour_plural": "ore",
      "from": "da",
      "to": "a",
      "notEnoughtData": "Non ci sono abbastanza dati, tornare più tardi",
      "changeDate": "CAMBIA DATA"
    },
    "compareWith": {
      "selectContactToCompareWith": "Selezionare un contatto da confrontare con {n}",
      "searchBy": "Cerca per telefono o nome"
    },
    "chatProbability": {
      "chatProbability": "Probabilità di chattare",
      "last24Hours": "Ultime 24 ore",
      "selectADate": "Selezionare una data",
      "time": "Tempo",
      "score": "Punteggio",
      "offlineFor": "Non in linea per",
      "hour": "ora",
      "hour_plural": "ore",
      "from": "da",
      "to": "a",
      "notEnoughtData": "Non ci sono abbastanza dati, tornare più tardi",
      "changeDate": "CAMBIA DATA"
    },

    nightWatch: {
      nightWatch: 'Visione notturna',
      sleptFor: 'HO DORMITO PER {{count}} ORE AL MASSIMO',
      sleptFor_plural: 'HO DORMITO PER {{count}} ORE AL MASSIMO',
      owlAlert: 'Avviso gufo',
      highestChatProbability: 'Massima probabilità di chat',
      minutes: 'minuto',
      minutes_plural: 'minuti'
    }
  }

/*let translation = `
ChatWatch
it
L'originale Whatsapp {n} Inseguitore online
Torna indietro
Casa
Abbonamento
Esci
Accesso
Accedi
Email
Password
Hai dimenticato la password?
Non hai un account?
Registrazione
Si prega di impostare un'e-mail valida
Imposta una password
Registrazione
Email
Password di accesso
Ripeti la password
Hai già un account?
Accedi
Si prega di impostare un'e-mail valida
Imposta una password
La password deve essere di almeno 8 caratteri
Le password non sono uguali
Recupera la password
Impostare un'e-mail valida
Email
Email inviata
Abbonamento
SETTIMANA
MESE
ANNO
SETTIMANALE
MENSILE
ANNUALE
INIZIA LA PROVA GRATUITA DI 3 GIORNI {n} SELEZIONA ABBONAMENTO
PIANO SELEZIONATO
PIANO SELEZIONATO
PAGA CON
Carta
Nome e cognome
ABBONAMENTO
Si prega di fornire il nome completo.
Impossibile creare il pagamento. Riprovare. CODICE:1
Probabilità di chat
Vuoi rintracciare un altro contatto a {n} per vedere se stanno chattando insieme a {n}?
Più tardi
Sì
Nessun contatto
Sembra che non ci siano ancora {n} contatti tracciati...
Le informazioni online verranno registrate a partire da adesso {n} per i contatti selezionati. {n} Selezionare il primo contatto da rintracciare.
Aggiungi un contatto da rintracciare
Inserire il codice di accesso
Seguire le istruzioni per scansionare questo {n} sul vostro dispositivo principale.
Buon divertimento con ChatWatch!
Puoi chiudere questa finestra
<0>Devi aprire questo url {n} da <3>UN ALTRO</3> dispositivo</0>
Stiamo aggiornando i nostri servizi, {n} per favore ricarica la pagina e riprova.
Connessione a WA... Attendere...
Sincronizzazione dell'account. Attendere prego...
Recupero informazioni... Attendere prego...
Sessione inizializzata.
Reimpostare la password
Impostare una password
La password deve essere di almeno 8 caratteri
Le password non sono uguali
Link non valido
Nuova password
Ripetere la nuova password
Password ripristinata
Grazie {n} per la tua iscrizione!
CONTINUA
Avviso sulla privacy
Non memorizziamo, leggiamo o {n} accediamo ai dati dell'account WA.
Avanti
Come accedere?
Trova un computer, un tablet, un telefono o una Smart TV {n} Visita
Inserisci il seguente codice
Collegare l'account WA
Aprire le impostazioni di WA toccando questa icona.
Selezionare i dispositivi collegati.
Quando la fotocamera è attivata, puntare il telefono {n} per scansionare il codice QR.
COLLEGAMENTO
Qualcosa è andato storto. {n} Ricaricare la pagina e riprovare.
Connessione a WA... Attendere...
Sincronizzazione dell'account. Attendere...
Recupero informazioni... Attendere...
Session initialized.
Connection Manager
Tracked contacts
<0>Contact tracking is <1>active</1>.<br/>You can deactivate tracking to appear offline. Later, you can activate it with one click. There is no need scan the QR code again.</0>
<0>Contact tracking is <1>inactive</1>.</0>
Deactivate tracking
Add contact to track
Attivare il tracciamento!
Non tracciare
Come rintracciare un contatto?
Notifica online
Cronologia online
Chat OODS
Controllo notturno
Cancellare
In linea
{{count}} Sec fa
{{count}} Secs Ago
{{count}} Min Ago
{{count}} Minuti fa
{{count}} Ora Precedente
{{count}} Ore fa
{Giorno Giorno fa
{{count}} Giorni fa
Dettagli dell'abbonamento
Traccia fino a {{count}} contatti!
Cronologia online
Approfondimenti sui contatti
Probabilità di chat addestrata dall'intelligenza artificiale
Support team (email)
<0>This subscription has been <1>cancelled</1> and your card will not be charged again.</0>
Your access will end on
Next payment date
Cancel plan
How to cancel your plan?
Accedere al proprio conto PayPal. {n} Fare clic su Strumenti, quindi selezionare Tutti gli strumenti nella parte superiore della pagina. {n} Quindi selezionare Pagamenti ricorrenti dalla pagina Strumenti. {n} Da qui è possibile annullare l'abbonamento.
Annullamento dell'abbonamento
Siamo spiacenti di sapere che desidera annullare l'abbonamento. Ci sono problemi di servizio o di qualità? {n} Desidera inviarci un'e-mail per eventuali risoluzioni? {n} In caso contrario, fare clic sul pulsante Annulla abbonamento.
Mantenere l'abbonamento
Selezionare un contatto {n} da confrontare
Selezionare un contatto {n} da monitorare
Ricerca per telefono o nome
Nessun risultato per
Prova a cercare per numero di telefono
Cronologia online
Ultime 24 ore
Selezionare una data
Tempo
Non in linea per
ora
ore
da
a
Non ci sono abbastanza dati, tornare più tardi
CAMBIA DATA
Selezionare un contatto da confrontare con {n}
Cerca per telefono o nome
Probabilità di chattare
Ultime 24 ore
Selezionare una data
Tempo
Punteggio
Non in linea per
ora
ore
da
a
Non ci sono abbastanza dati, tornare più tardi
CAMBIA DATA
`

const obj = it
const lines = translation.split('\n').filter(e=>!!e)
//console.log(lines.join('\n'))
let counter = -1
let result = {}

Object.keys(obj).forEach( k => {
    if( typeof obj[k] == "string" ){
        counter++
        //console.log(k,lines[counter].replaceAll(' {n} ', '\n'))
        result[k] = lines[counter].replaceAll(' {n} ', '\n')
    }else{
        result[k] = {}
        Object.keys(obj[k]).forEach( k2 => {
            if( typeof obj[k][k2] == "string" ){
                counter++
                //console.log(k2,lines[counter].replaceAll(' {n} ', '\n'))
                result[k][k2] = lines[counter].replaceAll(' {n} ', '\n')
            }else{
                result[k][k2] = {}
                Object.keys(obj[k][k2]).forEach( k3 => {
                    if( typeof obj[k][k2][k3] == "string" ){
                        counter++
                        //console.log(k3,lines[counter].replaceAll(' {n} ', '\n'))
                        result[k][k2][k3] = lines[counter].replaceAll(' {n} ', '\n')
                    }else{
                        result[k][k2][k3] = {}
                        Object.keys(obj[k][k2][k3]).forEach( k4 => {
                            counter++
                            //console.log(k4,lines[counter].replaceAll(' {n} ', '\n'))
                            result[k][k2][k3][k4] = lines[counter].replaceAll(' {n} ', '\n')
                        })
                    }
                }, [])
            }
        }, [])
    }
}, [])

console.log(JSON.stringify(result, null, 2))*/