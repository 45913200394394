import React, {Component, ReactNode} from 'react';
import {View, Animated, ViewStyle, StyleProp, Easing} from 'react-native';

export interface ViewSlideProps {
  show: boolean,
  children?: ReactNode,
  styles?: StyleProp<ViewStyle>
}

export class ViewSlide extends Component<ViewSlideProps> {

  onMount: number

  state = {
    initialHeight: 0,
    show: true,
    fadeAnim: new Animated.Value(1),
  };

  onLayout( event: any ): void {
    var {x, y, width, height} = event.nativeEvent.layout;
    if( (!!this.state.initialHeight && Date.now() - this.onMount > 500) || height == 0 ) return;
    //if( !!this.state.initialHeight && height > this.state.initialHeight ) return;
    setTimeout(()=>{
      if( this.props.show == false ) this.setState({
        initialHeight: height,
        show: false,
        fadeAnim: new Animated.Value(0),
      })
      else this.setState({
        initialHeight: height,
      })
    },30)
  }

  async fadeIn() {
    this.setState({ show: true })
    await new Promise(res => setTimeout(res, 10))
    Animated.timing(this.state.fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
      easing: Easing.out(Easing.quad)
    }).start();
  };

  fadeOut = () => {
    console.log("fadeOut")
    Animated.timing(this.state.fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
      easing: Easing.out(Easing.quad)
    }).start(() => this.setState({ show: false }));
  };

  componentDidMount(): void {
    this.onMount = Date.now()
  }

  componentDidUpdate() {
    let isOpenNow = this.props.show
    if (this.state.show !== isOpenNow && !!this.state.initialHeight ) {
      console.log("isOpenNow",isOpenNow)
      if (isOpenNow) this.fadeIn();
      else this.fadeOut()
      this.setState({
        show: isOpenNow
      })
    }
  }

  render() {
    return (
      <Animated.View onLayout={(event) => this.onLayout(event) } style={[ this.props.styles, { position: !!this.state.initialHeight ? 'relative' : 'absolute', top:!!this.state.initialHeight ? 0 : -3000 ,overflow: 'hidden', height: !this.state.initialHeight ? 'auto' : this.state.fadeAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [0, this.state.initialHeight]  // 0 : 150, 0.5 : 75, 1 : 0
      }) }]}>
        <View style={{ width: '100%' }} >
        { this.props.children }
        </View>
      </Animated.View>
    );
  }
}
