import { APP, CURRENT_APP } from "../shared/constants"

type ConfigPropertyInterface = 'appDisplayName' | 'logo' | 'domain' | 'frontUrl' | 'codeLoginUrl' | 'apiBaseUrl' | 'socketioUrl' | 'productsIos' | 'productsAndroid' | 'stripePublishableKey' | 'gtmId'

class ConfigService {

  _config = {
    'development': {
      [APP.CHATWATCH]: {
        appDisplayName: 'Chatwatch',
        logo: require('../../assets/images/logo_chatwatch_square.png'),
        domain: 'chatwatch.net',
        frontUrl: 'https://v2.chatwatch.net',
        codeLoginUrl: 'scan.chatwatch.net',
        apiBaseUrl: 'http://localhost:3000/api/',
        socketioUrl: 'http://localhost:3000',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_test_51Lv5NkLtZZpDxM8oU6LiUZNtEW2ewDmU0Z1YbzidqYLDEEOORJiybwCuuS1EdZon1BK27gdNEeXxLwp861cbKxq200LyGC55Es",
      },
      [APP.WAWATCHER]: {
        appDisplayName: 'WA Watcher',
        logo: require('../../assets/images/logo-horizontal.png'),
        domain: 'watools.io',
        frontUrl: 'https://watcher.watools.io',
        codeLoginUrl: 'scan.watools.io',
        apiBaseUrl: 'http://localhost:3000/api/',
        socketioUrl: 'http://localhost:3000',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_test_51Lv5NkLtZZpDxM8oU6LiUZNtEW2ewDmU0Z1YbzidqYLDEEOORJiybwCuuS1EdZon1BK27gdNEeXxLwp861cbKxq200LyGC55Es",
        gtmId: ""
      },
      [APP.WAPATROL]: {
        appDisplayName: 'WA Patrol',
        logo: require('../../assets/images/logo_chatwatch_square.png'),
        domain: "wacheck.online",
        frontUrl: '',
        codeLoginUrl: 'scan.wacheck.online',
        apiBaseUrl: 'https://api.wacheck.online/api/',
        socketioUrl: 'https://api.wacheck.online',
        productsIos: ["com.arturojs.wacheckonline.subs.1w", "com.arturojs.wacheckonline.subs.1m", "com.arturojs.wacheckonline.subs.1y"],
        productsAndroid: ['online.checkwa.app.android.subs.1y','online.checkwa.app.android.subs.1m','online.checkwa.app.android.subs.1w',]
      }
    },
    'stage': {
      [APP.CHATWATCH]: {
        appDisplayName: 'Chatwatch',
        logo: require('../../assets/images/logo_chatwatch_square.png'),
        domain: 'stage.chatwatch.net',
        frontUrl: 'https://stage.chatwatch.net',
        codeLoginUrl: 'stage.chatwatch.net/qr-scan',
        apiBaseUrl: 'https://stageapi.chatwatch.net/api/',
        socketioUrl: 'https://stageapi.chatwatch.net',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_test_51Lv5NkLtZZpDxM8oU6LiUZNtEW2ewDmU0Z1YbzidqYLDEEOORJiybwCuuS1EdZon1BK27gdNEeXxLwp861cbKxq200LyGC55Es",
      },
      [APP.WAWATCHER]: {
        appDisplayName: 'WA Watcher',
        logo: require('../../assets/images/logo-horizontal.png'),
        domain: 'watools.io',
        frontUrl: 'https://watcher.watools.io',
        codeLoginUrl: 'stage.watools.io/qr-scan',
        apiBaseUrl: 'https://stageapi.watools.io/api/',
        socketioUrl: 'https://stageapi.watools.io',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_test_51Lv5NkLtZZpDxM8oU6LiUZNtEW2ewDmU0Z1YbzidqYLDEEOORJiybwCuuS1EdZon1BK27gdNEeXxLwp861cbKxq200LyGC55Es",
        gtmId: ""
      },
      [APP.WAPATROL]: {
        appDisplayName: 'WA Patrol',
        logo: require('../../assets/images/logo_chatwatch_square.png'),
        domain: "wacheck.online",
        frontUrl: '',
        codeLoginUrl: 'scan.wacheck.online',
        apiBaseUrl: 'https://api.wacheck.online/api/',
        socketioUrl: 'https://api.wacheck.online',
        productsIos: ["com.arturojs.wacheckonline.subs.1w", "com.arturojs.wacheckonline.subs.1m", "com.arturojs.wacheckonline.subs.1y"],
        productsAndroid: ['online.checkwa.app.android.subs.1y','online.checkwa.app.android.subs.1m','online.checkwa.app.android.subs.1w',]
      }
    },
    'production': {
      [APP.CHATWATCH]: {
        appDisplayName: 'Chatwatch',
        logo: require('../../assets/images/logo_chatwatch_square.png'),
        domain: 'chatwatch.net',
        frontUrl: 'https://v2.chatwatch.net',
        codeLoginUrl: 'scan.chatwatch.net',
        apiBaseUrl: 'https://apiv4.chatwatch.net/api/',
        socketioUrl: 'https://apiv4.chatwatch.net',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_live_51Lv5NkLtZZpDxM8oyrkMesHeGNhewfCBfEOtt9HcnIcGLMcM6qnsUACrSdR23JKAT7Czhjd9Ajagn62n1rBDcp7q005NISk68D"
      },
      [APP.WAWATCHER]: {
        appDisplayName: 'WA Watcher',
        logo: require('../../assets/images/logo-horizontal.png'),
        domain: 'watools.io',
        frontUrl: 'https://watcher.watools.io',
        codeLoginUrl: 'scan.watools.io',
        apiBaseUrl: 'https://api.watools.io/api/',
        socketioUrl: 'https://api.watools.io',
        productsIos: ['net.whatsapptools.watcher.subs.1m', 'net.whatsapptools.watcher.subs.1y'],
        productsAndroid: ["io.watools.watcher.android.subs.1m", "io.watools.watcher.android.subs.1y"],
        stripePublishableKey: "pk_live_51Lv5NkLtZZpDxM8oyrkMesHeGNhewfCBfEOtt9HcnIcGLMcM6qnsUACrSdR23JKAT7Czhjd9Ajagn62n1rBDcp7q005NISk68D",
        gtmId: "GTM-546KX7MB"
      },
      [APP.WAPATROL]: {
        appDisplayName: 'WA Patrol',
        logo: require('../../assets/images/double-check.png'),
        domain: "wacheck.online",
        frontUrl: '',
        codeLoginUrl: 'scan.wacheck.online',
        apiBaseUrl: 'https://api.wacheck.online/api/',
        socketioUrl: 'https://api.wacheck.online',
        productsIos: ["com.arturojs.wacheckonline.subs.1w", "com.arturojs.wacheckonline.subs.1m", "com.arturojs.wacheckonline.subs.1y"],
        productsAndroid: ['online.checkwa.app.android.subs.1y','online.checkwa.app.android.subs.1m','online.checkwa.app.android.subs.1w',]
      }
    }
  }

  // TODO
  get( property: ConfigPropertyInterface ){
    return this._config[ /*process.env.NODE_ENV*/ "production" ][ CURRENT_APP ][ property ]
  }

}//end service

export const configService = new ConfigService()