import React, { Component, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Animated, Easing, Image, StyleSheet, TouchableWithoutFeedback, View, Text, Pressable } from 'react-native';
import { RootState, store } from '../store';
import { setShowDrawer } from '../store/ui/ui.actions';
import { globalStyles } from '../shared/styles';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute, useNavigationContainerRef, StackActions } from '@react-navigation/native';
import { useAuthService } from '../services/auth-service';
import { useTranslation } from 'react-i18next';
import { isApp } from '../shared';
import { APP } from '../shared/constants';

export const DrawerComponent = () => {

  const navigation = useNavigation()
  const auth = useAuthService()
  const { t } = useTranslation()

  const [fadeAnim] = useState(new Animated.Value(0));
  const show = useSelector((state: RootState) => state.ui.showDrawer)
  const currentScreen = useSelector((state: RootState) => state.navigation.currentScreen)

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
      easing: Easing.out(Easing.quad)
    }).start();
  }, [fadeAnim])

  const fadeOut = useCallback(async () => {
    // Will change fadeAnim value to 0 in 3 seconds
    return new Promise(resolve => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
        easing: Easing.out(Easing.quad)
      }).start(resolve);
    })
  }, [fadeAnim])

  const closeDrawer = useCallback(async () => {
    await fadeOut()
    store.dispatch(setShowDrawer(false))
  }, [fadeOut])

  const isActive = useCallback((name: string) => {
    return currentScreen == name
  }, [currentScreen])

  const navigateTo = useCallback((screenName: string) => {
    console.log("screenName", screenName)
    if( screenName == currentScreen ){
      closeDrawer()
      return;
    }
    navigation.dispatch(
      StackActions.push(screenName)
    )
    closeDrawer()
  }, [navigation,closeDrawer,currentScreen])

  const logOut = useCallback(() => {
    closeDrawer()
    auth.logout()
  }, [auth])

  useEffect(() => {
    if (show) fadeIn();
  }, [show])

  const logo = useMemo(()=>{
    if( isApp(APP.CHATWATCH) ){
      return <Image source={require('../../assets/images/logo-horizontal-white.png')} resizeMode='contain' style={{ width: '80%', aspectRatio: 6, maxWidth: 160, marginTop: 30, marginBottom: 20 }} />
    }else if( isApp(APP.WAWATCHER) ){
      return <Image source={require('../../assets/images/logo-horizontal.png')} resizeMode='contain' style={{ width: '90%', aspectRatio: 6, maxWidth: 240, marginTop: 30, marginBottom: 20 }} />
    }
  },[])

  return (
    show ?
      <TouchableWithoutFeedback onPress={() => closeDrawer()}>
        <View style={{ position: 'absolute', width: '100%', height: '100%' }} >
          <Animated.View style={{ ...style.backdrop, opacity: fadeAnim }} />
          <Animated.View
            style={{
              ...globalStyles.drawer, transform: [{
                translateX: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -260]  // 0 : 150, 0.5 : 75, 1 : 0
                }),
              }]
            }}
            onStartShouldSetResponder={(event) => true}
            onTouchEnd={(e) => {
              e.stopPropagation();
            }}>
            <View style={[globalStyles.background, { position: "absolute", width: "100%", height: "100%", opacity: 0.3, zIndex: -1 }]} />
            { logo }
            { auth.isValidSubscription && auth.isWALoggedIn && <Pressable onPress={() => navigateTo('Main')}>
              <Text style={[globalStyles.drawerItemText, isActive('Main') && globalStyles.drawerItemActiveText]}>{ t('menu.home') }</Text>
            </Pressable> }
            { auth.isValidSubscription && <Pressable onPress={() => navigateTo('SubscriptionDetails')}>
              <Text style={[globalStyles.drawerItemText, isActive('SubscriptionDetails') && globalStyles.drawerItemActiveText]}>{ t('menu.subscription') }</Text>
            </Pressable> }
            <Pressable onPress={() => logOut()}>
              <Text style={[globalStyles.drawerItemText]}>{ t('menu.logOut') }</Text>
            </Pressable>
          </Animated.View>
        </View>
      </TouchableWithoutFeedback> : null
  )
}

const style = StyleSheet.create({
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});
