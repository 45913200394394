import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported, Messaging, } from "firebase/messaging";
import { http } from './http-service';
import { useEffect, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RootState, store } from '../store';

declare var Notification: any, navigator: any, alert: any

const firebaseConfig = {
  apiKey: "AIzaSyBjcUm-KOPGkvea0J0Zl7RRrmP20wdU0Q8",
  authDomain: "thermal-glazing-200200.firebaseapp.com",
  databaseURL: "https://thermal-glazing-200200.firebaseio.com",
  projectId: "thermal-glazing-200200",
  storageBucket: "thermal-glazing-200200.appspot.com",
  messagingSenderId: "189177626405",
  appId: "1:189177626405:web:cc10fd791b283eb9bce4cc",
  measurementId: "G-X2XEC5265Z",
  vapidKey: "BP-XX6q42bbi2X_I5VjN0dHnOcDi6IcikGx119RgnlQdgS83JqLa7XV1lyLnsVgk5h29ibsvKgbPoFLgYC4Rg9o"
}
const firebaseApp = initializeApp(firebaseConfig);
let messaging: Messaging

isSupported()
  .then(supported => messaging = supported ? getMessaging(firebaseApp) : null)
  .catch()

let notificationsNotAllowedTimeout = null

export const useFirebase = () => {

  const subscribed = useSelector((state: RootState) => state.agenda.subscribed)

  useEffect(() => {
    // TODO
    return;
    const someContactIsNotify = subscribed.some(c => !!c.notify)
    console.log("someContactIsNotify", someContactIsNotify)
    if (someContactIsNotify && !hasPermission()) {
      notificationsNotAllowedTimeout = setTimeout(async () => {
        let result = await askForFirebaseToken()
        if (!result && !hasPermission()) {
          if (!notificationsNotAllowedTimeout) {
            console.log("---> 1")
            alert(`Notifications not allowed\n\nYou denied push notifications permission.\nTo activate notifications now, you must authorize them from your browser settings.`);
          }
          return
        }
      }, 1000)
    }

    if (store.getState().auth.subscription.firebase == true) {
      updateToken({ showAlerts: false })
    }
  }, [])

  useEffect(() => {
    try {
      onMessage(messaging, (payload) => {
        //console.log(payload)
        // SHOW NOTIFICATIONS IN FOREGROUND
        try {
          navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
            registration.showNotification(
              payload.notification.title,
              payload.notification
            )
          });
        } catch (e) { }
      });
    } catch (e) { }
  }, [])

  const getPermission = async () => {
    return await Notification.requestPermission()
  }

  const hasPermission = () => {
    return "Notification" in window && Notification.permission === "granted"
  }

  const isInstalled = useMemo(() => {
    // For iOS
    if((window.navigator as any).standalone) return true
  
    // For Android
    if(window.matchMedia('(display-mode: standalone)').matches) return true
  
    // If neither is true, it's not installed
    return false
  },[])

  const requestPermission = async () => {
    try {
      return !!(await askForFirebaseToken())
    } catch (e) {
      return false
    }
  }

  const _isSupported = () => isSupported()

  const _getToken = async () => {
    let registration = navigator.serviceWorker.controller ? await navigator.serviceWorker.ready : null
    return getToken(messaging, {
      serviceWorkerRegistration: registration,
      vapidKey: firebaseConfig.vapidKey
    })
  }

  const askForFirebaseToken = async () => {
    if (!await isSupported()) {
      console.log("FIREBASE MESSAGING NOT SUPPORTED");
      alert(`Push notifications not sopported on this browser`);
      return null;
    };
    const permission = await getPermission()
    if (permission == "granted") {
      await updateToken({ showAlerts: true })
    } else if (permission == "denied") {
      console.log("---> 2")
      alert(`Notifications not allowed\n\nYou denied push notifications permission.\nTo activate notifications now, you must authorize them from your browser settings.`);
      return null;
    };
  };

  const updateToken = async ({ showAlerts = false }: { showAlerts: boolean }) => {
    let currentToken: string
    let registration = await navigator.serviceWorker.ready;
    try {
      currentToken = await getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: firebaseConfig.vapidKey
      })
    } catch (error) {
      showAlerts && alert(`Error enabling push notifications`);
      console.log("error asking", error);
      return null;
    }
    try {
      await sendFirebaseToken(currentToken)
    } catch (error) {
      showAlerts && alert(`Error enabling push notifications,\nDo you have internet connection?`);
      console.log("error asking", error);
      return null;
    }

    if (currentToken) {
      return currentToken;
    } else {
      showAlerts && alert(`Error enabling push notifications`);
      console.log("error asking, no currentToken");
      return null;
    }
  }

  const sendFirebaseToken = async (token: any = null) => {
    /*if (token) this.persistentStorage.set("PENDING_FIREBASE_TOKEN", token);
    const pendingToken = this.persistentStorage.get("PENDING_FIREBASE_TOKEN");
    if (pendingToken) {*/
    console.log("sendFirebaseToken",token)
    try {
      await http.post("user/registerWebPushToken", { token })
    } catch (e) {
      console.log(e)
    }
    /*if (!error) this.persistentStorage.delete("PENDING_FIREBASE_TOKEN");
  });
  //};*/
  };

  return {
    isSupported: _isSupported,
    getToken: _getToken,
    firebaseApp,
    messaging,
    hasPermission,
    requestPermission,
    sendFirebaseToken,
    isInstalled
  }
}


