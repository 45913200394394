var LZString = function () {
	function o(o:any, r:any) {
		if (!t[o]) {
			t[o] = {};
			for (var n = 0; n < o.length; n++) t[o][o.charAt(n)] = n
		}
		return t[o][r]
	}
	var r:any = String.fromCharCode,
		n:any = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
		e:any = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+-$",
		t:any = {},
		i:any = {
			compressToBase64: function (o:any) {
				if (null == o) return "";
				var r = i._compress(o, 6, function (o:any) {
					return n.charAt(o)
				});
				switch (r.length % 4) {
					default:
						case 0:
						return r;
					case 1:
							return r + "===";
					case 2:
							return r + "==";
					case 3:
							return r + "="
				}
			},
			decompressFromBase64: function (r:any) {
				return null == r ? "" : "" == r ? null : i._decompress(r.length, 32, function (e:any) {
					return o(n, r.charAt(e))
				})
			},
			compressToUTF16: function (o:any) {
				return null == o ? "" : i._compress(o, 15, function (o:any) {
					return r(o + 32)
				}) + " "
			},
			decompressFromUTF16: function (o:any) {
				return null == o ? "" : "" == o ? null : i._decompress(o.length, 16384, function (r:any) {
					return o.charCodeAt(r) - 32
				})
			},
			compressToUint8Array: function (o:any) {
				for (var r:any = i.compress(o), n = new Uint8Array(2 * r.length), e = 0, t = r.length; t > e; e++) {
					var s:any = r.charCodeAt(e);
					n[2 * e] = s >>> 8, n[2 * e + 1] = s % 256
				}
				return n
			},
			decompressFromUint8Array: function (o:any) {
				if (null === o || void 0 === o) return i.decompress(o);
				for (var n:any = new Array(o.length / 2), e = 0, t = n.length; t > e; e++) n[e] = 256 * o[2 * e] + o[2 * e + 1];
				var s:any = [];
				return n.forEach(function (o:any) {
					s.push(r(o))
				}), i.decompress(s.join(""))
			},
			compressToEncodedURIComponent: function (o:any) {
				return null == o ? "" : i._compress(o, 6, function (o:any) {
					return e.charAt(o)
				})
			},
			decompressFromEncodedURIComponent: function (r:any) {
				return null == r ? "" : "" == r ? null : (r = r.replace(/ /g, "+"), i._decompress(r.length, 32, function (n:any) {
					return o(e, r.charAt(n))
				}))
			},
			compress: function (o:any) {
				return i._compress(o, 16, function (o:any) {
					return r(o)
				})
			},
			_compress: function (o:any, r:any, n:any) {
				if (null == o) return "";
				var e:any, t:any, i:any, s:any = {},
					p:any = {},
					u:any = "",
					c:any = "",
					a:any = "",
					l:any = 2,
					f:any = 3,
					h:any = 2,
					d:any = [],
					m:any = 0,
					v:any = 0;
				for (i = 0; i < o.length; i += 1)
					if (u = o.charAt(i), Object.prototype.hasOwnProperty.call(s, u) || (s[u] = f++, p[u] = !0), c = a + u, Object.prototype.hasOwnProperty.call(s, c)) a = c;
					else {
						if (Object.prototype.hasOwnProperty.call(p, a)) {
							if (a.charCodeAt(0) < 256) {
								for (e = 0; h > e; e++) m <<= 1, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++;
								for (t = a.charCodeAt(0), e = 0; 8 > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1
							} else {
								for (t = 1, e = 0; h > e; e++) m = m << 1 | t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t = 0;
								for (t = a.charCodeAt(0), e = 0; 16 > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1
							}
							l--, 0 == l && (l = Math.pow(2, h), h++), delete p[a]
						} else
							for (t = s[a], e = 0; h > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1;
						l--, 0 == l && (l = Math.pow(2, h), h++), s[c] = f++, a = String(u)
					}
				if ("" !== a) {
					if (Object.prototype.hasOwnProperty.call(p, a)) {
						if (a.charCodeAt(0) < 256) {
							for (e = 0; h > e; e++) m <<= 1, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++;
							for (t = a.charCodeAt(0), e = 0; 8 > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1
						} else {
							for (t = 1, e = 0; h > e; e++) m = m << 1 | t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t = 0;
							for (t = a.charCodeAt(0), e = 0; 16 > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1
						}
						l--, 0 == l && (l = Math.pow(2, h), h++), delete p[a]
					} else
						for (t = s[a], e = 0; h > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1;
					l--, 0 == l && (l = Math.pow(2, h), h++)
				}
				for (t = 2, e = 0; h > e; e++) m = m << 1 | 1 & t, v == r - 1 ? (v = 0, d.push(n(m)), m = 0) : v++, t >>= 1;
				for (;;) {
					if (m <<= 1, v == r - 1) {
						d.push(n(m));
						break
					}
					v++
				}
				return d.join("")
			},
			decompress: function (o:any) {
				return null == o ? "" : "" == o ? null : i._decompress(o.length, 32768, function (r:any) {
					return o.charCodeAt(r)
				})
			},
			_decompress: function (o:any, n:any, e:any) {
				var t:any, i:any, s:any, p:any, u:any, c:any, a:any, l:any, f:any = [],
					h = 4,
					d = 4,
					m = 3,
					v = "",
					w = [],
					A = {
						val: e(0),
						position: n,
						index: 1
					};
				for (i = 0; 3 > i; i += 1) f[i] = i;
				for (p = 0, c = Math.pow(2, 2), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
				switch (t = p) {
					case 0:
						for (p = 0, c = Math.pow(2, 8), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
						l = r(p);
						break;
					case 1:
						for (p = 0, c = Math.pow(2, 16), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
						l = r(p);
						break;
					case 2:
						return ""
				}
				for (f[3] = l, s = l, w.push(l);;) {
					if (A.index > o) return "";
					for (p = 0, c = Math.pow(2, m), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
					switch (l = p) {
						case 0:
							for (p = 0, c = Math.pow(2, 8), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
							f[d++] = r(p), l = d - 1, h--;
							break;
						case 1:
							for (p = 0, c = Math.pow(2, 16), a = 1; a != c;) u = A.val & A.position, A.position >>= 1, 0 == A.position && (A.position = n, A.val = e(A.index++)), p |= (u > 0 ? 1 : 0) * a, a <<= 1;
							f[d++] = r(p), l = d - 1, h--;
							break;
						case 2:
							return w.join("")
					}
					if (0 == h && (h = Math.pow(2, m), m++), f[l]) v = f[l];
					else {
						if (l !== d) return null;
						v = s + s.charAt(0)
					}
					w.push(v), f[d++] = s + v.charAt(0), h--, s = v, 0 == h && (h = Math.pow(2, m), m++)
				}
			}
		};
	return i
}();

export { LZString };