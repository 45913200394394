import { useRoute, useNavigation, StackActions, useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { DatePickerModal } from 'react-native-paper-dates';
import { ContactAvatarWithStatus } from '../components/contact-avatar-with-status';
import { HeaderComponent } from '../components/header';
import { RootStackParamList } from '../navigation/root-stack-param-list';
import { http } from '../services/http-service';
import { extractPayload, parseChats } from '../shared/ChatDataParser';
import { formatDate, getTodayDateString, isApp } from '../shared/helpers';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { connect, useSelector, useStore } from 'react-redux';
import { useAuthService } from '../services/auth-service';
import { useRouter } from '../services/router-service';
import { useTranslation } from 'react-i18next';
import { APP } from '../shared/constants';
import moment from 'moment';

const isRowEmpty = (row: any) => {
    if (!row.contact1_online || !row.contact2_online) return true;
    return row.contact1_online?.every(i => i == 'offline') && row.contact2_online?.every(i => i == 'offline')
}

window.moment = moment

export const ChatProbabilityScreen = () => {

    const { t } = useTranslation()
    const navigation = useNavigation()
    const route = useRoute()
    const {
        contact: contactPhone,
        compareWithContact: compareWithContactPhone
    } = route.params as RootStackParamList['ChatProbability'];
    const contact = useSelector((state: RootState) => state.agenda.subscribed.find(c => c.phone == contactPhone))
    const compareWithContact = useSelector((state: RootState) => state.agenda.subscribed.find(c => c.phone == compareWithContactPhone))

    const isLoading = useSelector((state: RootState) => state.ui.isLoading)

    const [date, setDate] = useState<Date>(new Date())
    const [error, setError] = useState<string>(null)
    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
    const [history, setHistory] = useState<any[]>([])

    const router = useRouter()
    const authService = useAuthService()

    useFocusEffect(useCallback(() => {
        router.functionalGuard({ from: "ChatProbability" })
    }, [authService.isWALoggedIn, authService.isLoggedIn, authService.isValidSubscription])
    )

    const getChatProbability = useCallback(() => {
        store.dispatch(setIsLoading(true))

        let newDate = date
        if (formatDate(newDate) == getTodayDateString()) {
            console.log("is today")
            newDate = new Date()
        } else {
            console.log("is NOT today")
            newDate = new Date(newDate.getTime() + (newDate.getTimezoneOffset() * 60000))
        }

        var data = {
            contacts: [contact, compareWithContact],
            date: newDate.toISOString(),
            type: "history"
        };

        console.log("date.toISOString()", date.toISOString())
        console.log(moment(date.toISOString()).toDate().toISOString())
        console.log("newDate", moment().toString())

        http
            .post("online-period/online-history", data)
            .then((result: any) => {
                const extracted = extractPayload(result.data)
                console.log("extracted", extracted)

                parseChats(extracted, date, contact).then((historyRecords: any) => {
                    //console.log(JSON.stringify(historyRecords, null, 2))
                    historyRecords.historyRecords.forEach(row => {
                        row?.contact1_online && (row.contact1_online = row.contact1_online.reverse());
                        row?.contact2_online && (row.contact2_online = row.contact2_online.reverse());
                    })
                    //console.log("historyRecords.historyRecords",historyRecords.historyRecords)
                    setHistory(historyRecords.historyRecords)
                });
            })
            .catch((e: any) => {
                // ERROR
                console.log(e);
                setError(e?.error?.message || "Are you sure do you have internet?")
            })
            .finally(() => {
                store.dispatch(setIsLoading(false))
            })
    }, [date, contact, compareWithContact])

    const isEmpty = useCallback(() => {
        return !!history.length && history.length <= 2 && history.every(r => isRowEmpty(r.contact1_online))
    }, [history])

    const getDateString = useCallback(() => {
        var isToday = formatDate(date) == getTodayDateString()
        if (isToday) return t('chatProbability.last24Hours');
        return formatDate(date)
    }, [date])

    useEffect(() => {
        getChatProbability()
    }, [date])

    return (!contact || !compareWithContact) ? null : (
        <View style={globalStyles.screenBase} >
            <HeaderComponent title={t('chatProbability.chatProbability')} style={{ textAlign: compareWithContact ? "center" : "left" }} />

            <View style={styles.main}>
                <View style={[{ backgroundColor: "white", borderRadius: isApp(APP.CHATWATCH) ? 10 : 0, padding: 0, overflow: "hidden", maxHeight: "100%" }]}>
                    <View style={[{ backgroundColor: globalStyles.mainCardBackground.backgroundColor, alignItems: 'center', paddingVertical: 10, width: '100%' }]}>
                        <Pressable onPress={() => setDatePickerOpen(true)} >
                            <Text style={[styles.subheaderText, globalStyles.fontFamily]}>{getDateString()}</Text>
                        </Pressable>
                        <DatePickerModal
                            allowEditing={false}
                            locale="en"
                            mode="single"
                            label='Select a date'
                            visible={datePickerOpen}
                            saveLabel=' '
                            saveLabelDisabled={true}
                            onChange={(params) => {
                                setDatePickerOpen(false)
                                let newDate = params.date
                                if (formatDate(newDate) == getTodayDateString()) {
                                    newDate = new Date()
                                }
                                setDate(newDate)
                            }}
                            editIcon={"fsdfds"}
                            validRange={{
                                endDate: new Date()
                            }}
                            presentationStyle='pageSheet'
                            onDismiss={() => {
                                setDatePickerOpen(false)
                            }}
                            date={date}
                            onConfirm={(params) => {
                                setDatePickerOpen(false)
                                setDate(params.date)
                            }}
                        />
                    </View>

                    <View style={{ alignItems: 'center', paddingVertical: 10, width: '100%' }}>
                        <View style={{ flexDirection: 'row', marginBottom: 8, alignItems: 'center', width: "100%" }}>
                            <View style={{ flex: 1, marginLeft: 90, alignItems: 'center' }}>
                                <ContactAvatarWithStatus contact={contact} width={60} />
                            </View>
                            <View style={{ flex: 1, alignItems: 'center', marginRight: 90 }}>
                                <ContactAvatarWithStatus contact={compareWithContact} width={60} />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginBottom: 8, alignItems: 'center', width: "100%" }}>
                            <Text style={[styles.subheaderText, { width: 90, paddingHorizontal: 2, color: "#1F024A" }]}>{t('chatProbability.time')}</Text>
                            <Text style={[styles.subheaderText, { flex: 1, color: "#1F024A" }]}>{contact?.name}</Text>
                            <Text style={[styles.subheaderText, { flex: 1, color: "#1F024A" }]}>{compareWithContact?.name}</Text>
                            <Text style={[styles.subheaderText, { width: 90, paddingHorizontal: 2, color: "#1F024A" }]}>{t('chatProbability.score')}</Text>
                        </View>
                    </View>

                    {isLoading && <ActivityIndicator color={globalStyles.mainCardBackground.backgroundColor} size={30} style={{ marginBottom: 20 }} />}

                    <ScrollView style={{ width: "100%" }} contentContainerStyle={[styles.main, { justifyContent: "flex-start", paddingVertical: 0, alignItems: 'stretch', paddingHorizontal: 0 }]}>

                        {!isEmpty() && <View style={{ flexDirection: 'column', alignItems: 'stretch', width: "100%" }}>
                            {history.map((hr, i) => {
                                let _isRowEmpty: boolean = hr.offlinePeriod ? null : isRowEmpty(hr)
                                return (
                                    (!hr.offlinePeriod ?
                                        <View style={{ flexDirection: 'row', marginBottom: 6, alignItems: 'stretch', width: "100%" }} key={'row-online-' + i}>
                                            <Text style={[styles.timeSpan, { opacity: !hr.hourRange.to && !_isRowEmpty ? 1 : 0 }]}>{hr.hourRange.from}</Text>
                                            <View style={{ flex: 1, alignItems: 'center', paddingHorizontal: 4 }}>
                                                {(hr.contact1_online as any[]).map((cssClass, i2) =>
                                                    <View style={_isRowEmpty ? globalStyles.dot : globalStyles[cssClass + 'Row']} key={'row-1-online-' + i + '-' + i2} />
                                                )}
                                            </View>
                                            <View style={{ flex: 1, alignItems: 'center', paddingHorizontal: 4 }}>
                                                {(hr.contact2_online as any[]).map((cssClass, i2) =>
                                                    <View style={_isRowEmpty ? globalStyles.dot : globalStyles[cssClass + 'Row']} key={'row-2-online-' + i + '-' + i2} />
                                                )}
                                            </View>
                                            <View style={{ width: 90, marginLeft: 6, height: '100%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                <View style={[styles['paddingscore' + hr.score?.pos], { flexDirection: 'row', display: !!hr.score.stars ? 'flex' : 'none' }]}>
                                                    <View style={[globalStyles.scoreDot, hr.score.stars > 4 ? globalStyles.scoreDotActive : null]} />
                                                    <View style={[globalStyles.scoreDot, hr.score.stars > 3 ? globalStyles.scoreDotActive : null]} />
                                                    <View style={[globalStyles.scoreDot, hr.score.stars > 2 ? globalStyles.scoreDotActive : null]} />
                                                    <View style={[globalStyles.scoreDot, hr.score.stars > 1 ? globalStyles.scoreDotActive : null]} />
                                                    <View style={[globalStyles.scoreDot, hr.score.stars > 0 ? globalStyles.scoreDotActive : null]} />
                                                </View>
                                            </View>
                                        </View>
                                        :
                                        <View style={{ width: '100%', alignItems: 'center', marginVertical: 40, marginBottom: 80 }} key={'row-offline-' + i}>
                                            <Text style={styles.offlinePeriodTextPrimary}>{t('chatProbability.offlineFor')} {hr.offlinePeriod.hours} {t('chatProbability.hour', { count: hr.offlinePeriod.hours })}</Text>
                                            <Text style={styles.offlinePeriodTextSecondary}>{t('chatProbability.from')} {hr.offlinePeriod.start}{"\n"}{t('chatProbability.to')} {hr.offlinePeriod.end}</Text>
                                        </View>
                                    )
                                )
                            })}
                        </View>}

                        <View style={{ padding: 20, display: isEmpty() ? 'flex' : 'none' }}>
                            <Text style={[globalStyles.title, { color: "rgb(31, 2, 74)", textAlign: "center" }]}>
                                {t('chatProbability.notEnoughtData')}
                            </Text>
                        </View>

                    </ScrollView>

                </View>
            </View>

            <View style={{ paddingHorizontal: 20, paddingBottom: 17, width: "100%", maxWidth: 600 }}>
                {compareWithContact && <Pressable style={[globalStyles.primaryButton, { marginBottom: 15 }]} onPress={() => setDatePickerOpen(true)}>
                    <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('chatProbability.changeDate')}</Text>
                </Pressable>}
                <Pressable style={[globalStyles.secondaryButton]} onPress={() => { navigation.dispatch(StackActions.pop(2)) }}>
                    <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('goBack')}</Text>
                </Pressable>
            </View>

        </View>
    );

}

const styles = StyleSheet.create({
    subheaderText: {
        ...globalStyles.fontFamily,
        fontSize: 12,
        fontWeight: "700",
        textAlign: 'center',
        color: "white"
    },
    containerCenter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    main: {
        flex: 1,
        flexGrow: 1,
        width: "100%",
        alignItems: 'stretch',
        padding: isApp(APP.CHATWATCH) ? 20 : 0,
        paddingTop: isApp(APP.CHATWATCH) ? 10 : 0,
        maxWidth: 600,
        alignSelf: "center"
    },
    contactCard: {
        flexDirection: 'row',
        backgroundColor: '#f5f5f5',
        width: '100%',
        height: 'auto',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 12,
        alignItems: 'center',
        marginVertical: 10,
    },
    contactInfoSection: {
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
        marginLeft: 14,
        paddingVertical: 0,
        justifyContent: 'flex-start'
    },
    contactInfoName: {
        ...globalStyles.fontFamily,
        fontSize: 18,
        fontWeight: 'bold',
    },
    contactInfoNumber: {
        ...globalStyles.fontFamily,
        fontSize: 14,
        opacity: 0.8
    },
    timeSpan: {
        ...globalStyles.fontFamily,
        textAlign: "center",
        fontSize: 13,
        width: 90
    },
    offlinePeriodTextPrimary: {
        ...globalStyles.fontFamily,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8
    },
    offlinePeriodTextSecondary: {
        ...globalStyles.fontFamily,
        textAlign: 'center',
        fontSize: 16,
    },
    paddingscore0: { marginTop: 90 },
    paddingscore1: { marginTop: 72 },
    paddingscore2: { marginTop: 54 },
    paddingscore3: { marginTop: 36 },
    paddingscore4: { marginTop: 18 },
    paddingscore5: { marginTop: 0 },
});