import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { LogoAndSlogan } from '../components/auth/logo-and-slogan';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { validateEmail } from "../shared";

export const RecoverPasswordScreen = () => {

  const navigation = useNavigation()
  const { t } = useTranslation()

  const authService = useAuthService()
  const router = useRouter()
  const isLoading = useSelector((state: RootState) => state.ui.isLoading)

  const [email, setEmail] = useState<string>("")
  const [error, setError] = useState<string>(null)
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const recoverPassword = useCallback(async () => {
    if (!validateEmail(email)) {
      return setError(t('recoverPassword.errorValidEmail'));
    }
    store.dispatch(setIsLoading(true))
    http.post("auth/forgotPassword", { email })
      .then(response => {
        console.log(response)
        setEmailSent(true)
      })
      .catch(e => {
        setError(e)
      })
      .finally(() => store.dispatch(setIsLoading(false)))
  }, [email, setError, setEmailSent])

  useFocusEffect(useCallback(() => {
    router.notFunctionalGuard({ from: "RecoverPassword" })
  }, [authService.isWALoggedIn])
  )

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, maxWidth: 600, alignSelf: "center" }} >
      <LogoAndSlogan />

      <Text style={[globalStyles.baseText, globalStyles.fontFamilyBold, { marginTop: 40, textAlign: 'center', fontSize: 18, textTransform: 'uppercase' }]}>{t('recoverPassword.recoverPassword')}</Text>

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'email-address'}
        textContentType={'emailAddress'}
        autoCapitalize={'none'}
        autoComplete={'email'}
        autoCorrect={false}
        placeholder={t('recoverPassword.email')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setEmail(text)}
        value={email}
        editable={!emailSent}
      />

      {!!error && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[globalStyles.fontFamily, { color: 'rgba(255,53,53,0.8)', fontSize: 18, textAlign: "center" }]}>{error}</Text>
      </View>}

      {!!emailSent && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[globalStyles.fontFamily, { color: 'rgba(53, 255, 63, 0.8)', fontSize: 18, textAlign: "center" }]}>{t('recoverPassword.emailSent')}</Text>
      </View>}

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20, opacity: emailSent || isLoading ? 0.5 : 1 }]} disabled={emailSent || isLoading} onPress={recoverPassword}>
        <Text style={globalStyles.primaryButtonText}>{t('recoverPassword.recoverPassword')}</Text>
      </TouchableOpacity>
      <Pressable style={[globalStyles.secondaryButton]} onPress={() => { navigation.goBack() }}>
        <Text style={globalStyles.secondaryButtonText}>{t('goBack')}</Text>
      </Pressable>
    </View>
  </View>

}