import { ActivityIndicator, Modal, StyleSheet, View, Text, Image, Pressable, Linking, TouchableOpacity } from 'react-native';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { RootState, store } from '../../store';
import { globalStyles } from '../../shared/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { http } from '../../services/http-service';
import { setSubscription } from '../../store/auth/auth.actions';
import { setTelegramBotLink } from '../../store/ui/ui.actions';
import { numbersService } from '../../services/numbers.service';

export const TelegramModal = () => {

    const dispatch = useDispatch()

    const telegramBotLink = useSelector((state: RootState) => state.ui.telegramBotLink)
    const authState = useSelector((state: RootState) => state.auth)

    const isTelegramEnabled = useMemo(() => !!authState.subscription?.telegram, [authState])
    const showTelegramModal = useMemo(()=>!!telegramBotLink,[telegramBotLink])
    const telegramBotLinkLoading = useMemo(()=>telegramBotLink == "loading",[telegramBotLink])

    const [verifyError, setVerifyError] = useState<string>(null)
    const [verifiying, setVerifiying] = useState<boolean>(false)

    const verifyTelegramBot = useCallback(() => {

        if (verifiying) return;
    
        setVerifiying(true)
        setVerifyError( null )
    
        http.get("user/get")
          .then((result: any) => {
            setVerifiying(false)
            //result.subscription.telegram = true
            dispatch( setSubscription( result.subscription ) )
          })
          .catch((e) => { // ERROR
            console.error(e)
            setVerifiying(false)
            setVerifyError( e.error.message || "Unknown error, please try again." )
          });
    
      },[verifiying,setVerifiying,setVerifyError])

    useEffect(()=>{
        let link = store.getState().ui.telegramBotLink
        let isReady = !!link && link != 'loading'
        if( !isReady ) return;
        if( isTelegramEnabled ){
            dispatch( setTelegramBotLink( null ) )
        }else{
            setVerifyError( "Telegram Notifications Chatbot is not enabled" )
        }
    },[authState])

    useEffect(()=>{
        //numbersService.getContacts()
        setVerifyError( null )
    },[showTelegramModal])
    

    return <Modal
        animationType="fade"
        visible={showTelegramModal}
        transparent={true}>
        <View style={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.2)" }}>
            <View style={styles.main}>
                <View style={styles.inner}>
                    {telegramBotLinkLoading ? (
                        <ActivityIndicator color={globalStyles.mainCardBackground.backgroundColor} size={30} />
                    ) : (
                        <View >
                            <Text style={{ ...globalStyles.fontFamily, textAlign: "center", fontSize: 18, fontWeight: "700", lineHeight: 24 }}>Steps to activate{'\n'}Push Notifications</Text>
                            <Text style={{ ...globalStyles.fontFamily, textAlign: "center", marginTop: 12 }}>We will send you notifications through our <Text style={{ fontWeight: "700" }}>Telegram
                                chatbot</Text>.{'\n'}Just
                                follow these instructions:
                            </Text>
                            <View style={{ marginTop: 4, paddingLeft: 6 }}>
                                <Text style={{ ...globalStyles.fontFamily, marginBottom: 8 }}><Text style={{ fontWeight: "700" }}>1. </Text>Install the <Image source={require('../../../assets/images/telegram-icon.png')} style={{ width: 26, height: 26, marginLeft: 4 }} /> Telegram App</Text>
                                <Text style={{ ...globalStyles.fontFamily, marginBottom: 8 }}><Text style={{ fontWeight: "700" }}>2. </Text>Start a conversation with our chatbot following <Pressable onPress={() => Linking.openURL(telegramBotLink)}><Text style={{ color: "#0d6efd", fontWeight: "600" }}>this
                                    link</Text></Pressable> and tapping <Text style={{ fontStyle: "italic" }}>Start</Text></Text>
                                <Text style={{ ...globalStyles.fontFamily, marginBottom: 8 }}><Text style={{ fontWeight: "700" }}>3. </Text>Click below to check if the bot is enabled:</Text>
                            </View>

                            {verifyError && <View style={styles.alertDanger}>
                                <Text style={[globalStyles.fontFamily, styles.alertDangerText]}>{verifyError}</Text>
                            </View>}

                            <TouchableOpacity onPress={ verifyTelegramBot } >
                                <View style={ styles.verifyButton }>
                                <Image source={require('../../../assets/images/telegram-icon-white.png')} style={{ width: 30, height: 30, marginRight: 4 }} />
                                <Text style={ styles.verifyButtonText }>Verify Telegram</Text>
                                {  }
                                </View>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </View>
        </View>
    </Modal>
}

const styles = StyleSheet.create({
    main: {
        alignItems: 'center',
        padding: 24,
        maxWidth: 600,
        alignSelf: 'center',
        width: "100%"
    },
    inner: {
        alignItems: 'center',
        padding: 24,
        alignSelf: 'stretch',
        backgroundColor: "white",
        borderRadius: 12
    },
    alertDanger: {
        backgroundColor: "#f8d7da",
        borderRadius: 8,
        padding: 16
    },
    alertDangerText: {
        color: "#721c24"
    },
    verifyButton: {
        backgroundImage: "linear-gradient(#37aee2,#1e96c8)",
        paddingVertical: 6,
        paddingHorizontal: 20,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        marginTop: 10
    },
    verifyButtonText: {
        ...globalStyles.fontFamily,
        color: "white",
        fontWeight: "700"
    }
})