import React, { memo, useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Contact } from '../shared/interfaces';
import { RootState } from '../store';
import { ImageAspectFit } from './image';

export interface ContactAvatarWithStatusProps {
  contact: Contact
  width?: number
}

function propsAreEqual(prev: ContactAvatarWithStatusProps, next: ContactAvatarWithStatusProps) {
  return prev.contact?.id == next.contact?.id
}

export const ContactAvatarWithStatus = memo(({ contact, width }: ContactAvatarWithStatusProps) => {

  const _contact = useSelector( (state: RootState) => state.agenda.subscribed.find( c => contact.id == c.id ) )

  const getProperties = useCallback(()=>{
    let isOnline = null, color = null, text = '';
    if( _contact?.status == 'NUMBER_OK' ){
      if( _contact.isOnline ){
        isOnline = true;
        color = '#0FDE23'
        text = 'ONLINE';
      }else{
        isOnline = false;
        color = 'transparent'
        text = 'OFFLINE';
      }
    }else if (_contact?.status == 'PENDING') {
      isOnline = false;
      color = 'transparent'
      text = '?';
    }
    else if (_contact?.status == 'WARNING') {
      isOnline = false;
      color = 'transparent'
      text = 'WARNING';
    }
    else if (_contact?.status == 'BAD_NUMBER') {
      isOnline = false;
      color = 'red'
      text = 'WARNING';
    }
    return { isOnline, color, text }
  },[_contact])

    return _contact ? (
      <View>
      <View style={{ ...styles.circle, borderColor: getProperties().color, width: width || 66, height: width || 66 }}>
        { !!_contact.profilePic ? 
        <Image source={{ uri: _contact.profilePic }} style={{ width: width || 66, height: width || 66 }} />
        :
        <ImageAspectFit asset={getProperties().isOnline ? require('../../assets/images/tracking-contact.png') : require('../../assets/images/not-tracking.png') } style={{ width: 66 }} />
        }
      </View>
        {getProperties().isOnline && <View style={styles.statusFlag} />}
      </View>
    ) : null
}, propsAreEqual)

const styles = StyleSheet.create({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "50%",
    borderWidth: 2,
    overflow: 'hidden'
  },
  statusTag: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 4,
    width: 70
  },
  statusText: {
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    fontSize: 11,
    lineHeight: 15,
    color: 'white'
  },
  statusFlag: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#0FDE23",
    borderWidth: 4,
    borderColor: "white"
  },
});
