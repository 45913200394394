const moment = require("moment");
require('twix');
const _ = require("underscore");
import { LZString } from './LZString'

declare var window

var insights: any = [];
var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var c = {
    addictionLevel: [{
        count: 0,
        label: "SOCIAL"
    }, {
        count: 60,
        label: "RISKY"
    }, {
        count: 90,
        label: "STRONG"
    }, {
        count: 120,
        label: "HELP!"
    }],
    obsessionLevel: [{
        count: 0,
        label: "MILD"
    }, {
        count: 100,
        label: "CRAVING"
    }, {
        count: 120,
        label: "HOOKED"
    }, {
        count: 160,
        label: "TORMENTED"
    }, {
        count: 180,
        label: "POSSESSED"
    }],
    labels: {
        onlineHistory: "PLEASE SELECT ONE CONTACT TO VIEW ONLINE HISTORY",
        chatProbability: "SELECT TWO CONTACTS TO VIEW THEIR CHAT PROBABILITY",
        insights: "PLEASE SELECT ONE CONTACT TO VIEW INSIGHTS",
        login: "CHATW CAN VIEW YOUR CONTACTS FOR YOU.\nCHOOSE YOUR SUBSCRIPTION TIER LEVEL."
    }
};;



class Insight {
    name: any
    hoursSleep: any
    minutesOnline: any
    connections: any
    iconSrc: any
    addictionLevel: any
    obsessionLevel: any
    sleep: any
    wakeUp: any
    description: any
    nightOwl: any
    constructor(name: any, sleepDate: any, wakeUpDate: any, whenh: any, hoursSleep: any, minutesOnline: any, connections: any, nightOwl: any) {
        this.name = name.toUpperCase();
        this.hoursSleep = hoursSleep;
        this.minutesOnline = minutesOnline;
        this.connections = connections;
        this.iconSrc = "assets/images/insights/emojis/-1.png";
        this.addictionLevel = c.addictionLevel[0].label;
        if (minutesOnline >= c.addictionLevel[1].count)
            this.addictionLevel = c.addictionLevel[1].label;
        if (minutesOnline >= c.addictionLevel[2].count)
            this.addictionLevel = c.addictionLevel[2].label;
        if (minutesOnline >= c.addictionLevel[3].count)
            this.addictionLevel = c.addictionLevel[3].label;
        this.obsessionLevel = c.obsessionLevel[0].label;
        if (connections >= c.obsessionLevel[1].count)
            this.obsessionLevel = c.obsessionLevel[1].label;
        if (connections >= c.obsessionLevel[2].count)
            this.obsessionLevel = c.obsessionLevel[2].label;
        if (connections >= c.obsessionLevel[3].count)
            this.obsessionLevel = c.obsessionLevel[3].label;
        if (connections >= c.obsessionLevel[4].count)
            this.obsessionLevel = c.obsessionLevel[4].label;
        this.sleep = {
            date: sleepDate,
            rhour: getReadableHour(sleepDate),
            rdate: getReadableDate(sleepDate)
        };
        this.wakeUp = {
            date: wakeUpDate,
            rhour: getReadableHour(wakeUpDate),
            rdate: getReadableDate(wakeUpDate)
        };
        this.nightOwl = nightOwl
        var category = "normal";
        if (whenh >= 19 && whenh <= 22)
            category = "normal";
        else if (whenh == 23 || whenh >= 0 && whenh <= 1)
            category = "late";
        else if (whenh >= 2 && whenh <= 6)
            category = "party";
        var description = "OH OH!";
        if (category == "normal") {
            if (hoursSleep >= 11) {
                this.iconSrc = "assets/images/insights/emojis/6.png";
                description = "SO LAZY..."
            }
            if (hoursSleep >= 8 && hoursSleep <= 10) {
                this.iconSrc = "assets/images/insights/emojis/10.png";
                description = "GREAT NIGHT!"
            }
            if (hoursSleep >= 4 && hoursSleep <= 7) {
                this.iconSrc = "assets/images/insights/emojis/9.png";
                description = "EARLY RISER!"
            }
            if (hoursSleep <= 3) {
                this.iconSrc = "assets/images/insights/emojis/9.png";
                description = "NO SLEEP!"
            }
        } else if (category == "late") {
            if (hoursSleep >= 10) {
                this.iconSrc = "assets/images/insights/emojis/6.png";
                description = "SNOOZE, SNOOZE, REPEAT!"
            }
            if (hoursSleep >= 7 && hoursSleep <= 9) {
                this.iconSrc = "assets/images/insights/emojis/10.png";
                description = "GREAT NIGHT"
            }
            if (hoursSleep >= 4 && hoursSleep <= 7) {
                this.iconSrc = "assets/images/insights/emojis/3.png";
                description = "HATE MY JOB"
            }
            if (hoursSleep <= 3) {
                this.iconSrc = "assets/images/insights/emojis/5.png";
                description = "NO SLEEP!"
            }
        } else if (category == "party") {
            this.iconSrc = "assets/images/insights/emojis/9.png";
            if (hoursSleep <= 5)
                description = "PLAY HARD WORK HARDER";
            else
                description = "PARTY ALL NIGHT!"
        }
        if (hoursSleep === -1) {
            this.iconSrc = "assets/images/insights/emojis/-1.png";
        }
        if (hoursSleep === -2) {
            this.iconSrc = "assets/images/insights/emojis/5.png";
            //( typeof window != 'undefined' ? window : (<any>global) ).descriptionh = "NO DATA"
        }
        this.description = description.toUpperCase();
        //console.log(JSON.stringify(this));
    };
};



function addZero(i: any) {
    if (i < 10)
        i = "0" + i;
    return i
}
function getReadableHour(d: any) {
    var ampm = "AM";
    var h = d.getHours();
    if (h >= 12)
        ampm = "PM";
    if (h > 12)
        h = h - 12;
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());
    return h + ":" + m + ampm
}
function getReadableDate(d: any) {
    var m = monthName(d.getMonth());
    var d = d.getDate();
    return m + " " + d
}
function monthName(n: any) {
    var monthArr = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return monthArr[n]
}
const parseInsights = function (r: any): Promise<any[]> {
    return new Promise(resolve => {
        resolve(setInsights(r));
    })
};
const setInsights = function (data: any) {
    const finalChats = getFinalChats(data);
    if (!finalChats.length) {
        return [];
    }
    let insights: any = [];
    finalChats.forEach(function (person) {
        let chats = person.chats;
        let chatAnterior: any = null;
        let mayorHora = 0
            , sleepPeriod: any = null
            , tiempoPerdido: any = 0
            , diaActual: any = null
            , diasContados: any = 0
            , sleepAnteriorDate: any = null;

        chats.forEach(function (chat: any) {
            if (!diaActual)
                diaActual = (new Date(chat.start)).getDate();
            if (chat.dur > 0) {
                var hourDiff = Math.round(Math.abs(<any>(new Date()) - chat.start) / 36E5);
                if (hourDiff <= 24)
                    tiempoPerdido += chat.dur;
                if (chatAnterior) {
                    var hoursDifference = Math.round(Math.abs(chat.start - chatAnterior.start) / 36E5);
                    if (hoursDifference > mayorHora) {
                        mayorHora = hoursDifference;
                        var options = {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric"
                        };
                        var hours = Math.round(Math.abs(chat.start - chatAnterior.start) / 36E5);
                        sleepPeriod = {
                            whenh: chatAnterior.end.getHours(),
                            when: monthNames[chatAnterior.start.getMonth()] + " " + moment(chatAnterior.start).format("DD") + " - " + monthNames[chat.start.getMonth()] + " " + moment(chat.start).format("DD"),
                            startd: chatAnterior.start,
                            endd: chat.start,
                            time: hours
                        }
                    }
                }
                chatAnterior = chat
            }
            diaActual = (new Date(chat.start)).getDate();
            if (sleepPeriod && sleepPeriod.time >= 3) {
                if (sleepPeriod.time > 18) {
                    sleepPeriod.start = monthNames[sleepPeriod.startd.getMonth()] + " " + moment(sleepPeriod.startd).format("DD");
                    sleepPeriod.end = monthNames[sleepPeriod.endd.getMonth()] + " " + moment(sleepPeriod.endd).format("DD");
                    sleepPeriod.time = -1
                }
                if (sleepPeriod && (sleepPeriod.time >= 4 || sleepPeriod.time === -1))
                    if (!isSiesta(sleepPeriod))
                        insights.push({
                            sleep: sleepPeriod
                        })
            }
            mayorHora = 0,
                sleepPeriod = null,
                tiempoPerdido = 0
        })
    });
    setNoSleepInsights();
    let arrInsights: Insight[] = [];

    let ch: any = [];
    finalChats.forEach(function (person) {
        person.chats.forEach(function (chat: any) {
            ch.push(chat)
        })
    });
    console.log("LEN CHATS:");
    console.log(ch.length);
    let chatsGrouped = _.groupBy(ch, "rdate");
    if (insights)
        insights.forEach(function (i: any) {
            var chats = [],
                nightOwl = []
                , addiction = {
                    connections: 0,
                    minutes: 0
                };
            try {
                var fechax = new Date(i.sleep.endd.getTime());
                var fechaChats = fechax.setHours(0, 0, 0);
                chats = chatsGrouped[fechaChats];
                //console.log("chats",chats   )
                nightOwl = chats.filter(e => e.start.getHours() < 5).map(e => ({
                    start: e.start,
                    startHour: getReadableHour(e.start),
                    duration: Math.floor((e.ends - e.starts) / 1000)
                }))
                addiction = calculoAddiction(chats)
            } catch (e) {
                console.log(e)
            }
            arrInsights.push(new Insight("", i.sleep.startd, i.sleep.endd, i.sleep.whenh, i.sleep.time, addiction.minutes, addiction.connections, nightOwl))
        });
    arrInsights.sort(function (a: any, b: any) {
        a = new Date(a.sleep.date.getTime());
        b = new Date(b.sleep.date.getTime());
        return a > b ? -1 : a < b ? 1 : 0
    });
    return arrInsights;
    /*if (insights.length > 0)
        viewModel.set("selectedInsight", arrInsights[0]);
    viewModel.set("working", false)*/
};

var isSiesta = function (sleepPeriod: any) {
    if (sleepPeriod <= 4)
        return true;
    var horaDesde = sleepPeriod.startd.getHours();
    var horaHasta = sleepPeriod.endd.getHours();
    var diaDesde = sleepPeriod.startd.getDate();
    var diaHasta = sleepPeriod.endd.getDate();
    if (horaDesde >= 8 && horaDesde <= 19)
        return true;
    return false
};
var periodoErroneo = function (sleepPeriod: any) {
    if (sleepPeriod <= 4)
        return true;
    var horaDesde = sleepPeriod.startd.getHours();
    var horaHasta = sleepPeriod.endd.getHours();
    var diaDesde = sleepPeriod.startd.getDate();
    var diaHasta = sleepPeriod.endd.getDate();
    if (horaDesde >= horaHasta)
        return true;
    return false
};
(<any>Array.prototype).getUnique = function () {
    var o: any = {}, a: any = [], i: any, e: any;
    for (i = 0; e = this[i]; i++)
        o[e] = 1;
    for (e in o)
        a.push(e);
    return a
};
var setNoSleepInsights = function () {
    var diasDormidos: any = [];
    insights.forEach(function (i: any) {
        var desde = getDateSinHora(i.sleep.startd);
        var hasta = getDateSinHora(i.sleep.endd);
        diasDormidos.push(desde);
        diasDormidos.push(hasta)
    });
    diasDormidos = (<any>diasDormidos).getUnique();
    diasDormidos = diasDormidos.sort(function (a: any, b: any) {
        return (<any>new Date(b.date)) - (<any>new Date(a.date))
    });
    diasDormidos.forEach(function (d: any, i: any) {
        try {
            var dormido = moment(d);
            var manana: any = moment(dormido).add(1, "days");
            if (diasDormidos.length >= i + 2) {
                var match = new Date(manana) == diasDormidos[i + 1];
                var mananaReal = new Date(manana);
                if (!match) {
                    var hasta = (<any>moment(mananaReal).add(1, "days"))._d;
                    var sleepPeriod = {
                        when: monthNames[mananaReal.getMonth()] + " " + moment(mananaReal).format("DD") + " No sleep.",
                        end: monthNames[hasta.getMonth()] + " " + moment(hasta).format("DD") + " No sleep.",
                        start: monthNames[mananaReal.getMonth()] + " " + moment(mananaReal).format("DD") + " No sleep.",
                        endd: hasta,
                        startd: mananaReal,
                        time: -2
                    };
                    if (sleepPeriod && (sleepPeriod.time >= 4 || sleepPeriod.time === -1))
                        if (!isSiesta(sleepPeriod))
                            insights.push({
                                sleep: sleepPeriod
                            })
                }
            }
        } catch (e) { }
    });
    insights = insights.sort(function (a: any, b: any) {
        return (<any>new Date(b.sleep.startd)) - (<any>new Date(a.sleep.startd))
    })
};
var getDateSinHora = function (minuteLess: any) {
    var aa = minuteLess.getFullYear()
        , dd = minuteLess.getDate()
        , mm = minuteLess.getMonth()
        , hh = 0
        , min = 0;
    var d = new Date(aa, mm, dd, hh, min, 0);
    return d
};
var cambioElDia = function (sleepPeriod: any) {
    return true;
    var ultimoSleep = insights[insights.length - 1].sleep;
    if (ultimoSleep.endd.getDate() != sleepPeriod.startd.getDate())
        return true;
    return false
};
var getFinalChats = function (response: any) {

    const finalChats = [];
    var chats = _.groupBy(response.chats, "numberId");
    for (var idNumero in chats) {
        var chatsx: any = [];
        chats[idNumero].forEach(function (chat: any, x: any) {
            var start = new Date(parseFloat("" + chat.start + "000"));
            var end = new Date(parseFloat("" + chat.end + "000"));
            var starts = start.getTime();
            var ends = end.getTime();
            var t = start.getTime();
            var rdate = (new Date(t)).setHours(0, 0, 0);
            var secsDuration = moment(end).diff(moment(start), "seconds");
            var date = moment(start)
            chatsx.push({
                starts: starts,
                ends: ends,
                start: start,
                end: end,
                rdate: rdate,
                dur: chat.end - chat.start,
                moment: date.twix(end)
            })
        });
        finalChats.push({
            idNumero: idNumero,
            chats: chatsx
        })
    }
    return finalChats;
};

var calculoAddiction = function (chats: any) {
    var conn = 0
        , durtot = 0;
    chats.forEach(function (chat: any) {
        durtot += chat.dur;
        conn++
    });
    return {
        connections: conn,
        seconds: durtot,
        minutes: parseInt(String(durtot / 60))
    }
};

var decompress = function (str: any) {
    return LZString.decompressFromBase64(str)
};

export { parseInsights };