export const tr = {
  "app.name": "ChatWatch",
  "language": "en",
  "slogan": "Orijinal Whatsapp\nÇevrimiçi İzleyici",
  "goBack": "Geri dön",
  "menu": {
    "home": "Ev",
    "subscription": "Abonelik",
    "logOut": "Oturumu kapat"
  },
  "login": {
    "login": "Giriş",
    "logIn": "Giriş yap",
    "email": "E-posta",
    "password": "Şifre",
    "forgotPassword": "Şifrenizi mi unuttunuz?",
    "dontHaveAccount": "Hesabınız yok mu?",
    "register": "Kaydolun",
    "errorValidEmail": "Lütfen geçerli bir e-posta ayarlayın",
    "errorPasswordEmpty": "Lütfen bir şifre belirleyin"
  },
  "register": {
    "register": "Kayıt Olun",
    "email": "E-posta",
    "password": "Şifre",
    "repeatPassword": "Şifreyi tekrarla",
    "alreadyHaveAccount": "Zaten bir hesabınız var mı?",
    "logIn": "Giriş yap",
    "errorValidEmail": "Lütfen geçerli bir e-posta ayarlayın",
    "errorPasswordEmpty": "Lütfen bir şifre belirleyin",
    "errorPasswordShort": "Şifre en az 8 karakter olmalıdır",
    "errorPasswordNotMatch": "Şifreler eşit değildir"
  },
  "recoverPassword": {
    "recoverPassword": "Şifre Kurtarma",
    "errorValidEmail": "Lütfen geçerli bir e-posta ayarlayın",
    "email": "E-posta",
    "emailSent": "E-posta gönderildi"
  },
  "buySubscription": {
    "subscription": "Abonelik",
    "week": "HAFTA",
    "month": "AY",
    "year": "YIL",
    "weekly": "HAFTALIK",
    "monthly": "AYLIK",
    "yearly": "YILLIK",
    "startYourTrial": "3 GÜNLÜK ÜCRETSİZ DENEMENİZİ BAŞLATIN\nABONELİK SEÇİN",
    "selectPlan": "PLAN SEÇİN",
    "selectedPlan": "SEÇİLMİŞ PLAN",
    "payWith": "İLE ÖDEME",
    payWithCard: 'KART İLE ÖDEME',
    "card": "Kart",
    "fullName": "Tam adınız",
    "subscribe": "ABONE OL",
    "errorFullName": "Lütfen tam adınızı girin.",
    "errorCouldNotCreate": "Ödeme oluşturulamadı. Tekrar deneyin. KOD:1"
  },
  "chatOddsIntro": {
    "chatOdds": "Sohbet Oranları",
    "wantToTrackAnother": "Birlikte\nsohbet edip etmediklerini görmek için başka bir kişiyi\ntakip etmek ister misiniz?",
    "later": "Daha sonra",
    "yes": "Evet"
  },
  "noContacts": {
    "noContacts": "Bağlantı yok",
    "itSeems": "Görünüşe göre henüz\ntakip edilen kişiniz yok...",
    "willBeRecorded": "Seçilen kişiler için şu andan itibaren\nçevrimiçi bilgi kaydedilecektir.\nİzlenecek ilk kişiyi seçin.",
    "addContact": "İzlenecek Kişi Ekle"
  },
  "qrScan": {
    "enterCode": "Giriş kodunu girin",
    "followInstructions": "Bu {n}'yi taramak için talimatları izleyin Ana cihazınızdaki QR kodu.",
    "enjoy": "ChatWatch'u kullanmanın keyfini çıkarın!",
    "canClose": "Bu pencereyi kapatabilirsiniz",
    "openUrl": "<0>Bu\nURL'sini <3>BAŞKA</3> bir cihazdan açmalısınız</0>",
    "updatingServices": "Hizmetlerimizi güncelliyoruz,\nlütfen sayfayı yeniden yükleyin ve tekrar deneyin.",
    "msgConnecting1": "WA'ya bağlanılıyor... Lütfen Bekleyin...",
    "msgConnecting2": "Hesap senkronize ediliyor. Lütfen bekleyin...",
    "msgConnecting3": "Bilgi alınıyor... Lütfen bekleyin...",
    "msgConnecting4": "Oturum başlatıldı."
  },
  "resetPassword": {
    "resetPassword": "Şifre Sıfırlama",
    "errorPasswordEmpty": "Lütfen bir şifre belirleyin",
    "errorPasswordShort": "Şifre en az 8 karakter olmalıdır",
    "errorPasswordNotMatch": "Şifreler eşit değildir",
    "errorLinkNotValid": "Bağlantı geçerli değil",
    "newPassword": "Yeni şifre",
    "repeatNewPassword": "Yeni şifreyi tekrarla",
    "passwordReseted": "Şifre sıfırlandı"
  },
  "thankYou": {
    "thankYou": "Aboneliğiniz için teşekkür ederiz {n}!",
    "continue": "DEVAM"
  },
  "waLogin": {
    "privacyNotice": "Gizlilik bildirimi",
    "weNotStore": "Herhangi bir WA hesabı\nverisini saklamıyor, okumuyor veya\nerişmiyoruz.",
    "next": "Sonraki",
    "howToLogin": "Nasıl Giriş Yapılır?",
    "howToLogin1": "Bir bilgisayar, tablet, telefon veya Smart TV bulun\nZiyaret etmek",
    "howToLogin2": "Aşağıdaki kodu girin",
    "link": "WA Hesap Bağlantısı",
    "link1": "Bu simgeye dokunarak WA Ayarını açın.",
    "link2": "Bağlı cihazları seçin.",
    "link3": "Kamera etkinleştirildiğinde, QR Kodunu taramak için\ntelefonunuzu doğrultun",
    "connecting": "BAĞLANTI",
    "somethingWrong": "Bir şeyler ters gitti.\nLütfen bu sayfayı yeniden yükleyin ve tekrar deneyin.",
    "msgConnecting1": "WA'ya bağlanılıyor... Lütfen Bekleyin...",
    "msgConnecting2": "Hesap senkronize ediliyor. Lütfen bekleyin...",
    "msgConnecting3": "Bilgi alınıyor... Lütfen bekleyin...",
    "msgConnecting4": "Oturum başlatıldı."
  },
  "main": {
    "connectionManager": "Bağlantı Yöneticisi",
    "trackedContacts": "Takip edilen kişiler",
    "contactTrackingActive": "<0>Kişi izleme <1>etkin</1>.<br/>Çevrimdışı görünmek için izlemeyi devre dışı bırakabilirsiniz. Daha sonra tek tıkla aktif hale getirebilirsiniz. QR kodunu tekrar taramanıza gerek yoktur.</0>",
    "contactTrackingInactive": "<0>Kişi izleme <1>etkin değil</1>.</0>",
    "deactivateTracking": "İzlemeyi devre dışı bırak",
    "addContact": "Takip edilecek kişiyi ekleyin",
    "activateTracking": "İzlemeyi etkinleştirin!",
    "contactCard": {
      "notTracking": "Takip edilmiyor",
      "howToTrack": "İletişim nasıl takip edilir?",
      "onlineNotification": "Çevrimiçi Bildirim",
      "onlineHistory": "Çevrimiçi Geçmiş",
      "chatODDS": "OOD'larla sohbet et",
      "nightWatch": "Gece Gözcüsü",
      "delete": "Silmek",
      "lastSeen": {
        "online": "Çevrimiçi",
        "secsAgo": "{{count}} Saniye Önce",
        "secsAgo_plural": "{{count}} Saniye Önce",
        "minsAgo": "{{count}} Dk. Önce",
        "minsAgo_many": "{{count}} Dakika Önce",
        "hoursAgo": "{{count}} Saat Önce",
        "hoursAgo_plural": "{{count}} Saat Önce",
        "daysAgo": "{{count}} Gün Önce",
        "daysAgo_plural": "{{count}} Gün Önce"
      }
    }
  },
  "subscriptionDetails": {
    "subscriptionDetails": "Abonelik Ayrıntıları",
    "feature1": "{{count}} kişiye kadar takip edin!",
    "feature2": "Çevrimiçi Geçmiş",
    "feature3": "İletişim Bilgileri",
    "feature4": "Yapay zeka eğitimli sohbet olasılığı",
    "feature5": "Destek ekibi (e-posta)",
    "hasBeenCancelled": "<0>Bu abonelik <1>iptal edilmiştir</1> ve kartınızdan tekrar ücret alınmayacaktır.</0>",
    "accessWillEndOn": "Erişiminiz şu tarihte sona erecektir",
    "nextPaymentDate": "Bir sonraki ödeme tarihi",
    "cancelPlan": "Planı iptal et",
    "howToCancel": "Planınızı nasıl iptal edebilirsiniz?",
    "howToCancelText": "PayPal hesabınıza giriş yapın.\nAraçlar'a tıklayın, ardından sayfanın üst kısmından Tüm Araçlar'ı seçin.\nArdından Araçlar sayfasından Yinelenen Ödemeler'i seçin.\nAboneliğinizi buradan iptal edebilirsiniz.",
    "cancelSubscription": "Aboneliği İptal Et",
    "weAreSorryToEar": "İptal etmek istediğinizi duyduğumuza üzüldük. Herhangi bir hizmet veya kalite sorunu var mı?\nPotansiyel çözümler için bize bir e-posta göndermek ister misiniz?\nDeğilse, Aboneliği İptal Et düğmesine tıklayın.",
    "keepSubscription": "Aboneliğim kalsın"
  },
  "addNumber": {
    "selectContactToCompare": "Karşılaştırmak için bir kişi\nseçin",
    "selectContactToTrack": "İzlemek için bir kişi\nseçin",
    "searchBy": "Telefon veya isme göre arama",
    "noResultsFor": "Sonuç yok",
    "trySearchingByPhone": "Telefon numarasına göre aramayı deneyin"
  },
  "onlineHistory": {
    "onlineHistory": "Çevrimiçi Tarihçe",
    "last24Hours": "Son 24 saat",
    "selectADate": "Bir tarih seçin",
    "time": "Zaman",
    "offlineFor": "Çevrimdışı için",
    "hour": "saat",
    "hour_plural": "saatler",
    "from": "gelen",
    "to": "için",
    "notEnoughtData": "Yeterli veri yok, lütfen daha sonra tekrar gelin",
    "changeDate": "DEĞİŞİKLİK TARİHİ"
  },
  "compareWith": {
    "selectContactToCompareWith": "n} ile karşılaştırmak için bir kişi seçin",
    "searchBy": "Telefon veya isme göre arama"
  },
  "chatProbability": {
    "chatProbability": "Sohbet Olasılığı",
    "last24Hours": "Son 24 saat",
    "selectADate": "Bir tarih seçin",
    "time": "Zaman",
    "score": "Skor",
    "offlineFor": "Çevrimdışı için",
    "hour": "saat",
    "hour_plural": "saatler",
    "from": "gelen",
    "to": "için",
    "notEnoughtData": "Yeterli veri yok, lütfen daha sonra tekrar gelin",
    "changeDate": "DEĞİŞİKLİK TARİHİ"
  },

  nightWatch: {
    nightWatch: 'Gece Gözcüsü',
    sleptFor: 'EN FAZLA {{count}} SAAT UYKU',
    sleptFor_plural: 'EN FAZLA {{count}} SAAT UYKU',
    owlAlert: 'Baykuş Uyarısı',
    highestChatProbability: 'En Yüksek Sohbet Olasılığı',
    minutes: 'dakika',
    minutes_plural: 'dakika'
  }
}