import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';
import { LogoAndSlogan } from "../components/auth/logo-and-slogan";

export const ResetPasswordScreen = () => {

  const navigation = useNavigation()
  const {t} = useTranslation()
  
  const authService = useAuthService()
  const router = useRouter()
  const isLoading = useSelector((state:RootState)=>state.ui.isLoading)

  const [password, setPassword] = useState<string>("")
  const [password2, setPassword2] = useState<string>("")
  const [error, setError] = useState<string>(null)
  const [passwordReseted, setPasswordReseted] = useState<boolean>(false)

  const resetPassword = useCallback(async () => {
    if (!password) {
      return setError( t('resetPassword.errorPasswordEmpty') );
    }
    if (password.length < 8) {
      return setError( t('resetPassword.errorPasswordShort') );
    }
    if (password != password2) {
      return setError( t('resetPassword.errorPasswordNotMatch') );
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const hash = queryParameters.get("hash")
    if (!hash) {
      return setError( t('resetPassword.errorLinkNotValid') );
    }
    store.dispatch(setIsLoading(true))
    http.post("auth/updatePassword", { hash, password })
      .then(response => {
        console.log(response)
        setPasswordReseted(true)
        setTimeout(()=>{
          navigation.navigate("Login" as never)
        }, 5000)
      })
      .catch( e => {
        setError(e)
      })
      .finally(()=> store.dispatch(setIsLoading(false)) )
  }, [password, password2, setError, setPasswordReseted])

  useFocusEffect(useCallback(() => {
    router.notFunctionalGuard({from:"ResetPassword"})
  }, [authService.isLoggedIn])
  )

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, maxWidth: 600, alignSelf: "center" }} >
    <LogoAndSlogan/>

    <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { marginTop: 40, textAlign: 'center', fontSize: 18, textTransform: 'uppercase' }]}>{t('resetPassword.resetPassword')}</Text>

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'default'}
        textContentType={'password'}
        autoCapitalize={'none'}
        autoComplete={'password'}
        autoCorrect={false}
        secureTextEntry={true}
        placeholder={t('resetPassword.newPassword')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setPassword(text)}
        value={password}
      />

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'default'}
        textContentType={'password'}
        autoCapitalize={'none'}
        autoComplete={'password'}
        autoCorrect={false}
        secureTextEntry={true}
        placeholder={t('resetPassword.repeatNewPassword')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setPassword2(text)}
        value={password2}
      />

{!!error && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[ globalStyles.fontFamily, { color: 'rgba(255,53,53,0.8)', fontSize: 18, textAlign: "center" }]}>{error}</Text>
      </View>}

{!!passwordReseted && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[ globalStyles.fontFamily, { color: 'rgba(53, 255, 63, 0.8)', fontSize: 18, textAlign: "center" }]}>{t('resetPassword.passwordReseted')}</Text>
      </View>}

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20, opacity: passwordReseted || isLoading ? 0.5 : 1 }]} disabled={ passwordReseted || isLoading } onPress={resetPassword}>
        <Text style={globalStyles.primaryButtonText}>{t('resetPassword.resetPassword')}</Text>
      </TouchableOpacity>
    </View>
  </View>

}