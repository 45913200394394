import React, { useCallback } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
//import { LoginScreenProps, LoginWithEmailScreenProps } from '../navigation/screen-props';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../services/router-service';
import { globalStyles } from '../shared/styles';

export const ThankYouScreen = () => {
  const {t} = useTranslation()
  const router = useRouter()

  const _continue = useCallback(() => {
    router.enroute()
  }, [router])

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, maxWidth: 600, alignSelf: "center" }} >
      <View style={{ alignItems: 'center', justifyContent: 'center' }} >
        <Image source={ require('../../assets/images/purchase-successfull.png') } style={{ width: 140, height: 140 }} />
        <Text style={{ ...globalStyles.baseText, fontSize: 20, fontWeight: "700", textAlign: "center" }}>{t('thankYou.thankYou')}</Text>
      </View>

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20 }]} onPress={_continue}>
        <Text style={globalStyles.primaryButtonText}>{t('thankYou.continue')}</Text>
      </TouchableOpacity>
    </View>
  </View>

}
