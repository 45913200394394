import {store} from '../store';
import { configService } from './config.service';

export const http = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method: string) {
  return (endpoint: string, body?: any) => {
    const requestOptions: any = {
      method,
      headers: {
        'Content-Type': 'application/json',
        origin: `http://${configService.get('domain')}`,
      },
    };
    requestOptions.headers = addAuthHeader(requestOptions.headers);
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
    const url = configService.get('apiBaseUrl') + endpoint;
    console.debug(url);
    return fetch(url, requestOptions).then(handleResponse);
  };
}

// helper functions

function addAuthHeader(headers) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = true; //url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function authToken() {
  return store.getState().auth.accessToken;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      /*if ([401, 403].includes(response.status) && authToken()) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        const logout = () => store.dispatch(authActions.logout());
        logout();
      }*/

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
