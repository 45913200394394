export const ru = {
  "app.name": "ChatWatch",
  "language": "ru",
  "slogan": "Оригинальный Whatsapp\nОнлайн-трекер",
  "goBack": "Вернуться назад",
  "menu": {
    "home": "Главная",
    "subscription": "Подписка",
    "logOut": "Выйти из системы"
  },
  "login": {
    "login": "Вход",
    "logIn": "Войти",
    "email": "Электронная почта",
    "password": "Пароль",
    "forgotPassword": "Забыли пароль?",
    "dontHaveAccount": "У Вас нет учетной записи?",
    "register": "Зарегистрируйтесь",
    "errorValidEmail": "Пожалуйста, введите действительный адрес электронной почты",
    "errorPasswordEmpty": "Пожалуйста, введите пароль"
  },
  "register": {
    "register": "Зарегистрироваться",
    "email": "Электронная почта",
    "password": "Пароль",
    "repeatPassword": "Повторить пароль",
    "alreadyHaveAccount": "У Вас уже есть учетная запись?",
    "logIn": "Войдите в систему",
    "errorValidEmail": "Пожалуйста, введите действительный адрес электронной почты",
    "errorPasswordEmpty": "Пожалуйста, введите пароль",
    "errorPasswordShort": "Пароль должен состоять минимум из 8 символов",
    "errorPasswordNotMatch": "Пароли не равны"
  },
  "recoverPassword": {
    "recoverPassword": "Восстановить пароль",
    "errorValidEmail": "Пожалуйста, укажите правильный адрес электронной почты",
    "email": "Email",
    "emailSent": "Электронная почта отправлена"
  },
  "buySubscription": {
    "subscription": "Подписка",
    "week": "НЕДЕЛЯ",
    "month": "МЕСЯЦ",
    "year": "ГОД",
    "weekly": "ЕЖЕНЕДЕЛЬНО",
    "monthly": "МЕСЯЦ",
    "yearly": "ГОД",
    "startYourTrial": "НАЧНИТЕ СВОЮ БЕСПЛАТНУЮ 3-ДНЕВНУЮ ПРОБКУ\nВЫБЕРИТЕ ПОДПИСКУ",
    "selectPlan": "ВЫБРАННЫЙ ПЛАН",
    "selectedPlan": "ВЫБРАННЫЙ ПЛАН",
    "payWith": "ОПЛАТА С",
    payWithCard: 'ОПЛАТИТЬ КАРТОЙ',
    "card": "Картой",
    "fullName": "Полное имя",
    "subscribe": "ПОДПИСАТЬСЯ",
    "errorFullName": "Пожалуйста, укажите Ваше полное имя.",
    "errorCouldNotCreate": "Не удалось создать платеж. Попробуйте еще раз. КОД:1"
  },
  "chatOddsIntro": {
    "chatOdds": "Коэффициенты чата",
    "wantToTrackAnother": "Хотите отследить другой контакт с {n}, чтобы узнать, могут ли они общаться в чате\nвместе?",
    "later": "Позже",
    "yes": "Да"
  },
  "noContacts": {
    "noContacts": "Нет контактов",
    "itSeems": "Похоже, что у вас еще нет\nотслеживаемых контактов...",
    "willBeRecorded": "Начиная с этого момента\nдля выбранных контактов будет записываться информация о режиме онлайн.\nВыберите первый контакт для отслеживания.",
    "addContact": "Добавить контакт для отслеживания"
  },
  "qrScan": {
    "enterCode": "Введите код входа в систему",
    "followInstructions": "Следуя инструкциям, отсканируйте этот\nQR-код на вашем основном устройстве.",
    "enjoy": "Приятного использования ChatWatch!",
    "canClose": "Вы можете закрыть это окно",
    "openUrl": "<0>Вы должны открыть этот url\nс <3>другого</3> устройства</0>",
    "updatingServices": "Мы обновляем наши сервисы,\nпожалуйста, перезагрузите страницу и попробуйте снова.",
    "msgConnecting1": "Подключение к WA... Пожалуйста, подождите...",
    "msgConnecting2": "Синхронизация учетной записи. Пожалуйста, подождите...",
    "msgConnecting3": "Получение информации... Пожалуйста, подождите...",
    "msgConnecting4": "Сессия инициализирована."
  },
  "resetPassword": {
    "resetPassword": "Сброс пароля",
    "errorPasswordEmpty": "Пожалуйста, задайте пароль",
    "errorPasswordShort": "Пароль должен состоять минимум из 8 символов",
    "errorPasswordNotMatch": "Пароли не равны",
    "errorLinkNotValid": "Ссылка не действительна",
    "newPassword": "Новый пароль",
    "repeatNewPassword": "Повторить новый пароль",
    "passwordReseted": "Пароль сброшен"
  },
  "thankYou": {
    "thankYou": "Благодарим Вас\nза подписку!",
    "continue": "ПОДПИСКА"
  },
  "waLogin": {
    "privacyNotice": "Уведомление о конфиденциальности",
    "weNotStore": "Мы не храним, не читаем и не имеем {n}доступа к каким-либо {n}данным учетной записи WA.",
    "next": "Далее",
    "howToLogin": "Как войти в систему?",
    "howToLogin1": "Найдите компьютер, планшет, телефон или Smart TV\nПосетите .",
    "howToLogin2": "Введите следующий код",
    "link": "Привязать учетную запись WA",
    "link1": "Откройте настройки WA, нажав на этот значок.",
    "link2": "Выберите связанные устройства.",
    "link3": "Когда камера будет активирована, направьте телефон\nдля сканирования QR-кода",
    "connecting": "ПОДКЛЮЧЕНИЕ",
    "somethingWrong": "Что-то пошло не так.\nПожалуйста, перезагрузите эту страницу и повторите попытку.",
    "msgConnecting1": "Подключение к WA... Пожалуйста, подождите...",
    "msgConnecting2": "Синхронизация учетной записи. Пожалуйста, подождите...",
    "msgConnecting3": "Получение информации... Пожалуйста, подождите...",
    "msgConnecting4": "Сессия инициализирована."
  },
  "main": {
    "connectionManager": "Менеджер подключений",
    "trackedContacts": "Отслеживаемые контакты",
    "contactTrackingActive": "<0>Отслеживание контактов <1>активно</1>.<br/>Вы можете отключить отслеживание, чтобы оно отображалось в автономном режиме. Позже вы сможете активировать его одним щелчком мыши. Нет необходимости повторно сканировать QR-код.</0>",
    "contactTrackingInactive": "<0>Отслеживание контактов <1>неактивно</1>.</0>",
    "deactivateTracking": "Деактивировать отслеживание",
    "addContact": "Добавьте контакт для отслеживания",
    "activateTracking": "Активируйте отслеживание!",
    "contactCard": {
      "notTracking": "Не отслеживается",
      "howToTrack": "Как отследить контакт?",
      "onlineNotification": "Онлайн-уведомление",
      "onlineHistory": "Интернет-история",
      "chatODDS": "Чат ООДС",
      "nightWatch": "Ночной дозор",
      "delete": "Удалить",
      "lastSeen": {
        "online": "В сети",
        "secsAgo": "{{count}} сек. назад",
        "secsAgo_plural": "{{count}} секунд назад",
        "minsAgo": "{{count}} мин назад",
        "minsAgo_many": "{{count}} минут назад",
        "hoursAgo": "{{count}} час назад",
        "hoursAgo_plural": "{{count}} часов назад",
        "daysAgo": "{{count}} день назад",
        "daysAgo_plural": "{{count}} дней назад"
      }
    }
  },
  "subscriptionDetails": {
    "subscriptionDetails": "Подробности подписки",
    "feature1": "Отслеживайте до {{count}} контактов!",
    "feature2": "Интернет-история",
    "feature3": "Информация о контактах",
    "feature4": "Вероятность общения в чате с помощью искусственного интеллекта",
    "feature5": "Служба поддержки (электронная почта)",
    "hasBeenCancelled": "<0>Эта подписка была <1>отменена</1> и с вашей карты больше не будет списана сумма.</0>",
    "accessWillEndOn": "Ваш доступ закончится",
    "nextPaymentDate": "Дата следующего платежа",
    "cancelPlan": "Отмена плана",
    "howToCancel": "Как отменить подписку?",
    "howToCancelText": "Войдите в свой аккаунт PayPal.\nНажмите Инструменты, затем выберите Все инструменты в верхней части страницы.\nЗатем выберите Recurring Payments (Повторяющиеся платежи) на странице Tools (Инструменты).\nТам можно отменить подписку.",
    "cancelSubscription": "Отменить подписку",
    "weAreSorryToEar": "Мы сожалеем, что вы хотите отменить подписку. Есть ли какие-либо проблемы с обслуживанием или качеством?\nНе хотите ли вы отправить нам электронное письмо для возможного решения?\nЕсли нет, нажмите на кнопку Отменить подписку.",
    "keepSubscription": "Сохранить подписку"
  },
  "addNumber": {
    "selectContactToCompare": "Выберите контакт\nдля сравнения",
    "selectContactToTrack": "Выберите контакт\nдля отслеживания",
    "searchBy": "Поиск по телефону или имени",
    "noResultsFor": "Нет результатов",
    "trySearchingByPhone": "Попробуйте выполнить поиск по номеру телефона"
  },
  "onlineHistory": {
    "onlineHistory": "Онлайн история",
    "last24Hours": "Последние 24 часа",
    "selectADate": "Выберите дату",
    "time": "Время",
    "offlineFor": "Не в сети в течение",
    "hour": "час",
    "hour_plural": "часов",
    "from": "с",
    "to": "до",
    "notEnoughtData": "Недостаточно данных, пожалуйста, вернитесь позже",
    "changeDate": "ИЗМЕНИТЬ ДАТУ"
  },
  "compareWith": {
    "selectContactToCompareWith": "Выберите контакт для сравнения",
    "searchBy": "Поиск по телефону или имени"
  },
  "chatProbability": {
    "chatProbability": "Вероятность чата",
    "last24Hours": "Последние 24 часа",
    "selectADate": "Выберите дату",
    "time": "Время",
    "score": "Оценка",
    "offlineFor": "Не в сети в течение",
    "hour": "час",
    "hour_plural": "часов",
    "from": "с",
    "to": "до",
    "notEnoughtData": "Недостаточно данных, пожалуйста, вернитесь позже",
    "changeDate": "ИЗМЕНИТЬ ДАТУ"
  },

  nightWatch: {
    nightWatch: 'Ночной дозор',
    sleptFor: 'СПАЛА {{count}} ЧАС TOP',
    sleptFor_plural: 'СПАЛА {{count}} ЧАСОВ TOP',
    owlAlert: 'Оповещение о сове',
    highestChatProbability: 'Самая высокая вероятность общения',
    minutes: 'минута',
    minutes_plural: 'минуты'
  }
}