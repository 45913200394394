
const moment = require("moment");
require('twix');
const _ = require("underscore");
import { Contact } from './interfaces';
import { LZString } from './LZString'
var chatProbalilityContacts: any, selectedDate: any;

declare var navigator;

const decompress = function (str: any) {
  return LZString.decompressFromBase64(str)
};

class OfflinePeriod {
  end: string
  start: string
  hours: number
  constructor(start: string, end: string, hours: number) {
    this.start = start
    this.end = end
    this.hours = hours
  }
}

export class HistoryRecord {
  public score: any
  public contact1_online: any
  public contact2_online: any
  public hourRange: any

  constructor(score: any, contact1_online: any, contact2_online: any, hourRange: any) {
    this.score = {
      stars: score[0],
      pos: score[1]
    }
    var ca1 = [], ca2 = [];
    contact1_online.forEach((o, x) => {
      var c1 = o ? "online" : "offline"
        , c2 = contact2_online[x] ? "online" : "offline";
      if (o && contact2_online[x]) {
        c1 = "intersection";
        c2 = c1
      }
      ca1.push(c1);
      ca2.push(c2)
    });
    this.contact1_online = ca1
    this.contact2_online = ca2
    this.hourRange = hourRange;
  };
};

export const extractPayload = (payload:string) => {
  var chatsString = decompress(payload); 
  return JSON.parse(chatsString);
}

function parseChats(parsedResponse: any, selectedDateInput: any, primaryContact: Contact) {
  selectedDate = selectedDateInput; 
  return new Promise(async resolve => {
    //console.log("PARSEEE·EDEEEEE", parsedResponse)
    chatProbalilityContacts = parsedResponse.contacts
    let contacts: any = [primaryContact];

    let finalChats: any = getFinalChats(parsedResponse);


    if (finalChats.length == 0) {
      contacts = [];//no hay contactos
    }
    //volteo los chats si no hay historial para alguno de los dos y estan al revez en los resultados
    if (finalChats.length == 1 && contacts.length == 2) {
      const unicoId = parseInt(finalChats[0].idNumero)
      if (contacts[0].sortId != unicoId) contacts.reverse();
    };

    if( Object.keys( chatProbalilityContacts ).length == 2 && Number( finalChats[0].idNumero ) != chatProbalilityContacts[ primaryContact.phone ] ){
      console.log("ESTÄN AL REVËS!!!!!!")
      finalChats = finalChats.reverse()
    }

    const intersectionChats = getIntersectionChats(finalChats);
    const minuteArray = getLast24Hours(finalChats);
    const { renderer, mergedArrays } = mergeMinutesAndChats(minuteArray, finalChats, intersectionChats);
    console.log("mergedArrays")
    var historyRecords = getHistoryRecords(mergedArrays);
    //const historyRecords = getHistoryRecords(minuteArray);


    resolve({
      contact1: contacts[0] || null,
      contact2: contacts[1] || null,
      historyRecords
    });

  });
};
function mergeMinutesAndChats(minuteArray: any, finalChats: any, intersectionChats: any) {

  minuteArray.forEach(function (m: any) {

    const timeString = m.hour + ":" + m.minute + " " + m.ampm;
    //console.log(timeString)
    var overlapsA: any = [];
    if (finalChats[0])
      finalChats[0].chats.forEach(function (chat: any) {
        if (m.moment.overlaps(chat.moment) || chat.moment.overlaps(m.moment)) {
          const inter = m.moment.intersection(chat.moment);
          overlapsA.push(inter)
        };
      });

    m.chatA = generateBlocks(overlapsA);

    var overlapsB: any = [];
    if (finalChats[1])
      finalChats[1].chats.forEach(function (chat: any) {
        if (m.moment.overlaps(chat.moment) || chat.moment.overlaps(m.moment)) {
          var inter = m.moment.intersection(chat.moment);
          overlapsB.push(inter)
        }
      });

    m.chatB = generateBlocks(overlapsB);

    var overlapped = false;
    intersectionChats.forEach(function (i: any, x: any) {
      var index = x;

      if (m.moment.overlaps(i.moment)) {

        overlapped = true;
        var inter = m.moment.intersection(i.moment);
        var dover = new Date(inter._start);
        m.score = i.score;
        m.scorePosition = chatBlock(dover.getSeconds());
        intersectionChats.splice(index, 1)
      }
    })

  });
  let mergedArrays = minuteArray
  let rendering: any = [];
  let timeBefore: any = null;

  mergedArrays.forEach((ma: any) => {
    const empty = { block0: false, block1: false, block2: false, block3: false, block4: false, block5: false };
    if (!ma.chatA) ma.chatA = empty;
    if (!ma.chatB) ma.chatB = empty;
    const online1 = (ma.chatA.block5 || ma.chatA.block4 || ma.chatA.block3 || ma.chatA.block2 || ma.chatA.block1 || ma.chatA.block0);
    const online2 = (ma.chatB.block5 || ma.chatB.block4 || ma.chatB.block3 || ma.chatB.block2 || ma.chatB.block1 || ma.chatB.block0);
    const online = online1 || online2;
    let hDurationOffline = 0, offlinePeriod = null;
    if (online) {
      if (timeBefore) {
        const now = ma.moment._start.toDate();
        hDurationOffline = moment(timeBefore).diff(moment(now), "hours");
        if (hDurationOffline) console.log("OFFLINE FOR", hDurationOffline)
        offlinePeriod = { end: timeBefore.toLocaleTimeString(navigator.language), start: now.toLocaleTimeString(navigator.language), offlineIndicator: true, hours: hDurationOffline }
        if (hDurationOffline) ma.offlinePeriod = offlinePeriod;
      }
      timeBefore = ma.moment._start.toDate()

    };
    //console.log(ma.chatA.block0, ma.chatB.block0)

    if (online) {
      if (hDurationOffline) rendering.push(offlinePeriod);
      rendering.push({ time: '', contact1: ma.chatA.block5, contact2: ma.chatB.block5, intersection: (ma.chatA.block5 && ma.chatB.block5), score: (ma?.score && ma?.scorePosition == 5) ? ma.score : 0, offlineIndicator: false });
      rendering.push({ time: '', contact1: ma.chatA.block4, contact2: ma.chatB.block4, intersection: (ma.chatA.block4 && ma.chatB.block4), score: (ma?.score && ma?.scorePosition == 4) ? ma.score : 0, offlineIndicator: false });
      rendering.push({ time: '', contact1: ma.chatA.block3, contact2: ma.chatB.block3, intersection: (ma.chatA.block3 && ma.chatB.block3), score: (ma?.score && ma?.scorePosition == 3) ? ma.score : 0, offlineIndicator: false });
      rendering.push({ time: '', contact1: ma.chatA.block2, contact2: ma.chatB.block2, intersection: (ma.chatA.block2 && ma.chatB.block2), score: (ma?.score && ma?.scorePosition == 2) ? ma.score : 0, offlineIndicator: false });
      rendering.push({ time: '', contact1: ma.chatA.block1, contact2: ma.chatB.block1, intersection: (ma.chatA.block1 && ma.chatB.block1), score: (ma?.score && ma?.scorePosition == 1) ? ma.score : 0, offlineIndicator: false });
      rendering.push({ time: ma.hour + ':' + ma.minute + ma.ampm, contact1: ma.chatA.block0, contact2: ma.chatB.block0, intersection: (ma.chatA.block0 && ma.chatB.block0), score: (ma?.score && ma?.scorePosition == 0) ? ma.score : 0, offlineIndicator: false });

    }

  });

  return { renderer: rendering, mergedArrays };
};

const getFinalChats = function (response: any) {

  let finalChats = [];

  const chats = _.groupBy(response.chats, "numberId");

  for (var idNumero in chats) {
    let chatsx: any = [];

    chats[idNumero].forEach(function (chat: any, x: any) {

      const start = new Date(parseFloat("" + chat.start + "000"));
      const end = new Date(parseFloat("" + chat.end + "000"));
      const starts = start.getTime();
      const ends = end.getTime();
      let date = (<any>moment(start))
      //var secsDuration = moment(end).diff(moment(start), "seconds");
      chatsx.push({
        starts: starts,
        ends: ends,
        start: start,
        end: end,
        dur: chat.end - chat.start,
        moment: date.twix(end)
      })
    });

    if (!finalChats) finalChats = []
    finalChats.push({
      idNumero: idNumero,
      chats: chatsx
    });
  }

  return finalChats;
};
const chatBlock = function (second: any) {
  if (second >= 0 && second <= 10)
    return 0;
  else if (second > 10 && second <= 20)
    return 1;
  else if (second > 20 && second <= 30)
    return 2;
  else if (second > 30 && second <= 40)
    return 3;
  else if (second > 40 && second <= 50)
    return 4;
  else if (second > 50 && second <= 60)
    return 5
  else return -1;
};

const generateBlocks = function (overlaps: any) {
  if (overlaps.length === 0)
    return false;
  let bloques: any = [];
  overlaps.forEach(function (inter: any) {
    const start = Math.floor(inter._start._d.getSeconds() / 10);
    const end = Math.floor(inter._end._d.getSeconds() / 10);

    const bloque = {
      block0: start <= 0 && end >= 0,
      block1: start <= 1 && end >= 1,
      block2: start <= 2 && end >= 2,
      block3: start <= 3 && end >= 3,
      block4: start <= 4 && end >= 4,
      block5: start <= 5 && end >= 5
    };
    bloques.push(bloque)
  });
  let finalBlock: any = {
    block0: false,
    block1: false,
    block2: false,
    block3: false,
    block4: false,
    block5: false
  };
  bloques.forEach(function (b: any) {
    if (b.block0 === true)
      finalBlock.block0 = true;
    if (b.block1 === true)
      finalBlock.block1 = true;
    if (b.block2 === true)
      finalBlock.block2 = true;
    if (b.block3 === true)
      finalBlock.block3 = true;
    if (b.block4 === true)
      finalBlock.block4 = true;
    if (b.block5 === true)
      finalBlock.block5 = true
  });


  if (JSON.stringify(finalBlock) == JSON.stringify({
    block0: false,
    block1: false,
    block2: false,
    block3: false,
    block4: false,
    block5: false
  })) finalBlock = false;

  return finalBlock
};

(<any>Array.prototype).getUnique = function () {
  let o: any = {}, a: any = [], i: any, e: any;
  for (i = 0; e = this[i]; i++)
    o[e] = 1;
  for (e in o)
    a.push(e);
  return a;
};
const getDateLimits = function () {
  //var ds = new Date(); // (<any>viewModel).date; // TODO
  let ds = selectedDate;
  let dsc = new Date(ds.getTime());
  dsc.setSeconds(59, 999);
  let maxDate = new Date(dsc.getTime());
  let minDate = new Date(dsc.getTime() - 24 * 60 * 60 * 1E3);
  minDate.setSeconds(0, 0);
  let o = {
    max: maxDate,
    min: minDate
  };

  return o
};
const getLast24Hours = function (finalChats: any) {
  let minutesOnly = [];
  let ds = new Date(); // (<any>viewModel).date; // TODO
  let dsc = new Date(ds.getTime());
  dsc.setSeconds(0, 0);
  let dateLimits = getDateLimits();
  finalChats.forEach(function (fc: any) {
    fc.chats.forEach(function (chat: any) {

      var s = (new Date(chat.starts)).setSeconds(0, 0);
      var e = (new Date(chat.ends)).setSeconds(0, 0);
      minutesOnly.push(s - 6E4);
      let current = s
      while (current != e) {
        minutesOnly.push(current)
        current += 6E4
      }
      minutesOnly.push(e)
    });
  });
  minutesOnly.push(dsc.getTime());
  minutesOnly.push(dateLimits.min.getTime() + 6E4);
  minutesOnly = (<any>minutesOnly).getUnique();
  minutesOnly.sort(function (a: number, b: number) {
    return a > b ? -1 : a < b ? 1 : 0
  });
  let arrayMinutos: any = [];
  minutesOnly.forEach(function (min: any) {
    min = parseInt(min);
    let start = new Date(min);
    let end = new Date(min);
    let hora = start.getHours(), ampm = "AM";
    start.setSeconds(0, 0);
    end.setSeconds(59, 999);
    ampm = "AM";
    if (hora >= 12)
      ampm = "PM";
    if (hora > 12)
      hora -= 12;
    if (hora === 0)
      hora = 12;
    let o = {
      hour: "00".substring(0, "00".length - (hora + "").length) + "" + hora,
      minute: "00".substring(0, "00".length - (start.getMinutes() + "").length) + "" + start.getMinutes(),
      moment: (<any>moment(start)).twix(end),
      ampm: ampm,
      chatA: false,
      chatB: false,
      usedTimes: 0
    };
    if (new Date((<any>o.moment)._start) >= new Date(dateLimits.min) && new Date((<any>o.moment)._end) <= new Date(dateLimits.max)) {
      arrayMinutos.push(o);
    };
  });


  return arrayMinutos;
};

const getIntersectionChats = function (finalChats: any) {
  let intersectionChats: any = [];
  if (finalChats[0]) finalChats[0].chats.forEach(function (chatA: any) {

    if (finalChats[1]) finalChats[1].chats.forEach(function (chatB: any) {
      const overlaps = chatA.moment.overlaps(chatB.moment);
      if (overlaps) {

        let inter = chatA.moment.intersection(chatB.moment);

        let intersectionDuration = moment(inter._end._d).diff(moment(inter._start._d), "seconds");
        let pctI = Math.round((intersectionDuration / chatA.dur + intersectionDuration / chatB.dur) / .02);
        const ic = {
          used: false,
          ip: pctI,
          chatA: chatA,
          chatB: chatB,
          start: inter._start._d,
          end: inter._end._d,
          dur: intersectionDuration,
          moment: inter,
          momentStart: (<any>moment(inter._start._d)).twix(inter._start._d)
        };
        //console.log("INTERSECTION CHAT: ", ic);
        intersectionChats.push(ic);
      };
    });
  });

  intersectionChats.forEach(function (i: any, x: any) {
    i.lead = (<any>moment(i.chatA.start <= i.chatB.start ? i.chatA.start : i.chatB.start)).twix(i.chatA.start >= i.chatB.start ? i.chatA.start : i.chatB.start);
    i.tail = (<any>moment(i.chatA.end <= i.chatB.end ? i.chatA.end : i.chatB.end)).twix(i.chatA.end >= i.chatB.end ? i.chatA.end : i.chatB.end);
    i.percentageConnection = getPercentageConnection(i, finalChats);
    i.DQ = DQ(i.dur);
    i.LQ = LQ(i.lead.count("seconds"));
    i.TQ = TQ(i.tail.count("seconds"));
    i.AQ = AQ(i.percentageConnection);
    i.TOTALQ = i.DQ + i.LQ + i.TQ + i.AQ;
    let horaComienza = (new Date(i.start)).getHours();
    if (horaComienza >= 1 && horaComienza < 6) i.TOTALQ += .1;
    i.score = score(i.TOTALQ)
  })

  return intersectionChats;
};
function getHistoryRecords(minuteArray: any) {
  let chats: any = []
    , periodStarts: any = false
    , minutoVacio = [false, false, false, false, false, false];
  minuteArray.forEach(function (m: any, x: any) {
    if ('offlinePeriod' in m) {
      chats.push({ offlinePeriod: new OfflinePeriod(m.offlinePeriod.start, m.offlinePeriod.end, m.offlinePeriod.hours) })
    }
    var timeString = m.hour + ":" + m.minute + " " + m.ampm;
    var chatA = minutoVacio
      , chatB = minutoVacio;
    if (m.chatA || m.chatB) {
      if (periodStarts) {

        chats.push(new HistoryRecord([0, 0], minutoVacio, minutoVacio, {
          from: periodStarts,
          to: timeString
        }));
        periodStarts = false
      }
      if (m.chatA) chatA = [m.chatA.block0, m.chatA.block1, m.chatA.block2, m.chatA.block3, m.chatA.block4, m.chatA.block5];
      if (m.chatB) chatB = [m.chatB.block0, m.chatB.block1, m.chatB.block2, m.chatB.block3, m.chatB.block4, m.chatB.block5];
      let hr = new HistoryRecord([m.score, m.scorePosition], chatA, chatB, {
        from: timeString,
        to: null
      });
      chats.push(hr)
    } else if (!periodStarts) {
      periodStarts = timeString;
      if (x === minuteArray.length - 1) {
        const hr = new HistoryRecord([0, 1], chatA, chatB, {
          from: timeString,
          to: timeString
        });
        chats.push(hr)
      };
    };

  });

  return chats;
}
function score(t: any) {
  //console.log("CALCULA SCORE", t);
  if (t <= .4)
    return 1;
  else if (t > .4 && t <= .5)
    return 2;
  else if (t > .5 && t <= .6)
    return 3;
  else if (t > .6 && t <= .7)
    return 4;
  else if (t > .7)
    return 5
  return 0;
};

function AQ(pct: any) {
  if (pct < 5)
    return .1243;
  else if (pct >= 5 && pct <= 85)
    return -1 * pct / 100 / .05 * .0043 + .12;
  else if (pct > 85)
    return .0469
  return 0

}
function DQ(duration: any) {
  if (duration < 10)
    return .02;
  else if (duration >= 10 && duration <= 130)
    return .002 * duration + .0259;
  else if (duration > 130)
    return .3351
  return 0
}
function LQ(duration: any) {
  if (duration < 10)
    return .1424;
  else if (duration >= 10 && duration <= 150)
    return -.006 * duration / 10 + .1424;
  else if (duration > 150)
    return .0524
  return 0
}
function TQ(duration: any) {
  if (duration < 10)
    return .1512;
  else if (duration >= 10 && duration <= 150)
    return -6.2E-4 * duration / 10 + .145;
  else if (duration > 150)
    return .052
  return 0
}
function getPercentageConnection(i: any, finalChats: any) {
  var p = (<any>moment(i.chatA.start).subtract(1, "hours")).twix(moment(i.chatA.start));
  var chatsA = finalChats[0].chats;
  var chatsB = finalChats[1].chats;
  var yes = 0;
  var no = 0;
  let segundosUltimaHoraA: number = 0, segundosUltimaHoraB: number = 0;
  chatsA.forEach(function (c: any) {
    if (p.overlaps(c.moment))
      segundosUltimaHoraA += p.intersection(c.moment).count("seconds")
  });
  chatsB.forEach(function (c: any) {
    if (p.overlaps(c.moment))
      segundosUltimaHoraB += p.intersection(c.moment).count("seconds")
  });
  var pctA = segundosUltimaHoraA / 3600 * 100;
  var pctB = segundosUltimaHoraB / 3600 * 100;
  var pctf = (pctA + pctB) / 2;
  return pctf
};

export { decompress, parseChats }