import React, { Component } from 'react';
import { Animated } from 'react-native';
import { AddNumberScreen } from '../screens/add-number';
import { BuySubscriptionScreen } from '../screens/buy-subscription';
import { ChatProbabilityScreen } from '../screens/chat-probability';
import { CompareWithScreen } from '../screens/compare-with';
import { InsightsScreen } from '../screens/insights';
import { LoginScreen } from '../screens/login';
import { MainScreen } from '../screens/main';
import { OnlineHistoryScreen } from '../screens/online-history';
import { QRScanScreen } from '../screens/qr-scan';
import { Stack } from './stack';
import { RegisterScreen } from '../screens/register';
import { ThankYouScreen } from '../screens/thank-you';
import { WALoginScreen } from '../screens/wa-login';
import { NoContactsScreen } from '../screens/no-contacts';
import { ChatOddsIntroScreen } from '../screens/chat-odds-intro';
import { NighwatchScreen } from '../screens/nightwatch';
import { SubscriptionDetailsScreen } from '../screens/subscription-details';
import { RecoverPasswordScreen } from '../screens/recover-password';
import { ResetPasswordScreen } from '../screens/reset-password';

const forSlide = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.width, // Focused, but offscreen in the beginning
                0, // Fully focused
                screen.width * -0.3, // Fully unfocused
              ],
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
};

export class StackNavigator extends Component {
  render() {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
          animationEnabled: true,
          cardStyleInterpolator: forSlide as any,
        }}
        initialRouteName="Main">
        <Stack.Screen name="Login" component={LoginScreen} />
        <Stack.Screen name="Register" component={RegisterScreen} />
        <Stack.Screen name="RecoverPassword" component={RecoverPasswordScreen} />
        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
        <Stack.Screen name="WALogin" component={WALoginScreen} />
        <Stack.Screen name="QRScan" component={QRScanScreen} />
        <Stack.Screen name="Main" component={MainScreen} />
        <Stack.Screen name="NoContacts" component={NoContactsScreen} />
        <Stack.Screen name="ChatOddsIntro" component={ChatOddsIntroScreen} />
        <Stack.Screen name="AddNumber" component={AddNumberScreen} />
        <Stack.Screen name="OnlineHistory" component={OnlineHistoryScreen} />
        <Stack.Screen name="CompareWith" component={CompareWithScreen} />
        <Stack.Screen name="ChatProbability" component={ChatProbabilityScreen} />
        <Stack.Screen name="Nightwatch" component={NighwatchScreen} />
        <Stack.Screen name="BuySubscription" component={BuySubscriptionScreen} />
        <Stack.Screen name="ThankYou" component={ThankYouScreen} />
        <Stack.Screen name="SubscriptionDetails" component={SubscriptionDetailsScreen} />
      </Stack.Navigator>
    );
  }
}
