import Poppins from "../assets/fonts/Poppins-Regular.ttf";
import Raleway from "../assets/fonts/Raleway-VariableFont_wght.ttf";
import NunitoSansRegular from "../assets/fonts/NunitoSans-Regular.ttf";
import NunitoSansBold from "../assets/fonts/NunitoSans-ExtraBold.ttf";
import FA5IconSolid from "../assets/fonts/FontAwesome5_Solid.ttf";
import FA5IconRegular from "../assets/fonts/FontAwesome5_Regular.ttf";

const iconFontStyles = `
@font-face {
    src: url(${Raleway});
    font-family: Raleway;
}
@font-face {
    src: url(${Poppins});
    font-family: Poppins;
}
@font-face {
    src: url(${FA5IconSolid});
    font-family: 'FontAwesome5_Solid';
}
@font-face {
    src: url(${NunitoSansRegular});
    font-family: 'Nunito Sans';
}
@font-face {
    src: url(${NunitoSansBold});
    font-family: 'Nunito Sans Bold';
}
    @font-face {
        src: url(${FA5IconRegular});
        font-family: 'FontAwesome5_Regular';
    }
`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);