import { useFocusEffect, useNavigation } from "@react-navigation/native";
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome5";
import { useAuthService } from '../services/auth-service';
import { useRouter } from '../services/router-service';
import { isApp } from '../shared';
import { APP } from '../shared/constants';
import { globalStyles } from '../shared/styles';

export const NoContactsScreen = () => {

  const navigation = useNavigation()
  const {t} = useTranslation()

  const router = useRouter()
  const authService = useAuthService()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({from:"NoContacts"})
  }, [authService.isWALoggedIn,authService.isLoggedIn,authService.isValidSubscription])
  )

  const goToAddNumber = useCallback(() => {
    navigation.navigate('AddNumber' as never)
  }, [navigation])

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, maxWidth: 600, alignSelf: "center" }} >
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Image source={ require('../../assets/images/big-alert.png') } resizeMode='contain' style={{ width: "100%", maxWidth: 200, aspectRatio: 1, marginBottom: 40, filter: isApp(APP.WAWATCHER) ? 'grayscale(1) brightness(1.5)' : undefined }} />

        <Text style={[ globalStyles.introBaseTextBold, { fontSize: 26, marginBottom: 16 }]}>{t('noContacts.noContacts')}</Text>
        <Text style={[ globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, marginBottom: 20, opacity: 0.6 }]}>{t('noContacts.itSeems')}</Text>
        <Text style={[ globalStyles.introBaseText, { textAlign: "center", lineHeight: 20, opacity: 0.6 }]}>{t('noContacts.willBeRecorded')}</Text>
      </View>

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20 }]} onPress={goToAddNumber}>
        <Icon size={17} color={'white'} name={"plus"} light />
        <Text style={[globalStyles.primaryButtonText, { fontFamily: globalStyles.introBaseTextBold.fontFamily, marginLeft: 12 }]}>{t('noContacts.addContact')}</Text>
      </TouchableOpacity>
    </View>
  </View>

}
const styles = StyleSheet.create({
  main: {
    ...globalStyles.background,
  },
  input: {
    width: "100%",
    height: 50,
    fontSize: 18,
    paddingHorizontal: 16,
    borderRadius: 7,
    backgroundColor: 'rgba(255,255,255,0.1)',
    backdropFilter: "blur(5px)",
    color: 'white'
  },
});
