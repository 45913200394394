import React, {Component} from 'react';
import {StyleSheet, View, Image} from 'react-native';
import { ImageAspectFit } from './image';

export interface ContactAvatarProps {
  profilePic: string
}

export class ContactAvatar extends Component<ContactAvatarProps> {

  render() {
    return (
      <View style={{ ...styles.circle }}>
        { !!this.props.profilePic ? 
        <Image source={{ uri: this.props.profilePic }} style={{ width: 66, height: 66 }} />
        :
        <ImageAspectFit asset={require('../../assets/images/contact-icon.png') } style={{ width: 66 }} />
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 66,
    height: 66,
    borderRadius: 33,
    overflow: 'hidden'
  },
});
