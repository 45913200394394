export const pt = {
  "app.name": "ChatWatch",
  "language": "pt",
  "slogan": "O Whatsapp\noriginal Rastreador online",
  "goBack": "Voltar atrás",
  "menu": {
    "home": "Início",
    "subscription": "Subscrição",
    "logOut": "Terminar sessão"
  },
  "login": {
    "login": "Iniciar sessão",
    "logIn": "Iniciar sessão",
    "email": "Correio eletrónico",
    "password": "Palavra-passe",
    "forgotPassword": "Esqueceu-se da palavra-passe?",
    "dontHaveAccount": "Não tem uma conta?",
    "register": "Registar",
    "errorValidEmail": "Por favor, defina um e-mail válido",
    "errorPasswordEmpty": "Por favor, defina uma palavra-passe"
  },
  "register": {
    "register": "Registar",
    "email": "Correio eletrónico",
    "password": "Palavra-passe",
    "repeatPassword": "Repetir palavra-passe",
    "alreadyHaveAccount": "Já tem uma conta?",
    "logIn": "Iniciar sessão",
    "errorValidEmail": "Por favor, defina um e-mail válido",
    "errorPasswordEmpty": "Por favor, defina uma palavra-passe",
    "errorPasswordShort": "A palavra-passe deve ter um mínimo de 8 caracteres",
    "errorPasswordNotMatch": "As palavras-passe não são iguais"
  },
  "recoverPassword": {
    "recoverPassword": "Recuperar palavra-passe",
    "errorValidEmail": "Por favor, defina um e-mail válido",
    "email": "Email",
    "emailSent": "Email enviado"
  },
  "buySubscription": {
    "subscription": "Subscrição",
    "week": "SEMANA",
    "month": "MÊS",
    "year": "ANO",
    "weekly": "SEMANAL",
    "monthly": "MENSAL",
    "yearly": "ANUAL",
    "startYourTrial": "INICIE A SUA AVALIAÇÃO GRATUITA DE 3 DIAS\nSELECCIONAR SUBSCRIÇÃO",
    "selectPlan": "PLANO SELECCIONADO",
    "selectedPlan": "PLANO SELECCIONADO",
    "payWith": "PAGAR COM",
    payWithCard: 'PAGUE COM CARTÃO',
    "card": "Cartão",
    "fullName": "Nome completo",
    "subscribe": "SUBSCREVER",
    "errorFullName": "Indique o seu nome completo.",
    "errorCouldNotCreate": "Não foi possível criar o pagamento. Tente novamente. CÓDIGO:1"
  },
  "chatOddsIntro": {
    "chatOdds": "Probabilidades de conversação",
    "wantToTrackAnother": "Pretende seguir outro contacto até\npara ver se podem estar a conversar\njuntos?",
    "later": "Mais tarde",
    "yes": "Sim"
  },
  "noContacts": {
    "noContacts": "Sem contactos",
    "itSeems": "Parece que ainda não tem\ncontactos seguidos...",
    "willBeRecorded": "A partir de\nde agora, serão registadas informações online para os contactos seleccionados.\nSeleccione o primeiro contacto a seguir.",
    "addContact": "Adicionar contacto a seguir"
  },
  "qrScan": {
    "enterCode": "Introduzir código de início de sessão",
    "followInstructions": "Siga as instruções para digitalizar este\ncódigo QR no seu dispositivo principal.",
    "enjoy": "Desfrute da utilização do ChatWatch!",
    "canClose": "Pode fechar esta janela",
    "openUrl": "<0>Deve abrir este URL\na partir de <3>OUTRO</3> dispositivo</0>",
    "updatingServices": "Estamos a atualizar os nossos serviços,\npor favor recarregue a página e tente novamente.",
    "msgConnecting1": "A ligar ao WA... Aguarde...",
    "msgConnecting2": "A sincronizar a conta. Aguarde...",
    "msgConnecting3": "A obter informações...Aguarde...",
    "msgConnecting4": "Sessão inicializada."
  },
  "resetPassword": {
    "resetPassword": "Redefinir palavra-passe",
    "errorPasswordEmpty": "Por favor, defina uma palavra-passe",
    "errorPasswordShort": "A palavra-passe deve ter no mínimo 8 caracteres",
    "errorPasswordNotMatch": "As palavras-passe não são iguais",
    "errorLinkNotValid": "Ligação não válida",
    "newPassword": "Nova palavra-passe",
    "repeatNewPassword": "Repetir a nova palavra-passe",
    "passwordReseted": "Palavra-passe redefinida"
  },
  "thankYou": {
    "thankYou": "Obrigado\npela sua subscrição!",
    "continue": "CONTINUAR"
  },
  "waLogin": {
    "privacyNotice": "Aviso de privacidade",
    "weNotStore": "Não armazenamos, lemos ou\nacedemos a quaisquer dados da conta WA.",
    "next": "Seguinte",
    "howToLogin": "Como iniciar sessão?",
    "howToLogin1": "Encontrar um computador, tablet, telemóvel ou Smart TV\nVisita",
    "howToLogin2": "Introduzir o seguinte código",
    "link": "Ligar a conta WA",
    "link1": "Abrir as definições WA tocando neste ícone.",
    "link2": "Selecionar dispositivos ligados.",
    "link3": "Quando a câmara estiver activada, aponte o seu telemóvel\npara ler o código QR",
    "connecting": "LIGAÇÃO",
    "somethingWrong": "Algo correu mal.\nPor favor, recarregue esta página e tente novamente.",
    "msgConnecting1": "A ligar ao WA... Aguarde...",
    "msgConnecting2": "A sincronizar a conta. Por favor, aguarde...",
    "msgConnecting3": "Buscando informações...Aguarde...",
    "msgConnecting4": "Sessão inicializada."
  },
  "main": {
    "connectionManager": "Gerenciador de conexões",
    "trackedContacts": "Contatos rastreados",
    "contactTrackingActive": "<0>O rastreamento de contatos está <1>ativo</1>.<br/>Você pode desativar o rastreamento para aparecer off-line. Mais tarde, você pode ativá-lo com um clique. Não há necessidade de ler o código QR novamente.</0>",
    "contactTrackingInactive": "<0>O rastreamento de contatos está <1>inativo</1>.</0>",
    "deactivateTracking": "Desativar rastreamento",
    "addContact": "Adicionar contato para rastrear",
    "activateTracking": "Ative o rastreamento!",
    "contactCard": {
      "notTracking": "Não rastreando",
      "howToTrack": "Como rastrear contato?",
      "onlineNotification": "Notificação on-line",
      "onlineHistory": "História on-line",
      "chatODDS": "Bate-papo OODS",
      "nightWatch": "Vigília noturna",
      "delete": "Excluir",
      "lastSeen": {
        "online": "On-line",
        "secsAgo": "{{count}} segundos atrás",
        "secsAgo_plural": "{{count}} segundos atrás",
        "minsAgo": "{{count}} minutos atrás",
        "minsAgo_many": "{{count}} minutos atrás",
        "hoursAgo": "{{count}} hora atrás",
        "hoursAgo_plural": "{{count}} horas atrás",
        "daysAgo": "{{count}} dia atrás",
        "daysAgo_plural": "{{count}} dias atrás"
      }
    }
  },
  "subscriptionDetails": {
    "subscriptionDetails": "Detalhes da assinatura",
    "feature1": "Rastreie até {{count}} contatos!",
    "feature2": "História on-line",
    "feature3": "Informações sobre os contactos",
    "feature4": "Probabilidade de chat treinada por IA",
    "feature5": "Equipa de apoio (e-mail)",
    "hasBeenCancelled": "<0>Esta subscrição foi <1>cancelada</1> e o seu cartão não voltará a ser debitado.</0>",
    "accessWillEndOn": "O seu acesso terminará em",
    "nextPaymentDate": "Próxima data de pagamento",
    "cancelPlan": "Cancelar plano",
    "howToCancel": "Como cancelar o seu plano?",
    "howToCancelText": "Inicie sessão na sua conta PayPal.\nClique em Ferramentas e, em seguida, seleccione Todas as ferramentas na parte superior da página.\nEm seguida, seleccione Cobranças recorrentes na página Ferramentas.\nPode cancelar a sua subscrição aqui.",
    "cancelSubscription": "Cancelar subscrição",
    "weAreSorryToEar": "Lamentamos saber que pretende cancelar a subscrição. Há algum problema de serviço ou de qualidade?\nGostaria de nos enviar uma mensagem de correio eletrónico para possíveis resoluções?\nCaso contrário, clique no botão Cancelar subscrição.",
    "keepSubscription": "Manter a minha subscrição"
  },
  "addNumber": {
    "selectContactToCompare": "Selecionar um contacto\npara comparar",
    "selectContactToTrack": "Selecionar um contacto\npara seguir",
    "searchBy": "Procurar por telefone ou nome",
    "noResultsFor": "Sem resultados para",
    "trySearchingByPhone": "Tentar pesquisar por número de telefone"
  },
  "onlineHistory": {
    "onlineHistory": "Histórico online",
    "last24Hours": "Últimas 24 horas",
    "selectADate": "Selecionar uma data",
    "time": "Hora",
    "offlineFor": "Offline por",
    "hour": "hora",
    "hour_plural": "horas",
    "from": "de",
    "to": "até",
    "notEnoughtData": "Não há dados suficientes, por favor volte mais tarde",
    "changeDate": "ALTERAR DATA"
  },
  "compareWith": {
    "selectContactToCompareWith": "Selecionar um contacto para\ncomparar com",
    "searchBy": "Procurar por telefone ou nome"
  },
  "chatProbability": {
    "chatProbability": "Probabilidade de conversação",
    "last24Hours": "Últimas 24 horas",
    "selectADate": "Selecionar uma data",
    "time": "Tempo",
    "score": "Pontuação",
    "offlineFor": "Offline por",
    "hour": "hora",
    "hour_plural": "horas",
    "from": "de",
    "to": "para",
    "notEnoughtData": "Não há dados suficientes, por favor volte mais tarde",
    "changeDate": "ALTERAR DATA"
  },

  nightWatch: {
    nightWatch: 'Vigília noturna',
    sleptFor: 'DORMI POR {{count}} HORAS NO TOPO',
    sleptFor_plural: 'DORMI POR {{count}} HORAS NO MÁXIMO',
    owlAlert: 'Alerta de coruja',
    highestChatProbability: 'Maior probabilidade de bate-papo',
    minutes: 'minuto',
    minutes_plural: 'minutos'
  }
}