import {createAction} from '@reduxjs/toolkit';
import { Subscription, UserInfo } from './AuthState';

export const setSubscription = createAction<Subscription>('setSubscription');
export const setAccessToken = createAction<string>('setAccessToken');
export const setOnlineStatus = createAction<string>('setOnlineStatus');
export const setAuthError = createAction<string | null>('setAuthError');
export const setIsWALoggedIn = createAction<boolean>('setIsWALoggedIn');
export const setUserInfo = createAction<UserInfo>('setUserInfo');
export const setFirebaseTokenSentToServer = createAction<boolean>('setFirebaseTokenSentToServer');
export const setTempLoginCode = createAction('setTempLoginCode')
export const clearAllAuth = createAction('clearAllAuth')