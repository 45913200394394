import {createReducer} from '@reduxjs/toolkit';
import { NavigationState } from './NavigationState';
import { setCurrentScreen, setNavigationState } from './navigation.actions';

const initialState: NavigationState = {
  state: {}
};

export const navigationReducer = createReducer(initialState, builder => {
  builder.addCase(setNavigationState, (state, action) => {
    return { ...state, state: action.payload};
  });
  builder.addCase(setCurrentScreen, (state, action) => {
    console.log("CURRENT SCREEN:",action.payload)
    return { ...state, currentScreen: action.payload};
  });
});
