export const hi = {
  "app.name": "चैटवॉच",
  "language": "एन",
  "slogan": "मूल व्हाट्सएप\nऑनलाइन ट्रैकर",
  "goBack": "वापस जाओ",
  "menu": {
    "home": "घर",
    "subscription": "अंशदान",
    "logOut": "लॉग आउट"
  },
  "login": {
    "login": "लॉग इन करें",
    "logIn": "लॉग इन करें",
    "email": "ईमेल",
    "password": "पासवर्ड",
    "forgotPassword": "पासवर्ड भूल गए?",
    "dontHaveAccount": "कोई खाता नहीं है?",
    "register": "पंजीकरण करवाना",
    "errorValidEmail": "कृपया एक वैध ईमेल सेट करें",
    "errorPasswordEmpty": "कृपया एक पासवर्ड सेट करें"
  },
  "register": {
    "register": "पंजीकरण करवाना",
    "email": "ईमेल",
    "password": "पासवर्ड",
    "repeatPassword": "पासवर्ड दोहराएं",
    "alreadyHaveAccount": "क्या आपके पास पहले से एक खाता मौजूद है?",
    "logIn": "लॉग इन करें",
    "errorValidEmail": "कृपया एक वैध ईमेल सेट करें",
    "errorPasswordEmpty": "कृपया एक पासवर्ड सेट करें",
    "errorPasswordShort": "पासवर्ड न्यूनतम 8 अक्षर का होना चाहिए",
    "errorPasswordNotMatch": "पासवर्ड समान नहीं हैं"
  },
  "recoverPassword": {
    "recoverPassword": "पासवर्ड की वसूली",
    "errorValidEmail": "कृपया एक वैध ईमेल सेट करें",
    "email": "ईमेल",
    "emailSent": "ईमेल भेजा"
  },
  "buySubscription": {
    "subscription": "अंशदान",
    "week": "सप्ताह",
    "month": "महीना",
    "year": "वर्ष",
    "weekly": "साप्ताहिक",
    "monthly": "महीने के",
    "yearly": "वार्षिक",
    "startYourTrial": "अपना 3 दिवसीय निःशुल्क परीक्षण प्रारंभ करें\nसदस्यता चुनें",
    "selectPlan": "प्लान का चयन करें",
    "selectedPlan": "चयनित योजना",
    "payWith": "के साथ भुगतान करें",
    payWithCard: 'कार्ड से भुगतान करें',
    "card": "कार्ड",
    "fullName": "पूरा नाम",
    "subscribe": "सदस्यता लें",
    "errorFullName": "अपना पूरा नाम दें।",
    "errorCouldNotCreate": "भुगतान नहीं बनाया जा सका. पुनः प्रयास करें। कोड:1"
  },
  "chatOddsIntro": {
    "chatOdds": "चैट ऑड्स",
    "wantToTrackAnother": "क्या आप यह देखने के लिए किसी अन्य संपर्क को ट्रैक करना चाहते हैं कि क्या वे\nएक साथ चैट कर रहे होंगे?",
    "later": "बाद में",
    "yes": "हाँ"
  },
  "noContacts": {
    "noContacts": "कोई संपर्क नहीं",
    "itSeems": "ऐसा लगता है कि आपने अभी तक संपर्कों को ट्रैक नहीं किया है...",
    "willBeRecorded": "चयनित संपर्कों के लिए अब से ऑनलाइन जानकारी दर्ज की जाएगी।\nट्रैक करने के लिए पहला संपर्क चुनें।",
    "addContact": "ट्रैक में संपर्क जोड़ें"
  },
  "qrScan": {
    "enterCode": "लॉगिन कोड दर्ज करें",
    "followInstructions": "अपने मुख्य डिवाइस पर इस\nक्यूआर कोड को स्कैन करने के लिए निर्देशों का पालन करें।",
    "enjoy": "चैटवॉच का उपयोग करके आनंद लें!",
    "canClose": "आप इस विंडो को बंद कर सकते हैं",
    "openUrl": "<0>आपको यह यूआरएल\n<3>किसी अन्य</3> डिवाइस</0> से खोलना होगा",
    "updatingServices": "हम अपनी सेवाएं अपडेट कर रहे हैं,\nकृपया पृष्ठ पुनः लोड करें और पुनः प्रयास करें।",
    "msgConnecting1": "WA से कनेक्ट हो रहा है...कृपया प्रतीक्षा करें...",
    "msgConnecting2": "खाता सिंक हो रहा है. कृपया प्रतीक्षा करें...",
    "msgConnecting3": "जानकारी लायी जा रही है...कृपया प्रतीक्षा करें...",
    "msgConnecting4": "सत्र प्रारंभ हुआ."
  },
  "resetPassword": {
    "resetPassword": "पासवर्ड रीसेट",
    "errorPasswordEmpty": "कृपया एक पासवर्ड सेट करें",
    "errorPasswordShort": "पासवर्ड न्यूनतम 8 अक्षर का होना चाहिए",
    "errorPasswordNotMatch": "पासवर्ड समान नहीं हैं",
    "errorLinkNotValid": "लिंक मान्य नहीं है",
    "newPassword": "नया पासवर्ड",
    "repeatNewPassword": "नया पासवर्ड दोहराएँ",
    "passwordReseted": "पासवर्ड रीसेट हो गया"
  },
  "thankYou": {
    "thankYou": "आपकी सदस्यता के लिए धन्यवाद {n}!",
    "continue": "जारी रखना"
  },
  "waLogin": {
    "privacyNotice": "गोपनीयता सूचना",
    "weNotStore": "हम किसी भी WA खाते के डेटा को संग्रहीत, पढ़ते या एक्सेस नहीं करते हैं।",
    "next": "अगला",
    "howToLogin": "कैसे लॉगिन करें?",
    "howToLogin1": "कंप्यूटर, टैबलेट, फ़ोन या स्मार्ट टीवी ढूंढें\nपर जाएँ",
    "howToLogin2": "निम्नलिखित कोड दर्ज करें",
    "link": "WA खाता लिंक करें",
    "link1": "इस आइकन पर टैप करके WA सेटिंग खोलें।",
    "link2": "लिंक किए गए डिवाइस का चयन करें.",
    "link3": "जब कैमरा सक्रिय हो, तो QR कोड को स्कैन करने के लिए अपने\nफ़ोन को इंगित करें",
    "connecting": "कनेक्ट",
    "somethingWrong": "कुछ ग़लत हो गया है.\nकृपया, इस पृष्ठ को पुनः लोड करें और पुनः प्रयास करें।",
    "msgConnecting1": "WA से कनेक्ट हो रहा है...कृपया प्रतीक्षा करें...",
    "msgConnecting2": "खाता सिंक हो रहा है. कृपया प्रतीक्षा करें...",
    "msgConnecting3": "जानकारी लायी जा रही है...कृपया प्रतीक्षा करें...",
    "msgConnecting4": "सत्र प्रारंभ हुआ."
  },
  "main": {
    "connectionManager": "कनेक्शन प्रबंधक",
    "trackedContacts": "संपर्कों को ट्रैक किया गया",
    "contactTrackingActive": "<0>संपर्क ट्रैकिंग <1>सक्रिय है</1>।<br/>आप ऑफ़लाइन दिखने के लिए ट्रैकिंग को निष्क्रिय कर सकते हैं। बाद में आप इसे एक क्लिक से एक्टिवेट कर सकते हैं. QR कोड को दोबारा स्कैन करने की कोई आवश्यकता नहीं है।</0>",
    "contactTrackingInactive": "<0>संपर्क ट्रैकिंग <1>निष्क्रिय</1> है।</0>",
    "deactivateTracking": "ट्रैकिंग निष्क्रिय करें",
    "addContact": "ट्रैक करने के लिए संपर्क जोड़ें",
    "activateTracking": "ट्रैकिंग सक्रिय करें!",
    "contactCard": {
      "notTracking": "ट्रैकिंग नहीं",
      "howToTrack": "संपर्क कैसे ट्रैक करें?",
      "onlineNotification": "ऑनलाइन अधिसूचना",
      "onlineHistory": "ऑनलाइन इतिहास",
      "chatODDS": "OODS चैट करें",
      "nightWatch": "रात का चोरपहरा",
      "delete": "मिटाना",
      "lastSeen": {
        "online": "ऑनलाइन",
        "secsAgo": "{{count}} एक सेकंड पहले",
        "secsAgo_plural": "{{count}} सेकंड पहले",
        "minsAgo": "{{count}} मिनट पहले",
        "minsAgo_many": "{{count}} मिनट पहले",
        "hoursAgo": "{{count}} घंटा पहले",
        "hoursAgo_plural": "{{count}} घंटे पहले",
        "daysAgo": "{{count}} दिन पहले",
        "daysAgo_plural": "{{count}} दिन पहले"
      }
    }
  },
  "subscriptionDetails": {
    "subscriptionDetails": "सदस्यता विवरण",
    "feature1": "{{count}} तक संपर्कों को ट्रैक करें!",
    "feature2": "ऑनलाइन इतिहास",
    "feature3": "संपर्क अंतर्दृष्टि",
    "feature4": "एआई प्रशिक्षित चैट संभावना",
    "feature5": "सहायता टीम (ईमेल)",
    "hasBeenCancelled": "<0>यह सदस्यता <1>रद्द कर दी गई है</1> और आपके कार्ड से दोबारा शुल्क नहीं लिया जाएगा।</0>",
    "accessWillEndOn": "आपकी पहुंच समाप्त हो जाएगी",
    "nextPaymentDate": "अगली भुगतान तिथि",
    "cancelPlan": "योजना रद्द करें",
    "howToCancel": "अपना प्लान कैंसिल कैसे करें?",
    "howToCancelText": "अपने पेपल अकाउंट में लॉगिन करें।\nटूल्स पर क्लिक करें, फिर पृष्ठ के शीर्ष पर सभी टूल्स का चयन करें।\nफिर टूल पेज से आवर्ती भुगतान चुनें।\nआप वहां अपनी सदस्यता रद्द कर सकते हैं।",
    "cancelSubscription": "सदस्यता रद्द",
    "weAreSorryToEar": "हमें यह जानकर दुख हुआ कि आप रद्द करना चाहते हैं। क्या सेवा या गुणवत्ता संबंधी कोई समस्या है?\nक्या आप संभावित समाधानों के लिए हमें एक ईमेल भेजना चाहेंगे?\nयदि नहीं, तो सदस्यता रद्द करें बटन पर क्लिक करें।",
    "keepSubscription": "मेरी सदस्यता बनाए रखें"
  },
  "addNumber": {
    "selectContactToCompare": "तुलना करने के लिए संपर्क\nचुनें",
    "selectContactToTrack": "ट्रैक करने के लिए संपर्क\nचुनें",
    "searchBy": "फ़ोन या नाम से खोजें",
    "noResultsFor": "के लिए कोई परिणाम नहीं",
    "trySearchingByPhone": "फ़ोन नंबर द्वारा खोजने का प्रयास करें"
  },
  "onlineHistory": {
    "onlineHistory": "ऑनलाइन इतिहास",
    "last24Hours": "पिछले 24 घंटे",
    "selectADate": "एक तारीख चुनें",
    "time": "समय",
    "offlineFor": "के लिए ऑफ़लाइन",
    "hour": "घंटा",
    "hour_plural": "घंटे",
    "from": "से",
    "to": "को",
    "notEnoughtData": "पर्याप्त डेटा नहीं, कृपया बाद में वापस आएं",
    "changeDate": "बदलाव दिनांक"
  },
  "compareWith": {
    "selectContactToCompareWith": "से तुलना करने के\nलिए एक संपर्क चुनें",
    "searchBy": "फ़ोन या नाम से खोजें"
  },
  "chatProbability": {
    "chatProbability": "चैट की संभावना",
    "last24Hours": "पिछले 24 घंटे",
    "selectADate": "एक तारीख चुनें",
    "time": "समय",
    "score": "अंक",
    "offlineFor": "के लिए ऑफ़लाइन",
    "hour": "घंटा",
    "hour_plural": "घंटे",
    "from": "से",
    "to": "को",
    "notEnoughtData": "पर्याप्त डेटा नहीं, कृपया बाद में वापस आएं",
    "changeDate": "बदलाव दिनांक"
  },

  nightWatch: {
    nightWatch: 'रात का चोरपहरा',
    sleptFor: 'अधिकतम {{गिनती}} घंटे तक सोएं',
    sleptFor_plural: 'अधिकतम {{गिनती}} घंटों तक सोएं',
    owlAlert: 'उल्लू चेतावनी',
    highestChatProbability: 'उच्चतम चैटिंग संभावना',
    minutes: 'मिनट',
    minutes_plural: 'मिनट'
  }
}