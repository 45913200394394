import React, { useCallback, useState } from 'react';
import { Image, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { Platform as PlatformEnum } from '../shared/constants';
import { getDeviceLanguage, validateEmail } from '../shared/helpers';
import { globalStyles } from '../shared/styles';
import { setAccessToken, setSubscription } from '../store/auth/auth.actions';
import { LogoAndSlogan } from '../components/auth/logo-and-slogan';
import * as mixpanel from 'mixpanel-browser';

export const RegisterScreen = () => {

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const authService = useAuthService()
  const router = useRouter()
  const route = useRoute()

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [password2, setPassword2] = useState<string>("")
  const [error, setError] = useState<string>(null)
  const [campaignId, setCampaignId] = useState<string>(null)

  const register = useCallback(async () => {
    if (!validateEmail(email)) {
      return setError( t('register.errorValidEmail') );
    }
    if (!password) {
      return setError( t('register.errorPasswordEmpty') );
    }
    if (password.length < 8) {
      return setError( t('register.errorPasswordShort') );
    }
    if (password != password2) {
      return setError( t('register.errorPasswordNotMatch') );
    }
    setError(null)
    const payload: any = {
      email,
      password,
      language: getDeviceLanguage(),
      timezoneOffset: new Date().getTimezoneOffset(),
      platform: PlatformEnum.WEB,
      campaignId
    };
    console.log(payload)
    http.post("auth/register", payload)
      .then(response => {
        try{
          mixpanel.track('Signup')
        }catch(e){}
        dispatch(setAccessToken(response.data.accessToken))
        dispatch(setSubscription(response.subscription))
      })
      .catch( e => setError(e))
  }, [email, password, password2, setError, campaignId])

  const goToLogin = useCallback(() => {
    navigation.navigate('Login' as never)
  }, [navigation])

  useFocusEffect(useCallback(() => {
    router.notFunctionalGuard({from:"Register"})
    let campaignIdCookie = getCookie('campaignId')
    if( !!campaignIdCookie || (route.params as any)?.campaignId){
      let campaignId = (route.params as any)?.campaignId || campaignIdCookie;
      setCampaignId( campaignId )
    }
  }, [authService.isLoggedIn])
  )

  return <View style={globalStyles.screenBase} >
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', flexGrow: 1, padding: 24, maxWidth: 600, alignSelf: "center" }} >
      <LogoAndSlogan/>

      <Text style={[ globalStyles.baseText, globalStyles.fontFamilyBold, { marginTop: 40, textAlign: 'center', fontSize: 18, textTransform: 'uppercase' }]}>{t('register.register')}</Text>

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'email-address'}
        textContentType={'emailAddress'}
        autoCapitalize={'none'}
        autoComplete={'email'}
        autoCorrect={false}
        placeholder={t('register.email')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setEmail(text)}
        value={email}
      />

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'default'}
        textContentType={'password'}
        autoCapitalize={'none'}
        autoComplete={'password'}
        autoCorrect={false}
        secureTextEntry={true}
        placeholder={t('register.password')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setPassword(text)}
        value={password}
      />

      <TextInput
        style={{ ...globalStyles.input, marginTop: 14 }}
        keyboardType={'default'}
        textContentType={'password'}
        autoCapitalize={'none'}
        autoComplete={'password'}
        autoCorrect={false}
        secureTextEntry={true}
        placeholder={t('register.repeatPassword')}
        placeholderTextColor={globalStyles.placeholderText.color}
        onChangeText={(text) => setPassword2(text)}
        value={password2}
      />

      {!!error && <View style={{ width: "100%", marginTop: 16, borderRadius: 10 }}>
        <Text style={[ globalStyles.fontFamily, { color: 'rgba(255,53,53,0.8)', fontSize: 18, textAlign: "center" }]}>{error}</Text>
      </View>}

    </View>
    <View style={{ padding: 24, paddingBottom: 30, maxWidth: 600, alignSelf: "center", width: "100%" }}>
      <TouchableOpacity style={[globalStyles.primaryButton, { marginBottom: 20 }]} onPress={register}>
        <Text style={globalStyles.primaryButtonText}>{t('register.register')}</Text>
      </TouchableOpacity>

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "center" }}>
        <Text style={[globalStyles.baseText, { fontSize: 18, marginRight: 10, opacity: 0.5 }]}>{ t('register.alreadyHaveAccount') }</Text>
        <TouchableOpacity onPress={() => goToLogin()}>
          <Text style={[globalStyles.baseText, { fontSize: 18, fontWeight: "700" }]}>{ t('register.logIn') }</Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>

}

function getCookie(name) {
  return (name = (document.cookie + ';').match(new RegExp(name + '=.*;'))) && name[0].split(/=|;/)[1];
}