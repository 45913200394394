import moment from "moment"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Image, StyleSheet, Text, View } from "react-native"
import { useSelector } from "react-redux"
import { HighestChatProbabilities } from "../../screens/nightwatch"
import { InsightsEmojis } from "../../shared/constants"
import { Insight } from "../../shared/interfaces"
import { globalStyles } from "../../shared/styles"
import { RootState } from "../../store"
import { ContactAvatarWithStatus } from "../contact-avatar-with-status"


export const InsightCard = ({ insight, highestChatProbabilities }: { insight: Insight, highestChatProbabilities: HighestChatProbabilities[] }) => {

    const subscribed = useSelector((state: RootState) => state.agenda.subscribed)
    const {t} = useTranslation()

    const formatDate = (date: Date) => {
        return moment(date).format("dd MMM DD").replace('.', '').split(" ").map(w => w[0].toUpperCase() + w.slice(1)).join(" ")
    }

    const getContactFor = useCallback((phone: string) => {
        return subscribed.find(s => s.phone == phone)
    }, [subscribed])

    return (
        <View style={[{ backgroundColor: globalStyles.mainCardBackground.backgroundColor, borderRadius: 10, padding: 15, overflow: "hidden", maxHeight: "100%", marginBottom: 24 }]}>

            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ width: 55, height: 55, borderRadius: 30, backgroundColor: "white", justifyContent: "center", alignItems: "center" }}>
                    { /*<Image source={ InsightsImages[ insight.iconSrc ] } style={{ width: 39, height: 39 }} /> */}
                    <Text style={{ fontSize: 36 }} >{InsightsEmojis[insight.iconSrc]}</Text>
                </View>
                <View style={{ flexGrow: 1, paddingLeft: 10 }}>
                    <Text style={[globalStyles.fontFamilyBold, globalStyles.baseText, { fontSize: 14, color: 'white' }]}>{formatDate(insight.wakeUp.date).toUpperCase()}</Text>
                    <Text style={[globalStyles.fontFamily, globalStyles.baseText, { fontSize: 12, marginTop: 4, color: 'white' }]}>{t('nightWatch.sleptFor',{ count: insight.hoursSleep })}</Text>
                </View>
            </View>

            <View style={{ flexDirection: "row", alignItems: "center", marginTop: 6 }}>
                <View style={{ flexGrow: 1, alignItems: "center" }}>
                    <Image source={require('../../../assets/insights/moon.png')} style={{ width: 27, height: 27 }} />
                    <Text style={[globalStyles.fontFamily, globalStyles.baseText, { fontSize: 12, marginTop: 5, color: 'white' }]}>{formatDate(insight.sleep.date)}</Text>
                    <Text style={[globalStyles.fontFamilyBold, globalStyles.baseText, { fontSize: 14, paddingTop: 10, color: 'white' }]}>{insight.sleep.rhour}</Text>
                </View>
                <View style={{ width: 27, height: 1, backgroundColor: "white", marginHorizontal: 16 }} />
                <View style={{ flexGrow: 1, alignItems: "center" }}>
                    <Image source={require('../../../assets/insights/sun.png')} style={{ width: 27, height: 27 }} />
                    <Text style={[globalStyles.fontFamily, globalStyles.baseText, { fontSize: 12, marginTop: 5, color: 'white' }]}>{formatDate(insight.wakeUp.date)}</Text>
                    <Text style={[globalStyles.fontFamilyBold, globalStyles.baseText, { fontSize: 14, marginTop: 10, color: 'white' }]}>{insight.wakeUp.rhour}</Text>
                </View>
            </View>

            {!!insight.nightOwl.length && <View style={{ paddingTop: 7, paddingBottom: 20, paddingHorizontal: 23, backgroundColor: "white", borderRadius: 10, marginTop: 17 }}>
                <Text style={[globalStyles.fontFamilyBold, globalStyles.baseText, { fontSize: 14, alignSelf: "center", color: "black", marginBottom: 12 }]}>{t('nightWatch.owlAlert')}</Text>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Image source={require('../../../assets/images/nighwatch.png')} style={{ width: 64, height: 69, tintColor: '#1F024A' }} />
                    <View style={{ alignItems: "center", marginLeft: 28 }}>
                        {insight.nightOwl.map((item, i) => (
                            <View key={`night_owl_i_${i}`} style={{ flexDirection: "row", alignItems: "center", marginVertical: 6 }} >
                                <Text style={[globalStyles.baseText, { fontSize: 12, color: "black", width: 58, textAlign: "right", fontWeight: "500" }]}>{item.startHour}</Text>
                                <View style={{ width: 27, height: 1, backgroundColor: "black", marginHorizontal: 5 }} />
                                <Text style={[globalStyles.baseText, { fontSize: 12, color: "black", fontWeight: "500" }]}>{Math.ceil(item.duration / 60)} {t('nightWatch.minutes',{count:Math.ceil(item.duration / 60)})}</Text>
                            </View>
                        ))}
                    </View>
                </View>
            </View>}

            {!!highestChatProbabilities.length && <View style={{ paddingTop: 7, paddingBottom: 16, paddingHorizontal: 23, backgroundColor: "white", borderRadius: 10, marginTop: 17 }}>
                <Text style={[globalStyles.fontFamilyBold, globalStyles.baseText, { fontSize: 14, alignSelf: "center", color: "black", marginBottom: 8 }]}>{t('nightWatch.highestChatProbability')}</Text>
                {highestChatProbabilities.map((item, i) => (
                    <View key={`highestChatProbabilities_i_${i}`} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginVertical: 4 }}>
                        <ContactAvatarWithStatus contact={getContactFor(item.phone)} width={60} />
                        <View style={{ alignItems: "center", marginLeft: 20 }}>
                            <View style={{ alignItems: "center", marginVertical: 6 }} >
                                <Text style={[globalStyles.baseText, { fontSize: 12, color: "black", textAlign: "center", marginBottom: 3 }]}>{getContactFor(item.phone).name}</Text>
                                <View style={[{ flexDirection: 'row', marginRight: 3 }]}>
                                    <View style={[globalStyles.scoreDot, item.score > 0 ? globalStyles.scoreDotActive : null]} />
                                    <View style={[globalStyles.scoreDot, item.score > 1 ? globalStyles.scoreDotActive : null]} />
                                    <View style={[globalStyles.scoreDot, item.score > 2 ? globalStyles.scoreDotActive : null]} />
                                    <View style={[globalStyles.scoreDot, item.score > 3 ? globalStyles.scoreDotActive : null]} />
                                    <View style={[globalStyles.scoreDot, item.score > 4 ? globalStyles.scoreDotActive : null]} />
                                </View>
                                <Text style={[globalStyles.baseText, { fontSize: 12, color: "black", fontWeight: "500" }]}>{item.time}</Text>
                            </View>
                        </View>
                    </View>
                ))}
            </View>}


        </View>
    )
}

const styles = StyleSheet.create({
    subheaderText: {
        ...globalStyles.fontFamily,
        fontSize: 12,
        fontWeight: "700",
        textAlign: 'center',
        color: "white"
    },
})