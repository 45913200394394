import { RootState } from "."

export const migrations = {
  0: state => {
    let stateCopy = { ...state }
    return stateCopy
  },
  1: state => {
    let stateCopy = { ...state }
    stateCopy.ui.telegramBotLink = null
    return stateCopy
  }
}