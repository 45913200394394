import { HeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from 'react';
import { Image, Pressable, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { APP, CURRENT_APP } from '../shared/constants';
import { globalStyles } from '../shared/styles';
import { store } from '../store';
import { ImageAspectFit } from './image';
import { setShowDrawer } from '../store/ui/ui.actions';
import { isApp } from '../shared';
import { useAuthService } from '../services/auth-service';
import { useFirebase } from '../services/firebase-service';

interface HeaderProps {
  canGoBack?: boolean;
  title?: string,
  style?: StyleProp<TextStyle>
  barStyle?: StyleProp<ViewStyle>
}

export const HeaderComponent = (props: HeaderProps) => {

  const navigation = useNavigation()
  const auth = useAuthService()
  const firebase = useFirebase()

  const _openDrawer = () => {
    console.log("openDrawer")
    store.dispatch(setShowDrawer(true))
  }

  const renderLogo = useCallback(() => {
    if (CURRENT_APP == APP.WAWATCHER) {
      return <Image source={require('../../assets/images/wa-watcher-icon.png')} resizeMode='contain' style={{ width: 31, height: 31 }} />
    } else if (CURRENT_APP == APP.CHATWATCH) {
      return <Image source={require('../../assets/images/logoblanco.png')} resizeMode='contain' style={{ width: 36, height: 36, alignSelf: 'flex-start' }} />
    } else if (CURRENT_APP == APP.WAPATROL) {
      return (
        <View style={{ flexDirection: 'row' }} >
          <ImageAspectFit asset={require('../../assets/images/double-check.png')} style={{ width: 31, height: 31 }} />
          <Text style={{ fontWeight: '800', color: 'white', fontSize: 24, marginLeft: 10 }}>WA PATROL</Text>
        </View>
      )
    }
  }, [CURRENT_APP])

  return (<View style={[globalStyles.header, props.barStyle || {}, firebase.isInstalled ? { height: 110, paddingTop: 34 } : {}]} >
    {props.canGoBack && navigation.canGoBack() ?
      <HeaderBackButton tintColor='white' onPress={navigation.goBack} />
      :
      ( auth.isWALoggedIn ? <Pressable onPress={() => navigation.navigate("Main" as never)} >
        <View style={{ height: "100%", justifyContent: "center", width: 50, alignItems: "flex-start" }}>
          {props.title && renderLogo()}
        </View>
      </Pressable> : <View style={{ width: 50 }}/> )
    }
    <View style={styles.grow}>
      {props.title &&
        <Text style={[globalStyles.headerTitle,props.style || {}]}>{props.title}</Text>
      }
      {!props.title && isApp(APP.WAWATCHER) &&
        <Image source={require('../../assets/images/logo-horizontal.png')} resizeMode='contain' style={{ width: 200, height: 42 }} />
      }
    </View>
    <TouchableOpacity onPress={() => _openDrawer()} style={[globalStyles.iconLeft, { width: 50 }]} disabled={false} >
      <ImageAspectFit asset={require('../../assets/images/menu-icon-new.png')} style={{ width: 30, height: 30 }} imageStyle={{ tintColor: globalStyles.baseText.color }} />
    </TouchableOpacity>

  </View>
  );
}

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
});
