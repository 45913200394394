import { RootStackParamList } from "./root-stack-param-list";

interface Linking{
  prefixes: any[]
  config: {
    screens: { [key in keyof RootStackParamList]: string}
  }
}

export const linking: Linking = {
  prefixes: [],
  config: {
    screens: {
      Main: 'home',
      Login: 'login',
      Register: 'register',
      RecoverPassword: 'recover-password',
      ResetPassword: 'reset-password',
      BuySubscription: 'buy-subscription',
      ThankYou: 'thank-you',
      WALogin: 'wa-login',
      QRScan: 'qr-scan',
      AddNumber: 'add-number',
      NoContacts: 'no-contacts',
      ChatOddsIntro: 'chat-odds-intro',
      OnlineHistory: "online-history",
      Nightwatch: "nightwatch",
      CompareWith: "compare-with",
      ChatProbability: "chat-probability",
      SubscriptionDetails: "subscription-details"
    }
  },
};