import {createAction} from '@reduxjs/toolkit';
import { AppStateStatus } from 'react-native';

export const setIsLoading = createAction<boolean>('setIsLoading');
export const setShowDrawer = createAction<boolean>('setShowDrawer');
export const setIsFirstLoaded = createAction<boolean>('setIsFirstLoaded');
export const setTelegramBotLink = createAction<string>('setTelegramBotLink');
export const setTogglingOnlineStatus = createAction<boolean>('setTogglingOnlineStatus');
export const setAppState = createAction<AppStateStatus>('setAppState');
export const setSocketIOConnected = createAction<boolean>('setSocketIOConnected');
export const setIsFirstScreen = createAction<boolean>('setIsFirstScreen');
