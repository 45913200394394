import AsyncStorage from '@react-native-async-storage/async-storage';
import { LZString } from '../shared/LZString';
import { NumberStatus } from '../shared/constants';
import { http } from './http-service';
import { store } from '../store';
import { setContacts, setSubscribed } from '../store/agenda/agenda.actions';
import { Contact } from '../shared/interfaces';

class NumbersService {

    public async getContacts({ forceBackend = false } = {}) {
        try {
            console.log("getContacts")
            let contacts: Contact[] = store.getState().agenda.contacts
            if( !contacts.length ) forceBackend = true;
            console.log("forceBackend",forceBackend)
            const promises = [
                http.get("number/get-subscribed?rand="+Math.random())
            ]
            if( forceBackend ){
                promises.push( http.get("number/get-contacts") )
            }
            const [subscribedResponse,contactsResponse]: any = await Promise.all(promises);
            if( !!contactsResponse ){
                if (contactsResponse.data.error || subscribedResponse.data.error) return;
                const compressed = contactsResponse.data.responseData;
                const decomp = LZString.decompressFromUTF16(compressed);

                contacts = (Object.values(JSON.parse(decomp)) as any[]).map((contact: Contact) => ({
                    id: contact.id,
                    phone: contact.phone,
                    name: contact.name || "No name available",
                } as Contact)).sort( (c1,c2) => c1.name.toLowerCase() < c2.name.toLowerCase() ? -1 : 1 )
            }

            const subscribedNumbers = subscribedResponse?.data.numbers || [];
            const notifications = subscribedResponse.data?.notifications || {};
            const lastSeen = subscribedResponse.data?.lastSeen || {};
            const statuses = subscribedResponse.data?.statuses || {};
            const online = subscribedResponse.data?.online || [];
            const profilePics = subscribedResponse.data?.profilePics || {};

            const subscribed: Contact[] = subscribedNumbers
                .map((number: string) => {
                    let contact = contacts.find( c => c.phone == number )
                    return contact ? {
                        ...contact,
                        notify: notifications[contact.phone]?.notify,
                        status: statuses[contact.phone] || NumberStatus.PENDING,
                        isOnline: online.includes(contact.phone),
                        profilePic: profilePics[contact.phone] || null,
                        lastSeen: lastSeen[contact.phone] || null,
                        isCollapsed: true
                    } as Contact : null
                })
                .filter(e=>!!e)

            store.dispatch(setContacts(contacts))
            store.dispatch(setSubscribed(subscribed))

        } catch (e) {
            console.error("ERROR")
            console.error(e)
        }
    }
}

export const numbersService = new NumbersService()
