import {Platform as RNPlatform, NativeModules} from 'react-native';
import {APP, CURRENT_APP, Platform} from './constants';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../navigation/root-stack-param-list';
import { LocalNotificationComponent } from '../components/local-notification';
import { Contact } from './interfaces';

declare const navigator: any;

export function getDeviceUuid() {
  return "8828af7d-5dc6-4e7b-bbb1-a7523d34a92d" //DeviceInfo.getUniqueIdSync();
}

export function getPlatform() {
  return RNPlatform.OS === 'ios' ? Platform.IOS : Platform.ANDROID;
}

export function getDeviceLanguage() {
  const deviceLanguage: string = navigator.language
  const deviceLanguageISOCode = deviceLanguage.substring(0, 2);
  return deviceLanguageISOCode;
}

export function getTodayDateString(){
  let todayDate = new Date()
  let year = todayDate.getFullYear()
  let month = todayDate.getMonth()+1
  let day = todayDate.getDate()
  return  year+ '-' + (month < 10 ? ( '0' + month ) : month) + '-' + (day < 10 ? ( '0' + day ) : day)
}

export function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
      dd = "0" + dd;
  }
  if (mm < 10) {
      mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
}

interface HelpersInt{
  products: any[],
  localNotification: LocalNotificationComponent
}
export var helpers: HelpersInt = {
  products: null,
  localNotification: null
}

export const isApp = (app:APP) =>{
  return app == CURRENT_APP
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function getDeviceType(): string {
  const ua = window.navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};