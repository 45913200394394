import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { HeaderComponent } from '../components/header';
import { InsightCard } from '../components/insights/InsightCard';
import { RootStackParamList } from '../navigation/root-stack-param-list';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { useRouter } from '../services/router-service';
import { extractPayload, parseChats } from '../shared/ChatDataParser';
import { parseInsights } from '../shared/InsightsParser';
import { Insight } from '../shared/interfaces';
import { globalStyles } from '../shared/styles';
import { RootState, store } from '../store';
import { setIsLoading } from '../store/ui/ui.actions';

export interface HighestChatProbabilities {
  date: string
  phone: string,
  score: number,
  time: string
}

export const NighwatchScreen = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const { t } = useTranslation()
  const { contact: contactPhone } = (route.params || {} ) as RootStackParamList['OnlineHistory'];
  const contact = useSelector((state: RootState) => state.agenda.subscribed.find(c => c.phone == contactPhone))

  const isLoading = useSelector((state: RootState) => state.ui.isLoading)
  const [error, setError] = useState<string>(null)
  const [insights, setInsights] = useState<Insight[]>([])
  const [highestChatProbabilities, setHighestChatProbabilities] = useState<HighestChatProbabilities[]>([])
  const [numbersMap, setNumbersMap] = useState<{ id: number, phone: string }[]>([])

  const router = useRouter()
  const authService = useAuthService()

  useFocusEffect(useCallback(() => {
    router.functionalGuard({ from: "Nightwatch" })
  }, [authService.isWALoggedIn, authService.isLoggedIn, authService.isValidSubscription])
  )

  const getInsights = useCallback(() => {
    store.dispatch(setIsLoading(true))

    let date = new Date()

    var data = {
      contacts: [contact],
      date: date.toISOString(),
      type: "insights"
    };

    console.log("data",data)

    return http
      .post("online-period/online-history", data)
      .then((result: any) => {
        const extracted = extractPayload(result.data)

        console.log("extracted.chats",extracted)
        let numbersMap = Object.keys(extracted.numbersMap).map((k, i) => ({ id: Object.values(extracted.numbersMap)[i] as number, phone: k }))

        setNumbersMap(numbersMap)

        let contactChatsByDate = extracted.chats.reduce((p, c, i) => {
          let dateFormatted = moment(c.end * 1000).toDate().toLocaleDateString()
          //console.log("dateFormatted",moment(c.end*1000).toDate().toLocaleDateString())
          if (c.start == 1696993428) console.log("*", date, dateFormatted)
          let index = p.findIndex(e => e.dateFormatted == dateFormatted)
          if (index != -1) {
            p[index].chats.push(c)
          } else {
            p.push({
              dateFormatted,
              chats: [c],
              date,
            })
          }
          return p
        }, [])

        //console.log("contactChatsByDate",contactChatsByDate)

        let grouped = (extracted.subscribedChats as any[]).reduce((p, c) => {
          let date = moment(c.end * 1000).toDate()
          let dateFormatted = date.toLocaleDateString()
          date.setHours(23, 59, 59)
          let index = p.findIndex(e => e.numberId == c.numberId)
          if (index != -1) {
            let index2 = p[index].chatsByDate.findIndex(e => e.dateFormatted == dateFormatted)
            if (index2 != -1) {
              p[index].chatsByDate[index2].chats.push(c)
            } else {
              p[index].chatsByDate.push({
                dateFormatted,
                chats: [c],
                date,
              })
            }
          } else {
            p.push({
              numberId: c.numberId,
              chatsByDate: [{
                dateFormatted,
                chats: [c],
                date,
              }]
            })
          }
          return p
        }, [])
        //console.log("grouped",grouped)

        const chatParserArguments = []

        grouped.forEach(groupByNumberId => {
          const { numberId, chatsByDate } = groupByNumberId
          const contacts = {
            ...extracted.contacts,
            [Object.keys(extracted.numbersMap)[Object.values(extracted.numbersMap).indexOf(numberId)]]: numberId
          }
          chatsByDate.forEach(chatByDate => {
            const { date, dateFormatted, chats } = chatByDate
            let contactChatsByThisDate = contactChatsByDate.find(e => e.dateFormatted == dateFormatted)
            let chatsToParse = [...chats, ...(contactChatsByThisDate?.chats || [])].sort((a, b) => a.id - b.id)
            //console.log("chatsToParse " + dateFormatted,[{contacts,chats: chatsToParse}, date, contact,numbersMap.find(e=>e.id==numberId).phone])
            chatParserArguments.push([{ contacts, chats: chatsToParse }, date, contact, dateFormatted, numbersMap.find(e => e.id == numberId).phone])
          });
        })

        let parseChatsPromises = chatParserArguments.map(args => parseChats(args[0], args[1], args[2]))
        Promise.all(parseChatsPromises)
          .then((parseChatsResults: any[]) => {
            parseChatsResults.forEach((e: any, i) => {
              e.dateFormatted = chatParserArguments[i][3]
              e.phone = chatParserArguments[i][4]
            });
            //console.log( "parseChatsResults",parseChatsResults )
            const highestChatProbabilities = parseChatsResults
              .map(cp => cp.historyRecords
                .map(hr => ({ ...hr, phone: cp.phone }))
                .filter(hr => !!hr.score?.stars)
                .sort((a, b) => a.score.stars - b.score.stars)
                .filter((hr,i,a) => a.findIndex( item=> item.phone == hr.phone ) == i)
                .slice(0, 3)
                .map(hr => ({
                  date: cp.dateFormatted,
                  phone: hr.phone,
                  score: hr.score.stars,
                  time: hr.hourRange.from
                }))
              )
            //console.log("highestChatProbabilities",highestChatProbabilities)
            setHighestChatProbabilities(highestChatProbabilities.flat())
          })

        parseInsights(extracted).then((insights: any[]) => {
          console.log("insights",insights)
          setInsights(insights || [])
        });

      })
      .catch((e: any) => {
        // ERROR
        console.log(e);
        setError(e?.error?.message || "Are you sure do you have internet?")
      })
      .finally(() => {
        store.dispatch(setIsLoading(false))
      })
  }, [contact])

  const getHighestChatProbabilityFor = useCallback((insight: Insight) => {
    return highestChatProbabilities.filter(e => e.date == insight.wakeUp.date.toLocaleDateString())
  }, [highestChatProbabilities])

  useFocusEffect(useCallback(() => {
    getInsights()
  }, [contact])
  )

  return !contact ? null : (
    <View style={globalStyles.screenBase} >
      <HeaderComponent title={t('nightWatch.nightWatch')} />

      {isLoading && <ActivityIndicator color={globalStyles.mainCardBackground.backgroundColor} size={30} style={{ marginBottom: 20 }} />}

      {!isLoading && !insights.length && <View style={{ padding: 20, paddingHorizontal: 30 }}>
        <Text style={[globalStyles.title, { fontSize: 18, textAlign: "center" }]}>
          {t('onlineHistory.notEnoughtData')}
        </Text>
      </View>}

      <FlatList
        style={{ flexGrow: 1, width: "100%", maxWidth: 600 }}
        contentContainerStyle={[styles.main, { alignItems: "stretch", width: "100%", maxWidth: 600 }]}
        data={insights}
        renderItem={({ item: insight }) => <InsightCard insight={insight} highestChatProbabilities={getHighestChatProbabilityFor(insight)} />}
        keyExtractor={item => Math.random().toString()}
      />

      <View style={{ paddingHorizontal: 20, paddingBottom: 17, width: "100%", maxWidth: 600 }}>
        <Pressable style={[globalStyles.secondaryButton, { marginTop: 15 }]} onPress={() => navigation.goBack()}>
          <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>GO BACK</Text>
        </Pressable>
      </View>

    </View>
  );

}

const styles = StyleSheet.create({
  subheaderText: {
    ...globalStyles.fontFamily,
    fontSize: 12,
    fontWeight: "700",
    textAlign: 'center',
    color: "white"
  },
  containerCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    flex: 1,
    flexGrow: 1,
    width: "100%",
    alignItems: 'stretch',
    padding: 20,
    paddingTop: 10,
    maxWidth: 600,
    alignSelf: "center"
  },
  contactCard: {
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: 'auto',
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignItems: 'center',
    marginVertical: 10,
  },
  contactInfoSection: {
    flexGrow: 1,
    height: '100%',
    flexDirection: 'column',
    marginLeft: 14,
    paddingVertical: 0,
    justifyContent: 'flex-start'
  },
  contactInfoName: {
    ...globalStyles.fontFamily,
    fontSize: 18,
    fontWeight: 'bold',
  },
  contactInfoNumber: {
    ...globalStyles.fontFamily,
    fontSize: 14,
    marginBottom: 10,
    opacity: 0.8
  },
  timeSpan: {
    ...globalStyles.fontFamily,
    fontSize: 13,
    width: 100,
    marginRight: 20
  },
  offlinePeriodTextPrimary: {
    ...globalStyles.fontFamily,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8
  },
  offlinePeriodTextSecondary: {
    ...globalStyles.fontFamily,
    textAlign: 'center',
    fontSize: 16,
  }
});