export const Platform = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
};

export const RequestStatus = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export enum NumberStatus {
  PENDING = 'PENDING',
  NUMBER_OK = 'NUMBER_OK',
  BAD_NUMBER = 'BAD_NUMBER',
}

export enum APP {
  CHATWATCH = 'CHATWATCH',
  WAWATCHER = 'WAWATCHER',
  WAPATROL = 'WAPATROL'
}

export const PaymentProvider = {
  NONE: 'NONE',
  STRIPE: 'STRIPE',
  PAYPAL: 'PAYPAL',
  NUVEI: 'NUVEI',
}

export const InsightsImages = {
  "assets/images/insights/emojis/-1.png": require('../../assets/insights/emojis/-1.png'),
  "assets/images/insights/emojis/1.png": require('../../assets/insights/emojis/1.png'),
  "assets/images/insights/emojis/2.png": require('../../assets/insights/emojis/2.png'),
  "assets/images/insights/emojis/3.png": require('../../assets/insights/emojis/3.png'),
  "assets/images/insights/emojis/5.png": require('../../assets/insights/emojis/5.png'),
  "assets/images/insights/emojis/6.png": require('../../assets/insights/emojis/6.png'),
  "assets/images/insights/emojis/8.png": require('../../assets/insights/emojis/8.png'),
  "assets/images/insights/emojis/9.png": require('../../assets/insights/emojis/9.png'),
  "assets/images/insights/emojis/10.png": require('../../assets/insights/emojis/10.png'),
}

export const InsightsEmojis = {
  "assets/images/insights/emojis/-1.png": "❓",
  "assets/images/insights/emojis/1.png": "🙂",
  "assets/images/insights/emojis/2.png": "😓",
  "assets/images/insights/emojis/3.png": "😤",
  "assets/images/insights/emojis/5.png": "🥲",
  "assets/images/insights/emojis/6.png": "😴",
  "assets/images/insights/emojis/8.png": "😣",
  "assets/images/insights/emojis/9.png": "🥴",
  "assets/images/insights/emojis/10.png": "😁",
}

const selectCurrentApp = (): APP => {
  let hostname = window.location.hostname
  if( hostname.endsWith('chatwatch.net') || hostname.endsWith('localhost') ){
    return APP.CHATWATCH
  }else if( hostname.endsWith('watools.io') ){
    return APP.WAWATCHER
  }else if( hostname.endsWith('wacheck.online') ){
    return APP.WAPATROL
  }
}

export const CURRENT_APP: APP = selectCurrentApp()

console.log("CURRENT_APP",CURRENT_APP)