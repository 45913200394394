import { Button, Image, ImageBackground, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { globalStyles } from '../../shared/styles';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { APP, CURRENT_APP } from '../../shared/constants';

export const LogoAndSlogan = () => {

    const { t } = useTranslation()

    const logoAndSlogan = useMemo(() => {
        if (CURRENT_APP == APP.CHATWATCH) {
            return <>
                <Image source={require('../../../assets/images/logoblanco.png')} resizeMode='contain' style={{ width: '54%', aspectRatio: 1, maxWidth: 200 }} />
                <Text style={{ ...globalStyles.baseText, opacity: 0.6, marginTop: 20, textAlign: 'center', fontSize: 15 }}>{t('slogan')}</Text>
            </>
        } else if (CURRENT_APP == APP.WAWATCHER) {
            return <>
                <Image source={require('../../../assets/images/logo-horizontal.png')} resizeMode='contain' style={{ width: '90%', aspectRatio: 3, maxWidth: 300, marginBottom: 60 }} />
            </>
        }
    }, [CURRENT_APP])

    return logoAndSlogan
}