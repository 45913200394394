import React, { Component, ReactNode } from 'react';
import { Animated, Dimensions, Easing, StyleSheet, Text, Vibration, View } from 'react-native';
import { helpers } from '../shared';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { ContactAvatar } from './contact-avatar';
//import GestureRecognizer from 'react-native-swipe-gestures';

interface LocalNotificationComponentProps {
  //loadingState: LoadingState;
}

interface _LocalNotificationItemProps {
  profilePic?: string
  title: string,
  body: string
}

class _LocalNotificationItem extends Component<_LocalNotificationItemProps> {

  state = {
    showAnim: new Animated.Value(0),
    fadeAnim: new Animated.Value(0),
  };

  componentDidMount(): void {
    Animated.timing(this.state.showAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.out(Easing.quad)
    }).start(() => {
      setTimeout(() => {
        this.fadeOut()
      }, 3000)
    });
  }

  onSwipeUp(){
    Animated.timing(this.state.showAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.out(Easing.quad)
    }).start()
    this.fadeOut()
  }

  fadeOut(){
    Animated.timing(this.state.fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.out(Easing.quad)
    }).start(()=>{
      this.state.showAnim.setValue(0)
    });
  }

  render(): ReactNode {
    return (
      <Animated.View style={{
        ...style.notification, transform: [{
          translateY: this.state.showAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 140]  // 0 : 150, 0.5 : 75, 1 : 0
          }),
        }],
        opacity: this.state.fadeAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [1, 0]
        }),
      }}>
        { /*<GestureRecognizer
          onSwipeUp={(state) => this.onSwipeUp()}
          onTouchEnd={() => this.onSwipeUp()}
          config={{ velocityThreshold: 0 }} >
          <View style={{ flexDirection: 'row' }}>
            <ContactAvatar profilePic={this.props.profilePic} />
            <View style={{ flexGrow: 1, marginLeft: 14 }} >
              <Text style={{ fontFamily: 'Nunito Sans', fontSize: 16, fontWeight: '600' }}>{this.props.title}</Text>
              <Text style={{ fontFamily: 'Nunito Sans', fontSize: 16 }}>{this.props.body}</Text>
            </View>
          </View>
    </GestureRecognizer> */ }
      </Animated.View>

    );
  }
}

export class LocalNotificationComponent extends Component<LocalNotificationComponentProps> {

  state = {
    children: []
  }

  componentDidMount(): void {
    helpers.localNotification = this
    /*setTimeout(() => {
      helpers.localNotification.showNotification({
        title: "Prueba",
        body: "y tal!"
      })
    }, 1000)  */
  }

  showNotification(notification: _LocalNotificationItemProps) {
    Vibration.vibrate(100)
    var notifications = this.state.children
    notifications.push(<_LocalNotificationItem title={notification.title} body={notification.body} profilePic={notification.profilePic} key={Date.now().toString()} />)
    this.setState({
      children: notifications
    })
  }

  render(): ReactNode {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) =>
          <View style={[style.base, { backgroundColor: 'transparent', paddingTop: insets.top }]}>
            {this.state.children}
          </View>
        }
      </SafeAreaInsetsContext.Consumer>
    );
  }
}

const style = StyleSheet.create({
  base: {
    position: 'absolute',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    width: '100%',
    height: 0,
    top: 0,
    paddingHorizontal: 28
  },
  notification: {
    position: 'absolute',
    width: Dimensions.get('window').width - 80,
    left: 40,
    height: 78,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.4,
    shadowRadius: 6,
    elevation: 6,
    bottom: 0,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  fontFamily: {
    fontFamily: 'Nunito Sans',
  },
});
