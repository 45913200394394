export const en = {
    'app.name': 'ChatWatch',
    language: 'en',
    slogan: 'The original Whatsapp\nOnline Tracker',

    goBack: 'Go back',

    menu: {
        home: "Home",
        subscription: "Subscription",
        logOut: "Log out"
    },

    login: {
        login: 'Login',
        logIn: 'Log in',
        email: 'Email',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        dontHaveAccount: "Don't have an account?",
        register: "Register",
        errorValidEmail: 'Please set a valid email',
        errorPasswordEmpty: 'Please set a password'
    },

    register: {
        register: 'Register',
        email: 'Email',
        password: 'Password',
        repeatPassword: 'Repeat password',
        alreadyHaveAccount: "Already have an account?",
        logIn: "Log in",
        errorValidEmail: 'Please set a valid email',
        errorPasswordEmpty: 'Please set a password',
        errorPasswordShort: 'Password must be 8 characters minimum',
        errorPasswordNotMatch: 'Passwords are not equal'
    },

    recoverPassword: {
        recoverPassword: 'Recover Password',
        errorValidEmail: 'Please set a valid email',
        email: 'Email',
        emailSent: 'Email sent'
    },

    buySubscription: {
        subscription: 'Subscription',
        week: 'WEEK',
        month: 'MONTH',
        year: 'YEAR',
        weekly: 'WEEKLY',
        monthly: 'MONTHLY',
        yearly: 'YEARLY',
        startYourTrial: 'START YOUR 3 DAY FREE TRIAL\nSELECT SUBSCRIPTION',
        selectPlan: 'SELECT PLAN',
        selectedPlan: 'SELECTED PLAN',
        payWith: 'PAY WITH',
        payWithCard: 'PAY WITH CREDIT CARD',
        card: 'Card',
        fullName: 'Full name',
        subscribe: 'SUBSCRIBE',
        errorFullName: "Please provide your full name.",
        errorCouldNotCreate: 'Could not create payment. Try again. CODE:1'
    },

    chatOddsIntro: {
        chatOdds: 'Chat Odds',
        wantToTrackAnother: 'Want to track another contact to\nsee if they might be chatting\ntogether?',
        later: 'Later',
        yes: 'Yes'
    },

    noContacts: {
        noContacts: 'No contacts',
        itSeems: 'It seems you don’t have\ntracked contacts yet...',
        willBeRecorded: 'Online info will be recorded from now\non for the selected contacts.\nSelect the first contact to track.',
        addContact: 'Add Contact to Track'
    },

    qrScan: {
        enterCode: 'Enter login code',
        followInstructions: 'Follow the instructions to scan this\nQR code on your main device.',
        enjoy: 'Enjoy using ChatWatch!',
        canClose: 'You can close this window',
        openUrl: '<0>You must open this url\nfrom <3>ANOTHER</3> device</0>',
        updatingServices: "We're updating our services,\nplease reload the page and try again.",
        msgConnecting1: 'Connecting to WA...Please Wait...',
        msgConnecting2: 'Syncing account. Please wait...',
        msgConnecting3: 'Fetching info...Please wait...',
        msgConnecting4: 'Session initialized.',
    },

    resetPassword: {
        resetPassword: 'Reset Password',
        errorPasswordEmpty: 'Please set a password',
        errorPasswordShort: 'Password must be 8 characters minimum',
        errorPasswordNotMatch: 'Passwords are not equal',
        errorLinkNotValid: 'Link not valid',
        newPassword: 'New password',
        repeatNewPassword: 'Repeat new password',
        passwordReseted: 'Password reseted'
    },

    thankYou: {
        thankYou: 'Thank you\nfor your subscription!',
        continue: 'CONTINUE'
    },

    waLogin: {
        privacyNotice: 'Privacy notice',
        weNotStore: 'We do not store, read or\naccess any WA account\ndata.',
        next: 'Next',
        howToLogin: 'How to Login ?',
        howToLogin1: 'Find a computer,  tablet, phone or Smart TV\nVisit',
        howToLogin2: 'Enter following code',
        link: 'Link WA Account',
        link1: 'Open WA Setting by tapping on this Icon.',
        link2: 'Select linked devices.',
        link3: 'When the camera is activated, point your\nphone to scan the QR Code',
        connecting: 'CONNECTING',
        somethingWrong: 'Something has gone wrong.\nPlease, reload this page and try again.',
        msgConnecting1: 'Connecting to WA...Please Wait...',
        msgConnecting2: 'Syncing account. Please wait...',
        msgConnecting3: 'Fetching info...Please wait...',
        msgConnecting4: 'Session initialized.',
    },

    main: {
        connectionManager: 'Connection Manager',
        trackedContacts: 'Tracked contacts',
        contactTrackingActive: '<0>Contact tracking is <1>active</1>.<br/>You can deactivate tracking to appear offline. Later, you can activate it with one click. There is no need scan the QR code again.</0>',
        contactTrackingInactive: '<0>Contact tracking is <1>inactive</1>.</0>',
        deactivateTracking: 'Deactivate tracking',
        addContact: 'Add contact to track',
        activateTracking: 'Activate tracking!',

        contactCard: {
            notTracking: 'Not tracking',
            howToTrack: 'How to track contact?',
            onlineNotification: 'Online Notification',
            onlineHistory: 'Online History',
            chatODDS: 'Chat OODS',
            nightWatch: 'NightWatch',
            delete: 'Delete',

            lastSeen: {
                online: 'Online',
                secsAgo: '{{count}} Sec Ago',
                secsAgo_plural: '{{count}} Secs Ago',
                minsAgo: '{{count}} Min Ago',
                minsAgo_many: '{{count}} Mins Ago',
                hoursAgo: '{{count}} Hour Ago',
                hoursAgo_plural: '{{count}} Hours Ago',
                daysAgo: '{{count}} Day Ago',
                daysAgo_plural: '{{count}} Days Ago',
            }
        }
    },

    subscriptionDetails: {
        subscriptionDetails: 'Subscription Details',
        feature1: 'Track up to {{count}} contacts!',
        feature2: 'Online History',
        feature3: 'Contact Insights',
        feature4: 'AI trained chat probability',
        feature5: 'Support team (email)',
        hasBeenCancelled: '<0>This subscription has been <1>cancelled</1> and your card will not be charged again.</0>',
        accessWillEndOn: 'Your access will end on',
        nextPaymentDate: 'Next payment date',
        cancelPlan: 'Cancel plan',
        howToCancel: 'How to cancel your plan?',
        howToCancelText: 'Log in to your PayPal account.\nClick Tools, then select All Tools at the top of the page.\nThen select Recurring Payments from the Tools page.\nYou can cancel your subscription there.',
        cancelSubscription: 'Cancel Subscription',
        weAreSorryToEar: 'We are sorry to hear that you want to cancel. Are there any service or quality issues?\nWould you like to send us an email for potential resolutions?\nIf not, click on the Cancel Subscription button.',
        keepSubscription: 'Keep my subscription'
    },

    addNumber: {
        selectContactToCompare: 'Select a contact\nto compare',
        selectContactToTrack: 'Select a contact\nto track',
        searchBy: 'Search by phone or name',
        noResultsFor: 'No results for',
        trySearchingByPhone: 'Try searching by phone number'
    },

    onlineHistory: {
        onlineHistory: 'Online History',
        last24Hours: 'Last 24 hours',
        selectADate: 'Select a date',
        time: 'Time',
        offlineFor: 'Offline for',
        hour: 'hour',
        hour_plural: 'hours',
        from: 'from',
        to: 'to',
        notEnoughtData: 'Not enought data, please come back later',
        changeDate: 'CHANGE DATE'
    },

    compareWith: {
        selectContactToCompareWith: 'Select a contact to\ncompare with',
        searchBy: 'Search by phone or name',
    },

    chatProbability: {
        chatProbability: 'Chat Probability',
        last24Hours: 'Last 24 hours',
        selectADate: 'Select a date',
        time: 'Time',
        score: 'Score',
        offlineFor: 'Offline for',
        hour: 'hour',
        hour_plural: 'hours',
        from: 'from',
        to: 'to',
        notEnoughtData: 'Not enought data, please come back later',
        changeDate: 'CHANGE DATE'
    },

    nightWatch: {
        nightWatch: 'NightWatch',
        sleptFor: 'SLEPT FOR {{count}} HOUR TOP',
        sleptFor_plural: 'SLEPT FOR {{count}} HOURS TOP',
        owlAlert: 'Owl Alert',
        highestChatProbability: 'Highest Chating Probability',
        minutes: 'minute',
        minutes_plural: 'minutes'
    }

}

/*
NightWatch
SLEPT FOR {{count}} HOUR TOP
SLEPT FOR {{count}} HOURS TOP
Owl Alert
Highest Chating Probability
minute
minutes
*/

/*const i18nKeys = Object.keys(en).reduce((p, c, i) => {
    p[c] = typeof en[c] == "string" ? c : Object.keys(en[c]).reduce((p2, c2, i2) => {
        p2[c2] = `${c}.${c2}`
        return p2
    }, {})
    return p
}, {})

const all = []

Object.keys(en).forEach( k => {
    if( typeof en[k] == "string" ){
        all.push( en[k].replaceAll('\n',' {n} ') )
    }else{
        Object.keys(en[k]).forEach( k2 => {
            if( typeof en[k][k2] == "string" ){
                all.push( en[k][k2].replaceAll('\n',' {n} ') )
            }else{
                Object.keys(en[k][k2]).forEach( k3 => {
                    if( typeof en[k][k2][k3] == "string" ){
                        all.push( en[k][k2][k3].replaceAll('\n',' {n} ') )
                    }else{
                        Object.values( en[k][k2][k3] ).forEach( v => {
                            all.push(v.replaceAll('\n',' {n} ') )
                        })
                    }
                })
            }
        })
    }
})*/

//console.log(i18nValue)
//console.log(all.join('\n'))

//export { i18nKeys }