import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { ContactAvatar } from '../components/contact-avatar';
import { HeaderComponent } from '../components/header';
import SearchBar from '../components/searchbar';
import { RootStackParamList } from '../navigation/root-stack-param-list';
import { useAuthService } from '../services/auth-service';
import { useRouter } from '../services/router-service';
import { Contact } from '../shared/interfaces';
import { globalStyles } from '../shared/styles';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';

export const CompareWithScreen = () => {
    const navigation = useNavigation()
    const route = useRoute()
    const {t} = useTranslation()

    const { contact: contactPhone } = route.params as RootStackParamList['OnlineHistory'];
  const contact = useSelector((state:RootState)=>state.agenda.subscribed.find(c=>c.phone==contactPhone))

    const agendaState = useSelector((state: RootState) => state.agenda)

    const [compareWithContact, setCompareWithContact] = useState<Contact>(null)
    const [searchText, setSearchText] = useState<string>("")

    const router = useRouter()
    const authService = useAuthService()
  
    useFocusEffect(useCallback(() => {
      router.functionalGuard({from:"CompareWith"})
    }, [authService.isWALoggedIn,authService.isLoggedIn,authService.isValidSubscription])
    )

    const onChangeSearch = useCallback((text: string) => {
        setSearchText(text)
    }, [searchText, setSearchText])

    useEffect(() => {
        if (!compareWithContact) return;
        navigation.navigate("ChatProbability", {
            contact: contact.phone,
            compareWithContact: compareWithContact.phone
        })
    }, [compareWithContact])

    return !contact ? null : (
        <View style={globalStyles.screenBase} >
            <HeaderComponent title={`${t('compareWith.selectContactToCompareWith')} ${contact.name}...`} style={{ textAlign: "left" }} />

            <View style={{ flex: 1, position: 'relative', maxWidth: 600, alignSelf: "center", width: "100%" }} >
                <View style={{ paddingHorizontal: 20, paddingTop: 10 }}>
                    <SearchBar
                        placeholder={t('compareWith.searchBy')}
                        setSearchPhrase={onChangeSearch}
                    />
                </View>
                <ScrollView contentContainerStyle={[styles.main, { alignItems: 'stretch' }]}>
                    {agendaState.subscribed.filter(c => c.status == "NUMBER_OK" && c.id != contact.id && (c.name.toLowerCase().includes(searchText.toLowerCase()) || c.phone.includes(searchText.toLowerCase()))).map((contact: Contact) =>
                        <Pressable onPress={() => setCompareWithContact(contact)} key={contact.id}>
                            <View style={styles.contactCard}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <ContactAvatar profilePic={contact.profilePic} />
                                    <View style={styles.contactInfoSection}>
                                        <Text style={styles.contactInfoName}>{contact.name}</Text>
                                        <Text style={styles.contactInfoNumber}>{contact.phone}</Text>
                                    </View>
                                </View>
                            </View>
                        </Pressable>
                    )}
                </ScrollView>
            </View>

            <View style={{ paddingHorizontal: 20, paddingBottom: 17, width: "100%", maxWidth: 600 }}>
                <Pressable style={[globalStyles.secondaryButton]} onPress={() => navigation.goBack()}>
                    <Text style={[globalStyles.fontFamily, { color: 'white', fontWeight: 'bold', marginLeft: 10, fontSize: 14, textTransform: "uppercase" }]}>{t('goBack')}</Text>
                </Pressable>
            </View>

        </View>
    );

}

const styles = StyleSheet.create({
    subheaderText: {
        ...globalStyles.fontFamily,
        fontSize: 12,
        fontWeight: "700",
        textAlign: 'center',
        color: "white"
    },
    containerCenter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    main: {
        flex: 1,
        flexGrow: 1,
        width: "100%",
        alignItems: 'stretch',
        padding: 20,
        paddingTop: 10,
        maxWidth: 600,
        alignSelf: "center"
    },
    contactCard: {
        flexDirection: 'row',
        backgroundColor: '#f5f5f5',
        width: '100%',
        height: 'auto',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 12,
        alignItems: 'center',
        marginVertical: 10,
    },
    contactInfoSection: {
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
        marginLeft: 14,
        paddingVertical: 0,
        justifyContent: 'flex-start'
    },
    contactInfoName: {
        ...globalStyles.fontFamily,
        fontSize: 18,
        fontWeight: 'bold',
    },
    contactInfoNumber: {
        ...globalStyles.fontFamily,
        fontSize: 14,
        opacity: 0.8
    },
    timeSpan: {
        ...globalStyles.fontFamily,
        textAlign: "center",
        fontSize: 13,
        width: 90
    },
    offlinePeriodTextPrimary: {
        ...globalStyles.fontFamily,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8
    },
    offlinePeriodTextSecondary: {
        ...globalStyles.fontFamily,
        textAlign: 'center',
        fontSize: 16,
    },
    paddingscore0: { marginTop: 90 },
    paddingscore1: { marginTop: 72 },
    paddingscore2: { marginTop: 54 },
    paddingscore3: { marginTop: 36 },
    paddingscore4: { marginTop: 18 },
    paddingscore5: { marginTop: 0 },
});