import React, {Component} from 'react';
import {View, Image, StyleProp, ViewStyle, ImageStyle} from 'react-native';

export interface ImageProps {
  asset: any
  style?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
}

export class ImageAspectFit extends Component<ImageProps> {

  render() {
    return (
      <View style={this.props.style}>
            <Image source={ this.props.asset } resizeMode="contain" style={[ this.props.imageStyle || {}, {width:"100%", height:'100%'} ]} />
      </View>
    );
  }
}
