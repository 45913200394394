import React, { Component } from 'react';
import { StyleSheet, View, Image, Text } from 'react-native';
import { ImageAspectFit } from './image';

export interface AvatarWithStatusProps {
  profilePic: string
  isOnline: boolean
}

export class AvatarWithStatus extends Component<AvatarWithStatusProps> {

  render() {
    return (
      <View>
        <View style={{ ...styles.circle, borderColor: this.props.isOnline ? '#0FDE23' : 'transparent' }}>
          {!!this.props.profilePic ?
            <Image source={{ uri: this.props.profilePic }} style={{ width: 66, height: 66 }} />
            :
            <ImageAspectFit asset={this.props.isOnline ? require('../../assets/images/tracking-contact.png') : require('../../assets/images/not-tracking.png')} style={{ width: 60, marginBottom: 16 }} />
          }
        </View>
        {this.props.isOnline && <View style={styles.statusFlag} />}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 66,
    height: 66,
    borderRadius: 44,
    borderWidth: 3,
    overflow: 'hidden'
  },
  statusTag: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 4,
    width: 70
  },
  statusFlag: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#0FDE23",
    borderWidth: 4,
    borderColor: "white"
  },
  statusText: {
    fontFamily: 'Nunito Sans',
    fontWeight: '200',
    fontSize: 11,
    lineHeight: 15,
    color: 'white'
  },
});
