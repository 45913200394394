import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import * as mixpanel from 'mixpanel-browser';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSelector } from "react-redux";
import { HeaderComponent } from '../components/header';
import SearchBar from '../components/searchbar';
import { useAuthService } from '../services/auth-service';
import { http } from '../services/http-service';
import { numbersService } from '../services/numbers.service';
import { useRouter } from '../services/router-service';
import { Contact } from '../shared/interfaces';
import { globalStyles } from '../shared/styles';
import { RootState } from '../store';

export const AddNumberScreen = () => {

  const navigation = useNavigation()
  const { t } = useTranslation()
  const route = useRoute()
  const router = useRouter()
  const authService = useAuthService()

  const agendaState = useSelector((state: RootState) => state.agenda)

  const [searchText, setSearchText] = useState<string>("")
  const [alreadySelectedPhones, setAlreadySelectedPhones] = useState<string[]>([])
  const comingFromChatOddsIntro = useMemo(() => {
    const routes = navigation.getState()?.routes;
    const prevRoute = routes[routes.length - 2];
    return !!prevRoute && prevRoute.name === "ChatOddsIntro"
  }, [navigation])

  useFocusEffect( useCallback(() => {
    setAlreadySelectedPhones(agendaState.subscribed.map(c => c.phone))
    numbersService.getContacts({ forceBackend: true });
  }, []) )

  useFocusEffect(useCallback(() => {
    router.functionalGuard({ from: "AddNumber" })
  }, [authService.isWALoggedIn, authService.isLoggedIn, authService.isValidSubscription])
  )

  const onChangeSearch = useCallback((text: string) => {
    setSearchText(text)
  }, [searchText, setSearchText])

  const selectContact = useCallback(async (contact: Contact) => {
    let newSubscribed = [...agendaState.subscribed]
    newSubscribed.push({ ...contact })
    //store.dispatch( setSubscribed( newSubscribed ) )
    let subscribedPhones = newSubscribed.map(c => c.phone)
    try {
      await http.post("number/sync-subscribed", { numbers: subscribedPhones })
      try{
        mixpanel.track('New tracked number')
      }catch(e){}
      numbersService.getContacts()
      const routes = navigation.getState()?.routes;
      const prevRoute = routes[routes.length - 2];
      if (!!prevRoute && prevRoute.name === "NoContacts") {
        navigation.navigate('ChatOddsIntro' as never)
      } else {
        navigation.navigate('Main' as never)
      }
    } catch (e) { }
  }, [agendaState])

  const noResults = useCallback(()=>{
    return !!searchText.length && !agendaState.contacts.filter(contact => !alreadySelectedPhones.includes(contact.phone) && (contact.name.toLowerCase().includes(searchText.toLowerCase())) ).length && !agendaState.contacts.filter(contact => !alreadySelectedPhones.includes(contact.phone) && (contact.name.toLowerCase().includes(searchText.toLowerCase()) || (contact as Contact).phone.includes(searchText))).length
  },[searchText,agendaState])

  return (
    <View style={globalStyles.screenBase} > 
      <HeaderComponent title={comingFromChatOddsIntro ? t('addNumber.selectContactToCompare') : t('addNumber.selectContactToTrack')} />
      <View style={[styles.main, { flexGrow: 0, alignItems: 'flex-start', paddingBottom: 10 }]}>
        <SearchBar
          placeholder={t('addNumber.searchBy')}
          setSearchPhrase={onChangeSearch}
        />
      </View>

      { noResults() && <View style={{ alignItems: "center", marginTop: 20 }}>
          <Text style={[globalStyles.fontFamily,{ color: "white", fontWeight: "700", fontSize: 17, textAlign: "center" }]}>{t('addNumber.noResultsFor')} "{searchText}"</Text>
          <Text style={[globalStyles.fontFamily,{ color: "white", fontSize: 15, marginTop: 12 }]}>{ t('addNumber.trySearchingByPhone') }</Text>
      </View> }

      <FlatList
        style={{ width: "100%", maxWidth: 600 }}
        contentContainerStyle={[styles.main, { paddingBottom: 360, paddingTop: 0, alignItems: "stretch" }]}
        data={agendaState.contacts.filter(contact => !alreadySelectedPhones.includes(contact.phone) && (contact.name.toLowerCase().includes(searchText.toLowerCase()) || (contact as Contact).phone.includes(searchText))).sort((a1, a2) => a1.name > a2.name ? 1 : -1)}
        renderItem={({ item: contact }) => <TouchableOpacity style={{ width: "100%" }} onPress={() => selectContact(contact)}>
          <View style={styles.contactCard}>
            <Image source={require('../../assets/images/contact-icon.png')} style={{ width: 30, height: 30 }} />
            <View style={styles.contactInfoSection}>
              <Text style={styles.contactInfoName}>{contact.name}</Text>
              <Text style={styles.contactInfoNumber}>{contact.phone}</Text>
            </View>
          </View>
        </TouchableOpacity>}
        keyExtractor={item => item.phone}
      />
    </View>
  );
}


const styles = StyleSheet.create({
  containerCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    flexGrow: 1,
    alignItems: 'flex-start',
    padding: 24,
    maxWidth: 600,
    alignSelf: "center",
    width: "100%",
  },
  contactCard: {
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    width: '100%',
    height: 'auto',
    borderRadius: 13,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignItems: 'center',
    marginVertical: 10,
  },
  contactInfoSection: {
    flexGrow: 1,
    height: '100%',
    flexDirection: 'column',
    marginLeft: 14,
    paddingVertical: 0,
    justifyContent: 'flex-start'
  },
  contactInfoName: {
    ...globalStyles.fontFamily,
    fontSize: 17,
    fontWeight: 'bold',
  },
  contactInfoNumber: {
    ...globalStyles.fontFamily,
    fontSize: 15,
    marginBottom: 10,
    opacity: 0.8
  },
});