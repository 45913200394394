export const es = {
    'app.name': 'ChatWatch',
    language: 'es',
    slogan: 'El rastreador original\nde Whatsapp',

    goBack: 'Regresar',

    menu: {
        home: "Inicio",
        subscription: "Suscripción",
        logOut: "Cerrar sesión"
    },

    login: {
        login: 'Entrar',
        logIn: 'Entrar',
        email: 'Correo electrónico',
        password: 'Contraseña',
        forgotPassword: '¿Contraseña olvidada?',
        dontHaveAccount: "¿No tienes cuenta?",
        register: "Regístrate",
        errorValidEmail: 'Por favor introduce un correo válido',
        errorPasswordEmpty: 'Por favor introduce la contraseña'
    },

    register: {
        register: 'Registro',
        email: 'Correo electrónico',
        password: 'Contraseña',
        repeatPassword: 'Repetir contraseña',
        alreadyHaveAccount: "¿Ya tienes una cuenta?",
        logIn: "Entrar",
        errorValidEmail: 'Por favor introduce un correo válido',
        errorPasswordEmpty: 'Por favor introduce la contraseña',
        errorPasswordShort: 'La contraseña debe ser de 8 carácteres mínimo',
        errorPasswordNotMatch: 'Las contraseñas no coinciden'
    },

    recoverPassword: {
        recoverPassword: 'Recuperar contraseña',
        errorValidEmail: 'Por favor introduce un correo válido',
        email: 'Correo electrónico',
        emailSent: 'Correo enviado'
    },

    buySubscription: {
        subscription: 'Suscripción',
        week: 'SEMANA',
        month: 'MES',
        year: 'AÑO',
        weekly: 'SEMANAL',
        monthly: 'MENSUAL',
        yearly: 'ANUAL',
        startYourTrial: 'EMPIEZA TU PRUEBA GRATUITA DE 3 DÍAS\nSELECCIONA SUSCRIPCIÓN',
        selectPlan: 'SELECCIONA PLAN',
        selectedPlan: 'PLAN SELECCIONADO',
        payWith: 'PAGAR CON',
        payWithCard: 'PAGAR CON TARJETA',
        card: 'TARJETA',
        fullName: 'Nombre completo',
        subscribe: 'SUSCRIBIRSE',
        errorFullName: "Por favor introduce tu nombre completo",
        errorCouldNotCreate: 'No se pudo crear el pago. Intenta otra vez. CODIGO:1'
    },

    chatOddsIntro: {
        chatOdds: 'Probabilidad de Chat',
        wantToTrackAnother: '¿Quieres monitorear otro contacto\npara comprobar si están\nchateando entre ellos?',
        later: 'Después',
        yes: 'Si'
    },

    noContacts: {
        noContacts: 'Sin contactos',
        itSeems: 'Parece que todavía no estás\nmonitoreando contactos...',
        willBeRecorded: 'La información de estado En línea será\nmonitoreada desde ahora para los\ncontactos seleccionados.\nElige el primer contacto a monitorear.',
        addContact: 'Añadir contacto'
    },

    qrScan: {
        enterCode: 'Introduce el código',
        followInstructions: 'Sigue las instrucciones para escanear este\ncódigo QR en tu dispositivo principal.',
        enjoy: '¡Disfruta usando ChatWatch!',
        canClose: 'Puedes cerrar esta ventana',
        openUrl: '<0>Debes abrir esta url\ndesde <3>OTRO</3> dispositivo</0>',
        updatingServices: "Estamos actualizando nuestro servicios,\npor favor recarga la página y vuelve a intentarlo.",
        msgConnecting1: 'Conectando a WA...Por favor espera...',
        msgConnecting2: 'Sincronizando cuenta. Por favor espera...',
        msgConnecting3: 'Obteniendo información...Por favor espera...',
        msgConnecting4: 'Sesión iniciada.',
    },

    resetPassword: {
        resetPassword: 'Restablecer contraseña',
        errorPasswordEmpty: 'Por favor introduce la contraseña',
        errorPasswordShort: 'La contraseña debe ser de 8 carácteres mínimo',
        errorPasswordNotMatch: 'Las contraseñas no coinciden',
        errorLinkNotValid: 'Enlace no válido',
        newPassword: 'Nueva contraseña',
        repeatNewPassword: 'Repetir nueva contraseña',
        passwordReseted: 'Contraseña restablecida'
    },

    thankYou: {
        thankYou: '¡Gracias\nPor tu suscripción!',
        continue: 'Continuar'
    },

    waLogin: {
        privacyNotice: 'Aviso de Privacidad',
        weNotStore: 'No almacenamos, leémos o\naccedemos a datos de ninguna\ncuenta de WA.',
        next: 'Siguiente',
        howToLogin: '¿Cómo conectarse?',
        howToLogin1: 'Encuentra una computadora, teléfono, tablet o Smart TV\nVisita',
        howToLogin2: 'Introduce el siguiente código',
        link: 'Vincular cuenta de WA',
        link1: 'Abre Ajustes de WA haciendo click en este icono.',
        link2: 'Selecciona Dispositivos vinculados.',
        link3: 'Cuando la cámara esté activa, apunta tu\nteléfono para escanear el código QR',
        connecting: 'CONECTANDO',
        somethingWrong: 'Algo salió mal.\nPor favor, recarga la página y vuelve a intentarlo.',
        msgConnecting1: 'Conectando a WA...Por favor espera...',
        msgConnecting2: 'Sincronizando cuenta. Por favor espera...',
        msgConnecting3: 'Obteniendo información...Por favor espera...',
        msgConnecting4: 'Sesión iniciada.',
    },

    main: {
        connectionManager: 'Administrar conexión',
        trackedContacts: 'Contactos rastreados',
        contactTrackingActive: '<0>El seguimiento de contactos está <1>activo</1>.<br/>Puedes desactivar el seguimiento para aparecer sin conexión. Más tarde, podrás activarlo con un clic. No es necesario volver a escanear el código QR.</0>',
        contactTrackingInactive: '<0>El seguimiento de contactos está <1>inactivo</1>.</0>',
        deactivateTracking: 'Desactivar seguimiento',
        addContact: 'Añadir contacto rastreado',
        activateTracking: '!Activar seguimiento!',

        contactCard: {
            notTracking: 'Sin rastrear',
            howToTrack: '¿Cómo rastrear contactos?',
            onlineNotification: 'Notificationes En línea',
            onlineHistory: 'Historial\nEn línea',
            chatODDS: 'Posibilidades\nde Chat',
            nightWatch: 'Visión nocturna',
            delete: 'Borrar',

            lastSeen: {
                online: 'En línea',
                secsAgo: 'Hace {{count}} Seg',
                secsAgo_plural: 'Hace {{count}} Segs',
                minsAgo: 'Hace {{count}} Min',
                minsAgo_plural: 'Hace {{count}} Mins',
                hoursAgo: 'Hace {{count}} Hora',
                hoursAgo_plural: 'Hace {{count}} Horas',
                daysAgo: 'Hace {{count}} Día',
                daysAgo_plural: 'Hace {{count}} Días',
            }
        }

    },

    subscriptionDetails: {
        subscriptionDetails: 'Detalles de la suscripción',
        feature1: '¡Rastrea hasta {{count}} contactos!',
        feature2: 'Historial En línea',
        feature3: 'Información de los contactos',
        feature4: 'Probabilidad de chat entrenado por IA',
        feature5: 'Equipo de soporte (email)',
        hasBeenCancelled: '<0>Esta suscripción ha sido <1>cancelada</1> y tu tarjeta no volverá a recibir cargos.</0>',
        accessWillEndOn: 'Tu acceso va a terminar en',
        nextPaymentDate: 'Próxima fecha de pago',
        cancelPlan: 'Cancelar plan',
        howToCancel: '¿Cómo cancelar tu plan?',
        howToCancelText: 'Inicia sesión en tu cuenta PayPal.\nHaz clic en Herramientas, luego selecciona Todas las herramientas en la parte superior de la página.\nLuego selecciona Pagos recurrentes en la página Herramientas.\nPuedes cancelar su suscripción allí.',
        cancelSubscription: 'Cancelar subscripción',
        weAreSorryToEar: 'Lamentamos saber que quieres cancelar. ¿Hay algún problema de calidad o de servicio?\n\n¿Te gustaría enviarnos un correo electrónico para posibles soluciones?\n\nSi no, haz clic en el botón Cancelar suscripción.',
        keepSubscription: 'Conservar'
    },

    addNumber: {
        selectContactToCompare: 'Selecciona un contacto\npara comparar',
        selectContactToTrack: 'Selecciona un contacto\npara rastrear',
        searchBy: 'Busca por número o nombre',
        noResultsFor: 'No hay resultados para',
        trySearchingByPhone: 'Prueba buscando por númerod e teléfono'
    },

    onlineHistory: {
        onlineHistory: 'Historial En línea',
        last24Hours: 'Últimas 24 horas',
        selectADate: 'Selecciona una fecha',
        time: 'Tiempo',
        offlineFor: 'Desconectado durante',
        hour: 'hora',
        hour_plural: 'horas',
        from: 'desde',
        to: 'hasta',
        notEnoughtData: 'Sin suficientes datos, por favor regresa después',
        changeDate: 'CAMBIAR FECHA'
    },

    compareWith: {
        selectContactToCompareWith: 'Selecciona para\ncomparar con',
        searchBy: 'Busca por número o nombre',
    },

    chatProbability: {
        chatProbability: 'Probabilidad de Chat',
        last24Hours: 'Últimas 24 horas',
        selectADate: 'Selecciona una fecha',
        time: 'Tiempo',
        score: 'Puntuación',
        offlineFor: 'Desconectado durante',
        hour: 'hora',
        hour_plural: 'horas',
        from: 'desde',
        to: 'hasta',
        notEnoughtData: 'Sin suficientes datos, por favor regresa después',
        changeDate: 'CAMBIAR FECHA'
    },

    nightWatch: {
        nightWatch: 'Visión Nocturna',
        sleptFor: 'DURMIÓ HASTA {{count}} HORA',
        sleptFor_plural: 'DURMIÓ HASTA {{count}} HORAS',
        owlAlert: 'Alerta Búho',
        highestChatProbability: 'Principales probabilidades de Chat',
        minutes: 'minuto',
        minutes_plural: 'minutos'
    }
}