import { createReducer } from '@reduxjs/toolkit';
import { AuthState } from './AuthState';
import { clearAllAuth, setAccessToken, setFirebaseTokenSentToServer, setIsWALoggedIn, setOnlineStatus, setSubscription, setTempLoginCode, setUserInfo } from './auth.actions';

const initialState = {
    subscription: null,
    accessToken: null,
    isWALoggedIn: null,
    onlineStatus: null,
    userInfo: null,
    error: null,
    wasFirebaseTokenSentToServer: false,
    tempLoginCode: null
  } as AuthState;

export const authReducer = createReducer(initialState, builder => {
  builder.addCase(setAccessToken, (state, action) => {
    return { ...state, accessToken: action.payload};
  });
  builder.addCase(setSubscription, (state, action) => {
    return { ...state, subscription: action.payload};
  });
  builder.addCase(setOnlineStatus, (state, action) => {
    return { ...state, onlineStatus: action.payload};
  });
  builder.addCase(setIsWALoggedIn, (state, action) => {
    if( action.payload == state.isWALoggedIn ) return;
    console.log("setIsWALoggedIn",action.payload)
    return { ...state, isWALoggedIn: action.payload};
  });
  builder.addCase(setUserInfo, (state, action) => {
    return { ...state, userInfo: action.payload};
  });
  builder.addCase(setFirebaseTokenSentToServer, (state, action) => {
    return { ...state, setFirebaseTokenSentToServer: action.payload};
  });
  builder.addCase(setTempLoginCode, (state, action) => {
    return { ...state, tempLoginCode: action.payload};
  });
  builder.addCase(clearAllAuth, (state, action) => {
    return { ...initialState};
  });
});
