export const nl = {
  "app.name": "ChatWatch",
  "language": "nl",
  "slogan": "De originele Whatsapp\nOnline Volger",
  "goBack": "Ga terug",
  "menu": {
    "home": "Home",
    "subscription": "Abonnement",
    "logOut": "Uitloggen"
  },
  "login": {
    "login": "Inloggen",
    "logIn": "Inloggen",
    "email": "E-mail",
    "password": "Wachtwoord",
    "forgotPassword": "Wachtwoord vergeten?",
    "dontHaveAccount": "Heb je geen account?",
    "register": "Registreren",
    "errorValidEmail": "Stel een geldig e-mailadres in",
    "errorPasswordEmpty": "Stel een wachtwoord in"
  },
  "register": {
    "register": "Registreren",
    "email": "E-mail",
    "password": "Wachtwoord",
    "repeatPassword": "Herhaal wachtwoord",
    "alreadyHaveAccount": "Heb je al een account?",
    "logIn": "Inloggen",
    "errorValidEmail": "Stel een geldig e-mailadres in",
    "errorPasswordEmpty": "Stel een wachtwoord in",
    "errorPasswordShort": "Wachtwoord moet minimaal 8 tekens bevatten",
    "errorPasswordNotMatch": "Wachtwoorden zijn niet gelijk"
  },
  "recoverPassword": {
    "recoverPassword": "Herstel wachtwoord",
    "errorValidEmail": "Stel een geldig e-mailadres in",
    "email": "E-mail",
    "emailSent": "Verzonden e-mail"
  },
  "buySubscription": {
    "subscription": "Abonnement",
    "week": "WEEK",
    "month": "MAAND",
    "year": "JAAR",
    "weekly": "WEKELIJK",
    "monthly": "MAANDELIJKS",
    "yearly": "JAARLIJKS",
    "startYourTrial": "START UW GRATIS PROEF VAN 3 DAGEN\nKIES ABONNEMENT",
    "selectPlan": "SELECTEER PLAN",
    "selectedPlan": "GESELECTEERD PLAN",
    "payWith": "BETALEN MET",
    payWithCard: 'BETALEN MET KAART',
    "card": "Kaart",
    "fullName": "Volledige naam",
    "subscribe": "ABONNEREN",
    "errorFullName": "Geef je volledige naam op.",
    "errorCouldNotCreate": "Kan betaling niet aanmaken. Probeer het opnieuw. CODE:1"
  },
  "chatOddsIntro": {
    "chatOdds": "Chatkansen",
    "wantToTrackAnother": "Wil je een ander contact volgen naar\nom te zien of ze misschien samen\nchatten?",
    "later": "Later",
    "yes": "Ja"
  },
  "noContacts": {
    "noContacts": "Geen contacten",
    "itSeems": "Het lijkt erop dat je\nnog geen contacten hebt getraceerd...",
    "willBeRecorded": "Online info wordt vanaf nu\nopgenomen voor de geselecteerde contacten.\nSelecteer het eerste contact dat u wilt volgen.",
    "addContact": "Contact toevoegen aan track"
  },
  "qrScan": {
    "enterCode": "Inlogcode invoeren",
    "followInstructions": "Volg de instructies om deze\nQR-code te scannen op uw hoofdapparaat.",
    "enjoy": "Veel plezier met ChatWatch!",
    "canClose": "Je kunt dit venster sluiten",
    "openUrl": "<0>U moet deze url\nopenen vanaf <3> EEN ANDER</3> apparaat</0>",
    "updatingServices": "We zijn onze services aan het bijwerken,\nlaad de pagina opnieuw en probeer het opnieuw.",
    "msgConnecting1": "Verbinding met WA...Even geduld...",
    "msgConnecting2": "Account wordt gesynchroniseerd. Even geduld...",
    "msgConnecting3": "Info ophalen...Even geduld...",
    "msgConnecting4": "Sessie geïnitialiseerd."
  },
  "resetPassword": {
    "resetPassword": "Wachtwoord opnieuw instellen",
    "errorPasswordEmpty": "Stel een wachtwoord in",
    "errorPasswordShort": "Wachtwoord moet minimaal 8 tekens bevatten",
    "errorPasswordNotMatch": "Wachtwoorden zijn niet gelijk",
    "errorLinkNotValid": "Link niet geldig",
    "newPassword": "Nieuw wachtwoord",
    "repeatNewPassword": "Herhaal nieuw wachtwoord",
    "passwordReseted": "Wachtwoord opnieuw ingesteld"
  },
  "thankYou": {
    "thankYou": "Bedankt\nvoor je inschrijving!",
    "continue": "VERDERGAAN"
  },
  "waLogin": {
    "privacyNotice": "Privacyverklaring",
    "weNotStore": "We slaan geen\nWA-accountgegevens op, lezen ze niet en\nhebben er geen toegang toe.",
    "next": "Volgende",
    "howToLogin": "Hoe aanmelden?",
    "howToLogin1": "Zoek een computer, tablet, telefoon of Smart TV\nBezoek",
    "howToLogin2": "Voer de volgende code in",
    "link": "WA-account koppelen",
    "link1": "Open WA-instelling door op dit pictogram te tikken.",
    "link2": "Selecteer gekoppelde apparaten.",
    "link3": "Wanneer de camera geactiveerd is, richt u uw\ntelefoon op het scannen van de QR-code.",
    "connecting": "VERBINDEN",
    "somethingWrong": "Er is iets misgegaan.\nLaad deze pagina opnieuw en probeer het opnieuw.",
    "msgConnecting1": "Verbinding met WA...Even wachten...",
    "msgConnecting2": "Synchronisatie van account. Even geduld...",
    "msgConnecting3": "Info ophalen...Even wachten...",
    "msgConnecting4": "Sessie geïnitialiseerd."
  },
  "main": {
    "connectionManager": "Verbindingsbeheer",
    "trackedContacts": "Bijgehouden contacten",
    "contactTrackingActive": "<0>Het bijhouden van contacten is <1>actief</1>.<br/>Je kunt het bijhouden van contacten deactiveren om offline te verschijnen. Later kunt u het met één klik activeren. Het is niet nodig om de QR-code opnieuw te scannen.</0>",
    "contactTrackingInactive": "<0>Het bijhouden van contacten is <1>inactief</1>.</0>",
    "deactivateTracking": "Deactiveer tracking",
    "addContact": "Contact toevoegen aan track",
    "activateTracking": "Activeer tracking!",
    "contactCard": {
      "notTracking": "Niet volgen",
      "howToTrack": "Hoe contact volgen?",
      "onlineNotification": "Online melding",
      "onlineHistory": "Onlinegeschiedenis",
      "chatODDS": "Chat OODS",
      "nightWatch": "Nachtwacht",
      "delete": "Verwijderen",
      "lastSeen": {
        "online": "Online",
        "secsAgo": "{{count}} sec geleden",
        "secsAgo_plural": "{{count}} seconden geleden",
        "minsAgo": "{{count}} Min geleden",
        "minsAgo_many": "{{count}} Minuten geleden",
        "hoursAgo": "{{count}} uur geleden",
        "hoursAgo_plural": "{{count}} uur geleden",
        "daysAgo": "{{count}} dag geleden",
        "daysAgo_plural": "{{count}} Dagen geleden"
      }
    }
  },
  "subscriptionDetails": {
    "subscriptionDetails": "Abonnementsgegevens",
    "feature1": "Houd tot {{count}} contacten bij!",
    "feature2": "Onlinegeschiedenis",
    "feature3": "Inzicht in contacten",
    "feature4": "AI getrainde chat waarschijnlijkheid",
    "feature5": "Ondersteuningsteam (e-mail)",
    "hasBeenCancelled": "<0>Dit abonnement is <1>geannuleerd</1> en uw kaart zal niet opnieuw worden belast.</0>",
    "accessWillEndOn": "Uw toegang eindigt op",
    "nextPaymentDate": "Volgende betaaldatum",
    "cancelPlan": "Plan annuleren",
    "howToCancel": "Hoe annuleer je je abonnement?",
    "howToCancelText": "Log in op je PayPal-rekening.\nKlik op Extra en selecteer vervolgens Alle tools boven aan de pagina.\nSelecteer vervolgens Terugkerende betalingen op de pagina Extra.\nDaar kun je je abonnement annuleren.",
    "cancelSubscription": "Abonnement annuleren",
    "weAreSorryToEar": "Het spijt ons te horen dat je wilt opzeggen. Zijn er problemen met de service of kwaliteit?\nWil je ons een e-mail sturen voor mogelijke oplossingen?\nZo niet, klik dan op de knop Abonnement annuleren.",
    "keepSubscription": "Mijn abonnement behouden"
  },
  "addNumber": {
    "selectContactToCompare": "Selecteer een contact\nom te vergelijken",
    "selectContactToTrack": "Selecteer een contact\nom te volgen",
    "searchBy": "Zoeken op telefoon of naam",
    "noResultsFor": "Geen resultaten voor",
    "trySearchingByPhone": "Probeer te zoeken op telefoonnummer"
  },
  "onlineHistory": {
    "onlineHistory": "Online geschiedenis",
    "last24Hours": "Laatste 24 uur",
    "selectADate": "Selecteer een datum",
    "time": "Tijd",
    "offlineFor": "Offline voor",
    "hour": "uur",
    "hour_plural": "uur",
    "from": "van",
    "to": "tot",
    "notEnoughtData": "Niet genoeg gegevens, kom later terug",
    "changeDate": "DATUM WIJZIGEN"
  },
  "compareWith": {
    "selectContactToCompareWith": "Selecteer een contact om\nmee te vergelijken",
    "searchBy": "Zoeken op telefoon of naam"
  },
  "chatProbability": {
    "chatProbability": "Waarschijnlijkheid chat",
    "last24Hours": "Laatste 24 uur",
    "selectADate": "Selecteer een datum",
    "time": "Tijd",
    "score": "Score",
    "offlineFor": "Offline voor",
    "hour": "uur",
    "hour_plural": "uur",
    "from": "van",
    "to": "naar",
    "notEnoughtData": "Niet genoeg gegevens, kom later terug",
    "changeDate": "WIJZIG DATUM"
  },

  nightWatch: {
    nightWatch: 'Nachtwacht',
    sleptFor: 'SLAPEN VOOR {{count}} UUR TOP',
    sleptFor_plural: 'SLAPEN VOOR {{count}} UUR TOP',
    owlAlert: 'Uil waarschuwing',
    highestChatProbability: 'Hoogste chatwaarschijnlijkheid',
    minutes: 'minuut',
    minutes_plural: 'minuten'
  }
}