import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { NavigationContainer } from './navigation/container';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import moment from "moment"
import { getDeviceLanguage } from './shared/helpers';
import ReactGA from 'react-ga4';
import * as mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module'
import { configService } from './services/config.service';

require("./import-fonts")
require('./services/i18n.service')
//moment.locale(getDeviceLanguage())
if( !window.location.href.includes('localhost') && !window.location.href.includes('local.')  ){
  //ReactGA.initialize('G-W1JZM94VH9');
  const gtmId = configService.get("gtmId")
  if( gtmId ) TagManager.initialize({ gtmId });
  // CHATWATCH
  else ReactGA.initialize('G-W1JZM94VH9');
  mixpanel.init('a22bcf4bb0982bfb8c52758d893fbd69', {track_pageview: true})
}

export function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <SafeAreaProvider>
          <NavigationContainer />
      </SafeAreaProvider>
    </PersistGate>
    </Provider >
  );
}

serviceWorkerRegistration.register();