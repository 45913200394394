import React from 'react';
import { View, StyleSheet } from 'react-native';
import { PageIndicator } from 'react-native-page-indicator';
import { globalStyles } from '../../shared/styles';
import { isApp } from '../../shared';
import { APP } from '../../shared/constants';

export const SliderIndicator = ({ pages, current }) => (
  <View style={styles.wrapper}>
    <PageIndicator count={pages} current={current} opacity={isApp(APP.WAWATCHER) ? 0.2 : 1} color={globalStyles.baseText.color} activeColor={ globalStyles.mainCardBackground.backgroundColor } gap={10} />
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});