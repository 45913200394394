export const fr = {
    "app.name": "ChatWatch",
    "language": "fr",
    "slogan": "L'original Whatsapp\nTraqueur en ligne",
    "goBack": "Retourner en arrière",
    "menu": {
      "home": "Accueil",
      "subscription": "Abonnement",
      "logOut": "Se déconnecter"
    },
    "login": {
      "login": "Se connecter",
      "logIn": "Se connecter",
      "email": "Courriel",
      "password": "Mot de passe",
      "forgotPassword": "Vous avez oublié votre mot de passe ?",
      "dontHaveAccount": "Vous n'avez pas de compte ?",
      "register": "S'inscrire",
      "errorValidEmail": "Veuillez indiquer un email valide",
      "errorPasswordEmpty": "Veuillez définir un mot de passe"
    },
    "register": {
      "register": "S'inscrire",
      "email": "Courriel",
      "password": "Mot de passe",
      "repeatPassword": "Répéter le mot de passe",
      "alreadyHaveAccount": "Vous avez déjà un compte ?",
      "logIn": "S'identifier",
      "errorValidEmail": "Veuillez indiquer un email valide",
      "errorPasswordEmpty": "Veuillez définir un mot de passe",
      "errorPasswordShort": "Le mot de passe doit comporter au moins 8 caractères",
      "errorPasswordNotMatch": "Les mots de passe ne sont pas égaux"
    },
    "recoverPassword": {
      "recoverPassword": "Récupérer le mot de passe",
      "errorValidEmail": "Veuillez indiquer un email valide",
      "email": "Courriel",
      "emailSent": "Email envoyé"
    },
    "buySubscription": {
      "subscription": "Abonnement",
      "week": "SEMAINE",
      "month": "MOIS",
      "year": "ANNÉE",
      "weekly": "HEBDOMADAIRE",
      "monthly": "MOIS",
      "yearly": "ANNUEL",
      "startYourTrial": "COMMENCEZ VOTRE ESSAI GRATUIT DE 3 JOURS\nCHOISIR L'ABONNEMENT",
      "selectPlan": "PLAN SÉLECTIONNÉ",
      "selectedPlan": "PLAN SÉLECTIONNÉ",
      "payWith": "PAYER AVEC",
      payWithCard: 'PAYEZ AVEC CARTE',
      "card": "Carte",
      "fullName": "Nom complet",
      "subscribe": "S'ABONNER",
      "errorFullName": "Veuillez indiquer votre nom complet.",
      "errorCouldNotCreate": "Impossible de créer un paiement. Réessayez. CODE:1"
    },
    "chatOddsIntro": {
      "chatOdds": "Probabilités de chat",
      "wantToTrackAnother": "Vous voulez suivre un autre contact à\npour voir s'il est possible qu'ils discutent ensemble à\n?",
      "later": "Plus tard",
      "yes": "Oui"
    },
    "noContacts": {
      "noContacts": "Pas de contacts",
      "itSeems": "Il semble que vous n'ayez pas encore\ncontacts suivis...",
      "willBeRecorded": "Les informations en ligne seront enregistrées à partir de\npour les contacts sélectionnés.\nSélectionnez le premier contact à suivre.",
      "addContact": "Ajouter un contact à suivre"
    },
    "qrScan": {
      "enterCode": "Entrez le code de connexion",
      "followInstructions": "Suivez les instructions pour scanner ce\nQR code sur votre appareil principal.",
      "enjoy": "Bonne utilisation de ChatWatch !",
      "canClose": "Vous pouvez fermer cette fenêtre",
      "openUrl": "<0>Vous devez ouvrir cette url\ndepuis <3>UN AUTRE</3> appareil</0>",
      "updatingServices": "Nous mettons à jour nos services,\nveuillez recharger la page et réessayer.",
      "msgConnecting1": "Connexion à WA...Veuillez patienter...",
      "msgConnecting2": "Synchronisation du compte. Veuillez patienter...",
      "msgConnecting3": "Récupération d'informations...Veuillez patienter...",
      "msgConnecting4": "Session initialisée."
    },
    "resetPassword": {
      "resetPassword": "Réinitialiser le mot de passe",
      "errorPasswordEmpty": "Veuillez définir un mot de passe",
      "errorPasswordShort": "Le mot de passe doit comporter au moins 8 caractères",
      "errorPasswordNotMatch": "Les mots de passe ne sont pas identiques",
      "errorLinkNotValid": "Le lien n'est pas valide",
      "newPassword": "Nouveau mot de passe",
      "repeatNewPassword": "Répéter le nouveau mot de passe",
      "passwordReseted": "Mot de passe réinitialisé"
    },
    "thankYou": {
      "thankYou": "Merci\npour votre abonnement !",
      "continue": "CONTINUER"
    },
    "waLogin": {
      "privacyNotice": "Avis de confidentialité",
      "weNotStore": "Nous ne stockons pas, ne lisons pas et n'accédons pas aux données du compte WA.",
      "next": "Suivant",
      "howToLogin": "Comment se connecter ?",
      "howToLogin1": "Trouver un ordinateur, une tablette, un téléphone ou une Smart TV\nVisiter",
      "howToLogin2": "Entrez le code suivant",
      "link": "Lier le compte WA",
      "link1": "Ouvrez WA Setting en tapant sur cette icône.",
      "link2": "Sélectionnez les appareils liés.",
      "link3": "Lorsque l'appareil photo est activé, pointez votre téléphone\npour scanner le code QR.",
      "connecting": "CONNEXION",
      "somethingWrong": "Un problème s'est produit.\nVeuillez recharger cette page et réessayer.",
      "msgConnecting1": "Connexion à WA...Veuillez patienter...",
      "msgConnecting2": "Synchronisation du compte. Veuillez patienter...",
      "msgConnecting3": "Récupération d'informations...Veuillez patienter...",
      "msgConnecting4": "Session initialisée."
    },
    "main": {
      "connectionManager": "Gestionnaire de connexion",
      "trackedContacts": "Contacts suivis",
      "contactTrackingActive": "<0>Le suivi des contacts est <1>actif</1>.<br/>Vous pouvez désactiver le suivi pour apparaître hors ligne. Plus tard, vous pourrez l'activer en un seul clic. Il n'est pas nécessaire de scanner à nouveau le code QR.</0>",
      "contactTrackingInactive": "<0>Le suivi des contacts est <1>inactif</1>.</0>",
      "deactivateTracking": "Désactiver le suivi",
      "addContact": "Ajouter un contact à suivre",
      "activateTracking": "Activez le suivi !",
      "contactCard": {
        "notTracking": "Pas de suivi",
        "howToTrack": "Comment suivre les contacts ?",
        "onlineNotification": "Notification en ligne",
        "onlineHistory": "Histoire en ligne",
        "chatODDS": "Chat",
        "nightWatch": "Veille de nuit",
        "delete": "Supprimer",
        "lastSeen": {
          "online": "En ligne",
          "secsAgo": "{{count}} Il y a une seconde",
          "secsAgo_plural": "Il y a {{count}} secondes",
          "minsAgo": "Il y a {{count}} minutes",
          "minsAgo_many": "Il y a {{count}} minutes",
          "hoursAgo": "Il y a {{count}} heures",
          "hoursAgo_plural": "Il y a {{count}} heures",
          "daysAgo": "Il y a {{count}} jour",
          "daysAgo_plural": "Il y a {{count}} jours"
        }
      }
    },
    "subscriptionDetails": {
      "subscriptionDetails": "Détails de l'abonnement",
      "feature1": "Suivez jusqu'à {{count}} contacts !",
      "feature2": "Histoire en ligne",
      "feature3": "Informations sur les contacts",
      "feature4": "Probabilité de chat formée par l'IA",
      "feature5": "Équipe d'assistance (email)",
      "hasBeenCancelled": "<0>Cet abonnement a été <1>annulé</1> et votre carte ne sera pas débitée à nouveau.</0>",
      "accessWillEndOn": "Votre accès prendra fin le",
      "nextPaymentDate": "Date du prochain paiement",
      "cancelPlan": "Annuler le plan",
      "howToCancel": "Comment annuler votre plan ?",
      "howToCancelText": "Connectez-vous à votre compte PayPal.\nCliquez sur Outils, puis sélectionnez Tous les outils en haut de la page.\nSélectionnez ensuite Paiements récurrents sur la page Outils.\nVous pouvez annuler votre abonnement à cet endroit.",
      "cancelSubscription": "Annuler l'abonnement",
      "weAreSorryToEar": "Nous sommes désolés d'apprendre que vous souhaitez résilier votre abonnement. Y a-t-il des problèmes de service ou de qualité ?\nSouhaitez-vous nous envoyer un courrier électronique afin de trouver une solution ?\nSi ce n'est pas le cas, cliquez sur le bouton Annuler l'abonnement.",
      "keepSubscription": "Conserver mon abonnement"
    },
    "addNumber": {
      "selectContactToCompare": "Sélectionnez un contact\nà comparer",
      "selectContactToTrack": "Sélectionnez un contact\nà suivre",
      "searchBy": "Recherche par téléphone ou par nom",
      "noResultsFor": "Aucun résultat pour",
      "trySearchingByPhone": "Essayez de rechercher par numéro de téléphone"
    },
    "onlineHistory": {
      "onlineHistory": "Historique en ligne",
      "last24Hours": "24 dernières heures",
      "selectADate": "Sélectionner une date",
      "time": "Heure",
      "offlineFor": "Hors ligne pendant",
      "hour": "heure",
      "hour_plural": "heures",
      "from": "de",
      "to": "à",
      "notEnoughtData": "Pas assez de données, veuillez revenir plus tard",
      "changeDate": "MODIFIER LA DATE"
    },
    "compareWith": {
      "selectContactToCompareWith": "Sélectionner un contact pour\ncomparer avec",
      "searchBy": "Recherche par téléphone ou par nom"
    },
    "chatProbability": {
      "chatProbability": "Probabilité de chat",
      "last24Hours": "24 dernières heures",
      "selectADate": "Sélectionner une date",
      "time": "Heure",
      "score": "Score",
      "offlineFor": "Hors ligne depuis",
      "hour": "heure",
      "hour_plural": "heures",
      "from": "de",
      "to": "à",
      "notEnoughtData": "Pas assez de données, veuillez revenir plus tard",
      "changeDate": "MODIFIER LA DATE"
    },

    nightWatch: {
      nightWatch: 'Veille de nuit',
      sleptFor: "J'ai dormi pendant {{count}} heures maximum",
      sleptFor_plural: "J'ai dormi pendant {{count}} heures maximum",
      owlAlert: 'Alerte chouette',
      highestChatProbability: 'Probabilité de chat la plus élevée',
      minutes: 'minute',
      minutes_plural: 'minutes'
    }
  }