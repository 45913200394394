import React, {Component, ReactNode} from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';
import {connect} from 'react-redux';
import { UiState } from '../store/ui/UiState';

interface LoadingConponentProps {
  uiState: UiState;
}

class _LoadingComponent extends Component<LoadingConponentProps> {
  render(): ReactNode {
    return this.props.uiState.isLoading && false ? (
      <View style={style.backdrop}>
        <ActivityIndicator animating={true} color={style.spinner.color} />
      </View>
    ) : null;
  }
}

const mapStateToProps = (store: {
  ui: UiState;
}): LoadingConponentProps => ({
  uiState: store.ui,
});

const style = StyleSheet.create({
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  spinner: {
    color: '#007bff',
  },
});

export const LoadingComponent = connect(mapStateToProps)(_LoadingComponent);
