import {authReducer} from './auth/auth.reducers';
import { migrations } from './migrations';
import {combineReducers, configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, createMigrate } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { uiReducer } from './ui/ui.reducers';
import { agendaReducer } from './agenda/agenda.reducers';
import { navigationReducer } from './navigation/navigation.reducers';

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrate: createMigrate( migrations, { debug: true } ),
  blacklist: ['ui'],
}

const reducers = combineReducers({
  auth: authReducer,
  agenda: agendaReducer,
  ui: uiReducer,
  navigation: navigationReducer
});

const _persistedReducer: typeof reducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export type RootState = ReturnType<typeof store.getState>
export const persistor = persistStore(store)

